import { Link } from "react-router-dom";
import Logo from '../Logo'
//import Notice from '../Notice'
import { Block } from 'components/common/ui'
import { event } from 'utils/ga'
import { useSelector } from 'react-redux';
import Selectors from 'selectors'
import AuthHeader from './header-loggedin'

export default function Header({ children, smallLogo, hideNotice, url, button, float, hideLogo, download, authHeader, isHome,  ...props }) {
  return (
    <Block>
      {authHeader ? (
        <AuthHeader isHome={isHome} />
      ) : (
        <Block {...props}>
          <Link to="/" onClick={() => {
            event({
              category: 'Profile',
              action: 'home_click',
              label: 'logo'
            })
          }}>
              <Block display={{
                mobile: 'none',
                desktop: 'block'
              }}
              >
                {/* {float ? (
                  <Logo
                    small={float}
                    style={{
                      width: 60,
                    }}
                  />
                ) : (
                  <Logo />
                )} */}
              </Block>

              <Block display={{
                mobile: hideLogo ? 'none' : 'block',
                desktop: 'none'
              }}
              >
                <Logo
                  small={smallLogo}
                  style={{
                    width: 40
                  }}
                />
              </Block>
          </Link>
          <Block>
            {children}
          </Block>
          <Block display={{
            mobile: 'none',
            desktop: 'block'
          }}
          >
            {button}
          </Block>
        </Block>
      )}
    </Block>
  )
}
