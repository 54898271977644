import colors from './colors'


export const Palette = {
  white: colors.white,
  translucent: colors.translucent,
  greyLightest: colors.grey[0],
  greyLighter: colors.grey[1],
  greyLight: colors.grey[2],
  grey: colors.grey[3],
  greyDark: colors.grey[4],
  black: colors.black,
  navy: colors.navy[0],
  navyLighter: colors.navy[1],
  blue: colors.blue,
  brandLightest: colors.brand[3],
  brandLight: colors.brand[1],
  brand: colors.brand[0],
  brandDark: colors.brand[2],

  danger: colors.danger[0],
  'danger-bg': colors.danger[1],
  error: colors.danger,
  overlay: colors.overlay,
  none: colors.none,
}


export const Fonts = {
  pageHeader: 'Gilroy',
  brandHeaderRegular: 'Gilroy',
  brandHeader: 'Gilroy',
  brandHeaderSemibold: 'Gilroy',
  body: 'Gilroy',
  bodyRegular: 'Gilroy',
  chatFont: 'Gilroy',
  bodySemibold: 'Gilroy',
  icons: 'NarrateIcons',
}

export const Typography = {
  pageHeader: {
    fontFamily: Fonts.pageHeader,
    fontSize: 22,
    textAlign: 'center',
    fontWeight: 600,
  },
  h1: {
    fontFamily: Fonts.brandHeader,
    fontSize: 38,
    fontWeight: 600,
  },
  h2: {
    fontFamily: Fonts.brandHeader,
    fontSize: 34,
    fontWeight: 600,
  },
  h3: {
    fontFamily: Fonts.brandHeader,
    fontSize: 30,
    fontWeight: 600,
  },
  h4: {
    fontFamily: Fonts.brandHeader,
    fontSize: 26,
    fontWeight: 600,
  },
  h5: {
    fontFamily: Fonts.brandHeader,
    fontSize: 22,
    fontWeight: 600,
  },
  h6: {
    fontFamily: Fonts.brandHeader,
    fontSize: 18,
    fontWeight: 600,
  },
  settings: {
    fontFamily: Fonts.brandHeaderRegular,
    fontSize: 18,
    lineHeight: 1.5,
    fontWeight: 300,
  },
  subhead1: {
    fontFamily: Fonts.brandHeaderSemibold,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.2,
  },
  subhead2: {
    fontFamily: Fonts.brandHeaderSemibold,
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0.4,
  },
  subhead3: {
    fontFamily: Fonts.brandHeaderSemibold,
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 0.6,
  },
  subhead4: {
    fontFamily: Fonts.brandHeaderRegular,
    fontSize: 12,
    fontWeight: 300,
    letterSpacing: 0.6,
  },
  overline: {
    fontFamily: Fonts.bodySemibold,
    fontSize: 11,
    textTransform: 'uppercase',
    fontWeight: 600,
    letterSpacing: 1.8,
  },
  body: {
    fontFamily: Fonts.body,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.4,
  },
  bodyRegular: {
    fontFamily: Fonts.bodyRegular,
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 0.4,
  },
  bodySmall: {
    fontFamily: Fonts.body,
    fontSize: 11,
    fontWeight: 500,
    letterSpacing: 0.4,
  },
  button: {
    fontFamily: Fonts.brandHeaderSemibold,
    fontSize: 11,
    fontWeight: 600,
    letterSpacing: 0.2,
  },
  chatFont: {
    fontFamily: Fonts.body,
    fontSize: 12,
    lineHeight: 1,
    fontWeight: 500,
    letterSpacing: 0.1,
  },
  tabBar: {
    fontFamily: Fonts.brandHeaderSemibold,
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 0.2,
  },
}

Typography.label = Typography.subhead2
Typography.caption = Typography.bodySmall
Typography.paragraph = Typography.body


const breakpoints = ['320px', '736px', '1024px', '1025px']

breakpoints.mobile = breakpoints[0]
breakpoints.tablet = breakpoints[2]
breakpoints.desktop = breakpoints[3]

export default {
  fonts: Fonts,
  fontSizes: [11, 12, 14, 16, 18, 22, 26, 30, 34, 38],
  letterSpacings: [0.2, 0.4, 1.8],
  breakpoints,
  mediaQueries: {
    mobile: `@media (min-width: ${breakpoints[0]}) and (max-width : ${breakpoints[1]})`,
    tablet: `@media (min-width: ${breakpoints[1]}) and (max-width: ${breakpoints[2]})`,
    desktop: `@media (min-width: ${breakpoints[3]})`
  },
  colors: {
    ...Palette,
    error: Palette.danger,
    modes: {
      dark: {
        // dark mode colors here
      },
    },
  },
  borders: [0, '1px solid', '2px solid'],
  shadows: {
    dialog: '0 1px 15px rgba(27,31,35,.15)',
    formControl: '0 5px 10px -5px rgba(0,0,0,0.04)'
  },
  radii: [4, 6, 8, 15, 22],
  sizes: [0, 5, 11, 22, 30, 44, 66],
  space: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 100, 200],
}
