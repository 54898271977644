import Lottie from 'react-lottie'
import { Block } from 'ui'
import animation from './loader.json'
import animationWhite from './loader-white.json'


export default function Loader({ size = 50, light, ...props }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: light ? animationWhite : animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <Block {...props}>
      <Lottie
        options={defaultOptions}
        height={size}
        width={size}
      />
    </Block>
  )
}
