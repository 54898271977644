import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Selectors from 'selectors';
import Actions from 'actions';
import Chat from 'components/conversation'
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

export default function Conversation(){
  const conversation = useSelector(Selectors.conversations.conversations);
  const userIdMap = useSelector(Selectors.conversations.keyByOtherUserId);
  const convoUser = useSelector(Selectors.conversations.currentConvoUser);
  const dispatch = useDispatch()
  const { initialUserId, participantUserId, userId } = useParams();
  const key = (initialUserId && participantUserId) ? `${initialUserId}/${participantUserId}` : userIdMap[userId];
  const messages = conversation[key]

  useEffect(() => {
    dispatch(Actions.profile.loadUser.trigger({ userId }));
    dispatch(
      Actions.conversations.loadOne.trigger({
        key,
        markRead: true,
        userId,
        // onSuccess: () => {
        //   this._isMounted = true;
        //   this.configureHeader();
        // },
      }),
    );
  }, [dispatch, key, userId])

  const title = convoUser?.username ? `Conversation with ${convoUser?.username} - Narrate` : 'My Conversations - Narrate'

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta
          property="og:title"
          content={title}
        />
        <meta
          property="description"
          content={title}
        />
        <meta
          property="og:description"
          content={title}
        />
      </Helmet>
      <Chat conversation={messages} otherUserId={userId} loading={conversation?.loading} convoKey={key} />
    </>
  )
}