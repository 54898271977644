import firebase from 'firebase/app';
import 'firebase/remote-config';

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECTID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_SENDERID,
  appId: process.env.FIREBASE_APP_ID
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const remoteConfig = firebase.remoteConfig();

const fetch = (env) => new Promise((res, reject) => {
  remoteConfig.ensureInitialized().then(() => {
    remoteConfig.fetchAndActivate().then(() => {
      const snapshot = remoteConfig.getValue(env);
      res(snapshot._value);
    });
  }).catch((err) => {
    reject(err);
  });
});

export const getConfig = async () => {
  let conf = null;

  try {
    const snapshot = await fetch(process.env.REACT_ENV);
    conf = snapshot;
  } catch (e) {
    console.log(e);
  }

  return JSON.parse(conf);
};

export default firebase;