import styled, { css } from 'styled-components'
import { Text, Block, theme} from 'ui'
import { isMobile } from "react-device-detect";

const BadgeWrapper = styled.div`
  position:relative;

   aside {
    width:14px;
    height:14px;
    position:absolute;
    top:-5px;
    right:-5px;
    border-radius:100%;
    z-index:99;
    box-shadow: 0 0 0 2px ${isMobile ? '#ffffff' : theme?.colors?.navy};
    text-align:center;
    justify-content:center;
    align-items:center;
    display:flex;
    ${Text} {
      font-weight:bold;
      font-size:8px;
    }
  }
`


const Badge = ({count, children}) => (
  <BadgeWrapper>
    {count > 0 && (
      <Block bg="danger" as="aside">
        <Text variant="subhead4" color="white">{count}</Text>
      </Block>
    )}
    {children}
  </BadgeWrapper>
)

export default Badge