import { createActions } from 'redux-actions';
import { DefaultRoutine } from 'utils';

const Actions = createActions({
  POSTS: {
    CREATE: DefaultRoutine,
    DESTROY: DefaultRoutine,
    LIST: DefaultRoutine,
    LIST_BY_USER: DefaultRoutine,
    SHOW: DefaultRoutine,
    UPDATE: DefaultRoutine,
    RESET: undefined,
    UPLOAD_IMAGE: DefaultRoutine,
    SAVING: undefined,
    CONTENT: {
      ADD: undefined,
      REMOVE: undefined,
      TOGGLE: undefined,
      CLEAR: undefined,
      UPLOAD: DefaultRoutine,
      LOAD: undefined,
      ADD_PHOTOS: DefaultRoutine,
      ADD_VIDEO: DefaultRoutine,
      REMOVE_ITEMS: DefaultRoutine,
      GENERATE_LINK: DefaultRoutine,
      UPDATE: DefaultRoutine,
      GET: DefaultRoutine,
      SAVE: DefaultRoutine,
      REMOVE_DRAFT: undefined,
      UPLOAD_PROGRESS: undefined,
      UPLOAD_STATUS: undefined,
    },
    UPDATE_IMAGE: {
      CREATE: undefined,
      UPDATE: undefined,
    },
  },
});

export default Actions;
