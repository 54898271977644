import * as Yup from 'yup';
import { MAX_QUANTITY, MAX_PURCHASE_AMT, MIN_PURCHASE_AMT } from '../constants';

function equalTo(ref, msg) {
  return Yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg,
    params: {
      reference: ref.path,
    },
    test(value) {
      return value === this.resolve(ref);
    },
  });
}

Yup.addMethod(Yup.string, 'equalTo', equalTo);

export const username = Yup.string()
  .min(3, 'Username needs to be at least 3 characters')
  .max(30, 'Username is too long')
  .matches(/^[a-zA-Z0-9_.]*$/, 'Please enter a valid username')
  .required('Username is required');

export const email = Yup.string()
  .trim('Make sure your email address does not have any spaces.')
  .email()
  .required('Enter a valid email address');

export const name = Yup.string()
  .trim()
  .max(30, 'Name is too long');

export const password = Yup.string()
  .trim()
  .required('Please Enter your password')
  .min(6, 'Password must contain at least 6 characters')
  .max(48, 'Password is too long');

export const PostValidation = Yup.object().shape({
  accessImage: Yup.string().required('Photo is required'),
  headline: Yup.string()
    .trim()
    .required('Headline is required'),
  price: Yup.string()
    .required('Price is required')
    .test(
      'min-price',
      'Price cannot be less than $2.00',
      value => value && parseInt(value.replace(/[^0-9.-]+/g, ''), 10) >= MIN_PURCHASE_AMT,
    )
    .test(
      'max-price',
      'Price cannot be more than $999,999.99',
      value => value && parseInt(value.replace(/[^0-9.-]+/g, ''), 10) <= MAX_PURCHASE_AMT,
    ),
  quantity: Yup.number()
    .typeError('Quantity must be a number')
    .required('Quantity is required')
    .positive('Quantity must be more than zero')
    .test(
      'max-qty',
      `Quanity cannot be more than ${MAX_QUANTITY}`,
      value => value !== '' && value < MAX_QUANTITY,
    ),
  description: Yup.string()
    .trim()
    .required('Description is required'),
  autoReply: Yup.string()
    .trim()
    .required('Purchase Auto-Reply is required'),
});

export const UserValidation = Yup.object().shape({
  name,
  username,
  email,
  password,
});

export const ProfileValidation = Yup.object().shape({
  name,
});

export const EditProfileValidation = Yup.object().shape({
  name,
  username,
});

export const LoginValidation = Yup.object().shape({
  email,
  password: Yup.string().required('Password is required'),
});

export const ForgotPasswordValidation = Yup.object().shape({
  email,
});

export const ChangePasswordValidation = Yup.object().shape({
  previousPassword: Yup.string().required('Previous password is required'),
  newPassword: password,
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .equalTo(Yup.ref('newPassword'), 'New and confirm passwords must match'),
});

export const ResetPasswordValidation = Yup.object().shape({
  newPassword: password,
  code: Yup.string().required('Verification code is required'),
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .equalTo(Yup.ref('newPassword'), 'New and confirm passwords must match'),
});

export const ReportIssueValidation = Yup.object().shape({
  report_issue: Yup.string().required('Please enter a message'),
});
