import React, { useEffect, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { useHistory } from "react-router-dom";
import { email, username, password } from 'utils/validation'
import { connect, useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Block, Text, TextInput, Avatar, Icon, Button, Spinner } from 'ui'
import Selectors from 'selectors'
import Actions from 'actions'

export const SignupValidation = Yup.object().shape({
  email,
  username,
  password
})


function Signup({callback: cb, title, standalone}) {
  const dispatch = useDispatch()
  const { push } = useHistory()
  const {loading, error: ssError} = useSelector(Selectors.auth.signup)
  const toggleSignUp = () => {
    if(standalone) {
      push('/login')
    }else{
      dispatch(Actions.profile.toggleRegister(false))
    }
  }

  return (
    <Block>
     {!loading &&
        <Text
          variant="h4"
          mb={8}
          color="navy"
          textAlign="center"
        >
          {title}
        </Text>
      }
      <Formik
        initialValues={{
          email: '',
          username: '',
          password: ''
        }}
        validationSchema={SignupValidation}
        validateOnBlur={false}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(()=> setSubmitting(false), 3000)
          dispatch(Actions.auth.clearErrors())
          dispatch(Actions.auth.signup.trigger({ ...values, callback: () => {
            if(cb) {
              cb()
            }
            setSubmitting(false)
          } }));
        }}
      >
      {(props) => {
        const { errors: clientError, handleBlur, touched, values, handleChange, handleSubmit, isSubmitting } = props

        if(loading) {
          return (
            <Block col justifyContent="center" alignCenter="center" textAlign="center">
              <Spinner size={40} />
              <Text color="grey">loading...</Text>
            </Block>
          )
        }

        return (
          <Form>
            <Block col>
              <TextInput
                label="Email Address"
                name="email"
                placeholder="Email Address"
                type="email"
                value={values.email}
              />
            </Block>
            <Block mt={3} mb={2} col>
              <TextInput
                label="Username"
                name="username"
                placeholder="Username"
                type="text"
                autocomplete="username"
                value={values.username}
              />
            </Block>
            <Block mt={3} mb={2} col>
              <TextInput
                label="Password"
                name="password"
                placeholder="Password"
                type="password"
                autocomplete="new-password"
                value={values.password}
              />
            </Block>
            {ssError?.Error && (
              <Block textAlign="center" mt={4} mb={2}>
                <Text variant="subhead1" color="danger" role="alert">
                  {ssError?.Error}
                </Text>
              </Block>
            )}
            <Button
              mt={3}
              size="large"
              block
              text="Sign up"
              type="submit"
              variant="alt"
              progress={isSubmitting}
            />
            <Block textAlign="center">
              <Text py={3}>Have an account? <Text color="brand" display="inline-block" onClick={toggleSignUp}>Login</Text></Text>
            </Block>
          </Form>
        )
      }}
      </Formik>
    </Block>
  )
}

const mapStateToProps = state => ({
  signup: Selectors.auth.signup(state),
});

export default connect(mapStateToProps)(Signup)