import Narrate from '@narrate/app-sdk';
import { fork, call, put, take, takeEvery, actionChannel } from 'redux-saga/effects';
import { buffers } from 'redux-saga';
import { routineSaga } from 'utils/sagas';
import Actions from 'actions';

const search = routineSaga({
  routine: Actions.search.search,
  *request({ payload: { query } }) {
    if (!query) {
      return { users: [] };
    }

    return yield call(() => Narrate.api.search.list(query.trim()));
  },
});

function* manageQueue() {
  const channel = yield actionChannel(Actions.search.update, buffers.sliding(1));
  while (true) {
    const { payload } = yield take(channel);
    yield put(Actions.search.search.trigger(payload));
    yield take(Actions.search.search.fulfill);
  }
}

export default function* combined() {
  yield fork(manageQueue);
  yield takeEvery(Actions.search.search.trigger, search);
}
