import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { useHistory } from "react-router-dom";
import { LoginValidation, ForgotPasswordValidation, ResetPasswordValidation } from 'utils/validation'
import { connect, useDispatch, useSelector } from 'react-redux';
import { Block, Text, TextInput, Avatar, Icon, Button, Spinner } from 'ui'
import Selectors from 'selectors'
import Actions from 'actions'


function Login({callback, title, standalone}) {
  const dispatch = useDispatch()
  const {loading, error: ssError} = useSelector(Selectors.auth.login)
  const { loading: refreshLoading } = useSelector(Selectors.auth.forgotPassword)
  const messagedUser = useSelector(Selectors.auth.messagedUser)
  const [forgotPassVisible, showForgotPassword] = useState(false)
  const [verifyCodeVisible, showVerify] = useState(false)
  const [resent, setResent] = useState(false);
  const [savedEmail, setEmail] = useState('')
  const { push } = useHistory()
  const toggleSignUp = () => {
    if(standalone) {
      push('/register')
    }else{
      dispatch(Actions.profile.toggleRegister(true))
    }
  }

  const resendCode = () => {
    dispatch(Actions.auth.forgotPassword.trigger({
      email: savedEmail,
      onSuccess: () => {
        setResent(true);
        setTimeout(() => {
          setResent(false);
        }, 1500);
      },
    }));
  }


  if(verifyCodeVisible) {
    return (
      <Block col>
        <Text
          variant="h4"
          mb={4}
          color="navy"
          textAlign="center"
        >
          Reset Password
        </Text>
        <Formik
          initialValues={{
            code: '',
            newPassword: '',
            confirmPassword: '',
          }}
          validationSchema={ResetPasswordValidation}
          validateOnBlur={false}
          onSubmit={(values) => {
            dispatch(Actions.auth.confirmPassword.trigger({
              newPass: values.newPassword,
              code: values.code,
              email: savedEmail,
              onSuccess: () => {
                push(`/conversations`)
              },
            }));
          }}
        >
          {(props) => {
            const { errors: clientError, handleBlur, touched, values, handleChange, handleSubmit } = props
            return (
              <Form>
                <Block pb={3} col>
                  <TextInput
                    label="Verification Code"
                    name="code"
                    placeholder="Code"
                    type="text"
                    value={values.code}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    {...props}
                  />
                  <Block row alignItems="center" pt={1} onClick={resendCode}>
                    {refreshLoading ? (<Spinner size={20} />) : (<Icon name="refresh" color="greyDark" />)}
                    <Text color="greyDark" pl={1}>Resend Code</Text>
                  </Block>
                  {resent && <Text color="brand" pt={2}>Verification code re-sent.</Text>}
                </Block>
                <Block pb={3}>
                  <TextInput 
                    name="newPassword" 
                    label="New Password" 
                    id="newPassword"
                    value={values.newPassword} 
                    type="password"
                    placeholder="New Password"
                  />
                </Block>

                <Block pb={3}>
                  <TextInput 
                    name="confirmPassword" 
                    label="Confirm Password" 
                    id="confirmPassword"
                    value={values.confirmPassword} 
                    type="password"
                    placeholder="Confirm Password"
                  />
                </Block>
                <Button
                  mt={3}
                  size="large"
                  block
                  onClick={handleSubmit}
                  text="Update Password"
                  type="submit"
                  variant="alt"
                />
                <Block textAlign="center" mt={2}>
                  <Text color="greyDark" variant="subhead1" onClick={()=>{
                    showVerify(false)
                  }}>Back</Text>
                </Block>
              </Form>
            )
          }}
        </Formik>
      </Block>
    )
  }


  if(forgotPassVisible) {
    return (
      <Block col>
        <Text
          variant="h4"
          mb={2}
          color="navy"
          textAlign="center"
        >
          Forgot Password
        </Text>
        <Text mb={4}>Enter your email and we'll send you a code to reset your password.</Text>
        <Formik
          initialValues={{
            email: '',
          }}
          validateOnBlur={false}
          validationSchema={ForgotPasswordValidation}
          onSubmit={(values) => {
            dispatch(Actions.auth.forgotPassword.trigger({
              email: values.email,
              onSuccess: () => {
                showVerify(true)
                setEmail(values.email)
              },
            }));
          }}
        >
          {(props) => {
            const { errors: clientError, handleBlur, touched, values, handleChange, handleSubmit } = props
            if(refreshLoading) {
              return (
                <Block col justifyContent="center" alignCenter="center" textAlign="center">
                  <Spinner size={40} />
                  <Text color="grey">Sending Email...</Text>
                </Block>
              )
            }
            return (
              <Form>
                <TextInput
                  label="Email Address"
                  name="email"
                  placeholder="Email Address"
                  type="email"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  {...props}
                />
                <Button
                  mt={3}
                  size="large"
                  block
                  onClick={handleSubmit}
                  text="Send Reset Link"
                  type="submit"
                  variant="alt"
                />
                <Block textAlign="center" mt={2}>
                  <Text color="greyDark" variant="subhead1" onClick={()=>{
                    showForgotPassword(false)
                  }}>Back</Text>
                </Block>
              </Form>
            )
          }}
        </Formik>
      </Block>
    )
  }
  
  return (
    <Block>
      {!loading &&
        <Text
          variant="h4"
          mb={8}
          color="navy"
          textAlign="center"
          display="block"
        >
          {title}
        </Text>
      }
      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        validateOnBlur={false}
        validationSchema={LoginValidation}
        onSubmit={(values) => {
          dispatch(Actions.auth.login.trigger({ ...values, callback }));
        }}
      >
      {(props) => {
        const { errors: clientError, handleBlur, touched, values, handleChange, handleSubmit } = props
        if(loading) {
          return (
            <Block col justifyContent="center" alignCenter="center" textAlign="center">
              <Spinner size={40} />
              <Text color="grey">loading...</Text>
            </Block>
          )
        }

        return (
          <Form>
            <Block col>
              <TextInput
                label="Email Address"
                name="email"
                placeholder="Email Address"
                type="email"
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                {...props}
              />
            </Block>
            <Block mt={3} mb={2} col>
              <Block row justifyContent="space-between">
                <Text
                  as="label"
                  htmlFor="card-element"
                  variant="subhead2"
                  color="navy"
                  mb={1}
                >
                  Password
                </Text>
                <Block>
                  <Text color="brand" onClick={() => showForgotPassword(true)}>Forgot Password?</Text>
                </Block>
              </Block>
              <TextInput
                name="password"
                placeholder="Password"
                type="password"
                value={values.password}
                onBlur={handleBlur}
                onChange={handleChange}
                {...props}
              />
            </Block>
            {ssError?.Error && touched && (
              <Block textAlign="center" mt={4} mb={2}>
                <Text variant="subhead1" color="danger" role="alert">
                  {ssError?.Error}
                </Text>
              </Block>
            )}
            <Button
              mt={3}
              size="large"
              block
              onClick={handleSubmit}
              text="Login"
              type="submit"
              variant="alt"
            />
            <Block textAlign="center">
              <Text py={3}>Dont have an account? <Text color="brand" display="inline-block" onClick={toggleSignUp}>Sign up</Text></Text>
            </Block>
          </Form>
        )
      }}
      </Formik>
    </Block>
  )
}

const mapStateToProps = state => ({
  login: Selectors.auth.login(state),
});

export default connect(mapStateToProps)(Login)