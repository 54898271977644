import React from 'react'
import { Block, Text, Avatar, Icon, getStyle, colors, Button } from 'ui'
import styled, { css } from 'styled-components'
import { useSelector, useDispatch } from 'react-redux';
import { formatAmount } from 'utils' 
import Selectors from 'selectors';
import dayjs from 'dayjs';
import Actions from 'actions';
import { current } from 'immer';


const BubbleContainer = styled.div`
  display:inline-flex;
  flex:1;
  flex-direction:column;
  max-width: 90%;
  //padding-bottom:20px;
  
  ${props =>  props.position === 'left' ? 
    css`
      align-items:flex-start;
      margin:20px 40px 0 0;
    ` : 
    css`
      align-items:flex-end;
      margin:20px 0 0 55px;
    `
  }
  @media (min-width: 320px) and (max-width : 736px)
  {
    margin:10px 20px;
  }
`

const ChatBubble = styled(Block)`
  border:1px solid ${getStyle('colors.greyLighter')};
  display:inline-block;
  padding:10px 20px;
  border-radius:20px;
  background-color:#fff;
  position: relative;
`

const BoostStyle = styled(Block)`
  position:absolute;
  top:-10px;
  border-radius:20px;
  padding:4px 8px;
  align-items:center;
  display:inline-flex;
  justify-content:center;
  ${props => props.bubblePosition === 'left' && css`right:-10px;`}
  ${props => props.bubblePosition === 'right' && css`left:-10px;`}
`

const getStatus = ({messageId, priorityMessagesHistory}) => {
  if(messageId && priorityMessagesHistory) {
    return (
      priorityMessagesHistory
      && priorityMessagesHistory[messageId]
      && priorityMessagesHistory[messageId].status
    );
  }
  return null
};

const statusColors = status => {

  let statusColor;
  let statusMsg;

  switch (status) {
    case 'placed':
      statusColor = 'brand';
      break;
    case 'replied':
      statusColor = 'brandLightest';
      break;
    case 'expired':
      statusColor = 'grey';
      statusMsg = 'Boost Expired';
      break;
    case 'failed':
      statusColor = 'danger';
      statusMsg = 'Boost Failed';
      break;
    default:
      statusColor = 'brandLightest';
  }

  return {
    statusColor,
    statusMsg
  }
}

const Boost = ({currentMessage, position, priorityMessagesHistory}) => {
  const { messageId, msgType, priorityAmount, lastMessage } = currentMessage;

  const status = getStatus({
    messageId,
    priorityMessagesHistory
  });


  if (msgType === '80') {

    const { statusColor } = statusColors(status)

    return (
      <>
        {priorityAmount && msgType === '80' && (
          <BoostStyle bubblePosition={position} bg={statusColor}>
            <Text color="white" variant="button">
              {formatAmount(priorityAmount)}
            </Text>
          </BoostStyle>
        )}
      </>
    )
  }

  return null

}

const Bubble = ({currentMessage, ...props}) => {
  const { position } = props
  const priorityMessagesHistory = useSelector(Selectors.conversations.priorityMessagesHistory);
  const { messageId, message: {transaction} } = currentMessage
  const contentLinkLoading = useSelector(Selectors.conversations.contentLinkLoading)
  const status = getStatus({
    messageId,
    priorityMessagesHistory
  });
  const dispatch = useDispatch()
  const { statusColor, statusMsg } = statusColors(status)

  const viewContent = async () => {
    dispatch(Actions.conversations.content.generateLink.trigger({
      photosetId: transaction?.mediaSetId,
      entitlementId: transaction?.purchaseObjectItemId,
      onSuccess: link => {
        window.location.href = link
      }
    }))
  } 

  return (
    <BubbleContainer position={position}>
      <ChatBubble>
        <Boost currentMessage={currentMessage} position={position} priorityMessagesHistory={priorityMessagesHistory} />
        <Text>{currentMessage?.text}</Text>
        {transaction?.mediaSetId && <Button onClick={viewContent} mt={2} size="medium" text="View Content" iconLeft="eye" />}
      </ChatBubble>
      <Block row mt={2} alignItems="center">
        <Text variant="subhead3" color="greyDark">
          {dayjs(currentMessage?.createdAt).locale('en').format('h:mm A')}
        </Text>
        {(status === 'failed' || status === 'expired') && (
          <Text
            color={statusColor}
            variant="button"
            px={2}
            style={{
              fontSize: 9,
              order: position === 'left' ? 1 : -1,
            }}
          >
            {statusMsg && statusMsg}
          </Text>
        )}
      </Block>
    </BubbleContainer>
  )
}

export default Bubble