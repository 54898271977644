import { useEffect, useState } from 'react'
import { Block, Text, getStyle, Button, Icon, Spinner } from 'ui'
import styled, { css } from 'styled-components';

const TextInputWrapper = styled.div`
  flex:1;
  background-color: ${getStyle('colors.greyLightest')};
  border:1px solid ${getStyle('colors.greyLight')};
  transition: all 0.4s;
  border-radius:24px;
  padding-right: 10px;
  display:flex;
  align-items:center;

  &:focus-within {
    border-color: ${getStyle('colors.greyDark')};
  }

  input {
    font-family: ${getStyle('fonts.body')};
    font-weight:500;
    padding: 14px 30px;
    color: ${getStyle('colors.navy')};
    outline:none;
    min-height: 24px;
    border:none;
    flex:1;
    background:none;
    &::placeholder {
      color: ${getStyle('colors.grey')};
    }
  }
`

const SendBtn = styled.button`
  background-color:${getStyle('colors.brand')};
  width:47px;
  height:47px;
  border:none;
  border-radius: 100%;
  margin-left: 15px;
  cursor:pointer;
  outline:none;
  display:inline-flex;
  align-items:center;
  justify-content: center;
  & > * {
    position:relative;
    left:1px;
  }
`

const FloatingInput = styled.aside`
  text-align:center;
  display:flex;
  align-items:center;
  padding: ${getStyle('space.4')}px;

  @media (min-width: 320px) and (max-width : 736px) {
    position:fixed;
    bottom:60px;
    left:0;
    right:0;
    z-index:999;
    padding: ${getStyle('space.2')}px;
    background-color: ${getStyle('colors.white')};
  }

`

const Input = ({onSend = () => false, loading}) => {

    const [text, setText] = useState('')

    useEffect(() => {
      const listener = event => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
          if(text.length > 0) {
            onSend(text)
            setText('')
          }
          return false;
        }
      };
      document.addEventListener("keydown", listener);
      return () => {
        document.removeEventListener("keydown", listener);
      };
    }, [text, onSend])

    return (
      <FloatingInput>
        <TextInputWrapper>
          <input 
            type="text" 
            placeholder="Send a message" 
            value={text} 
            onChange={e => setText(e.target.value)}
          />
          {loading && <Spinner size={30} />}
        </TextInputWrapper>
        {text.length > 0 && (
          <SendBtn 
            onClick={() => {
              onSend(text)
              setText('')
            }}
          >
            <Icon name="send" size={24} color="white" />
          </SendBtn>  
        )}
      </FloatingInput>
    )
}

export default Input