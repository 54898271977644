import  { Block, Text, getStyle, colors } from 'ui'
import { rgba } from 'polished'
import styled, {css} from 'styled-components'

const NoticeWrapper = styled(Block)`
  padding: 15px 30px;
  border-radius:6px;
  ${props => props.type === 'success' &&
    css `
      background-color:${rgba(colors.brand[0], 0.7)};
    `
  }
  ${props => props.type === 'error' &&
      css `background-color:${rgba(colors.danger[0], 0.7)};
      `
  }
`


const Notice  = ({
  visible, 
  type = 'success', 
  children, 
  text,
  ...props
}) => {
  if(visible) {
    return (
      <Block as={NoticeWrapper} type={type} {...props}>
        <Text color="navy">{text}</Text>
      </Block>
    )
  }
  return null
}


export default Notice