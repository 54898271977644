import { handleActionsImmer } from 'utils';
import Actions from 'actions';
import _ from 'lodash';

export const initialState = {
  current: null,
  error: null,
  loading: false,
  myProfile: {
    loading: false,
    shareURL: null,
  },
  loadMyProfile: {},
  updateMyProfile: {},
  userProfiles: [],
  email: null,
  user: {},
  userLoading: false,
  earningsLoading: false,
  updateProfileLoading: false,
  profile: {},
  convoUser: {},
  isOpen: false,
  isRegister: false,
};

const {
  profile: { myProfile, loadUser, loadEarnings, getProfileLink, loadProfile, loadEarningsSummary, toggleSidebar, toggleRegister },
} = Actions;

const Reducer = handleActionsImmer(
  {
    [Actions.auth.logout.trigger]: () => initialState,
    [myProfile.load.success]: (draft, data) => {
      draft.myProfile = { ...data };
      draft.email = data.cognitoEmail;
    },
    [myProfile.load.request]: draft => {
      draft.myProfile.loading = true;
    },
    [myProfile.load.fulfill]: draft => {
      draft.myProfile.loading = false;
    },
    [myProfile.update.request]: draft => {
      draft.updateProfileLoading = true;
    },
    [myProfile.update.fulfill]: draft => {
      draft.myProfile.loading = false;
      draft.updateProfileLoading = false;
    },
    [myProfile.update.success]: (draft, data) => {
      draft.myProfile = {...data};
      draft.email = data.cognitoEmail;
      draft.myProfile.loading = false;
    },
    [loadUser.trigger]: draft => {
      draft.loading = true;
    },
    [loadUser.success]: (draft, profile) => {
      const profiles = draft.userProfiles || [];
      profiles.unshift(profile);
      draft.userProfiles = _.uniqBy(profiles, 'userId');
      draft.convoUser = profile;
    },
    [loadUser.fulfill]: draft => {
      draft.loading = false;
    },
    [myProfile.updateImage]: (draft, data) => {
      draft.myProfile.profileImage = data.profileImage;
    },
    [myProfile.clearImage]: draft => {
      draft.myProfile.profileImage = null;
    },
    [myProfile.uploadPhoto.request]: draft => {
      draft.myProfile.loading = true;
    },
    [myProfile.uploadPhoto.success]: draft => {
      draft.myProfile.loading = false;
    },
    [myProfile.uploadPhoto.fulfill]: draft => {
      draft.myProfile.loading = false;
    },
    [loadEarnings.request]: draft => {
      draft.earningsLoading = true;
    },
    [loadEarnings.success]: (draft, data) => {
      draft.myProfile.earnings = data;
      draft.earningsLoading = false;
    },
    [loadEarningsSummary.trigger]: draft => {
      draft.earningsLoading = true;
    },
    [loadEarningsSummary.success]: (draft, data) => {
      draft.myProfile.earnings = data;
      draft.earningsLoading = false;
    },
    [getProfileLink.success]: (draft, data) => {
      draft.myProfile.shareURL = data;
    },
    [loadProfile.request]: draft => {
      draft.userLoading = true;
    },
    [loadProfile.success]: (draft, data) => {
      draft.userLoading = false;
      draft.user = data
    },
    [loadProfile.failure]: (draft, { error }) => {
      draft.userLoading = false;
      draft.user = {};
      draft.user.error = error;
    },
    [toggleSidebar]: (draft, status) => {
      draft.isOpen = status
    },
    [toggleRegister]: (draft, status) => {
      draft.isRegister = status
    }
  },
  initialState,
  {
    loadMyProfile: myProfile.load,
    updateMyProfile: myProfile.update,
  },
);

export default Reducer;
