/* eslint global-require: 0 */
import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import axios from 'axios';
import Narrate, { configureMobile } from '@narrate/app-sdk';
import localConfig from '@narrate/app-sdk/config/firebase-config.json';
import SagaManager from './SagaManager';
import reducer from './reducer';
import { getConfig } from './firebase'

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
let remoteEnv = null;

const __DEV__ = process.env.NODE_ENV === 'development'

if (__DEV__) {
  const loggerMiddleware = createLogger({ collapsed: true });
  middlewares.push(loggerMiddleware);
}

const configureStore = () => {

  const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
  : compose

  const enhancer = composeEnhancers(applyMiddleware(...middlewares))
  const store = createStore(reducer, enhancer)
  const persistor = persistStore(store)

  // let config = null;

  // const setConfig = async () => {
  //   config = await getConfig();
  //   console.log(config)
  // }
  

  // setConfig()


  // // configureMobile(Narrate, {
  // //   config,
  // //   axios,
  // //   store,
  // // });


  // if (!config) {
  //   remoteEnv = process.env.REACT_ENV
  //   try {
  //     config = JSON.parse(localConfig.parameters[remoteEnv].defaultValue.value);
      
  //   } catch (error) {
  //     console.log(error)
  //   }

  //   configureMobile(Narrate, {
  //     config,
  //     axios,
  //     store,
  //   });
  // }


  SagaManager.startSagas(sagaMiddleware);

  if (module.hot) {
    const reducerCallback = () => {
      const nextRootReducer = require('./reducer').default;
      store.replaceReducer(nextRootReducer);
    };

    module.hot.accept('./reducer', reducerCallback);
    module.hot.acceptCallback = reducerCallback;

    const sagaCallback = () => {
      SagaManager.cancelSagas(store);
      require('./SagaManager').default.startSagas(sagaMiddleware);
    };

    module.hot.accept('./SagaManager', sagaCallback);
    module.hot.acceptCallback = sagaCallback;
  }

  return { store, persistor };
};

export default configureStore;
