import { Link } from "react-router-dom";
import { event } from 'utils/ga'
import { Block, Text } from 'ui'

export default function Footer(props) {
  const onEmail = (e) => {
    const text = 'contactATnarrateappDOTcom'
    e.preventDefault()
    event({
      category: 'Profile',
      action: 'footer_click',
      label: 'contact'
    })

    const email = text.replace(/AT/, '@').replace(/DOT/, '.')
    window.location.href = `mailto:${email}`
  }

  return (
    <Block {...props}>
      <Text color="grey" display="inline-block" variant="subhead2" mr={2}>
        &copy;
        {`${new Date().getFullYear()} Narrate`}
      </Text>
      <Link to="/terms" onClick={() => {
        event({
          category: 'Profile',
          action: 'footer_click',
          label: 'terms'
        })
      }}>
        <Text color="grey" display="inline-block" variant="subhead2">Terms of Use</Text>
      </Link>
      <Link to="/terms#privacy" onClick={() => {
        event({
          category: 'Profile',
          action: 'footer_click',
          label: 'privacy'
        })
      }}>
        <Text mx={2} color="grey" display="inline-block" variant="subhead2">Privacy</Text>
      </Link>
      <Text color="grey" display="inline-block" variant="subhead2" onClick={onEmail}>Contact Us</Text>
    </Block>
  )
}
