import { createActions } from 'redux-actions';
import { DefaultRoutine } from 'utils';

const Actions = createActions({
  SETTINGS: {
    REPORT_ISSUE: DefaultRoutine,
    REPORT_MODAL: undefined
  },
});

export default Actions;
