import Login from './Login'
import Signup from './Signup'
import Selectors from 'selectors'
import { useSelector } from 'react-redux';

function LoginSignUp({callback, visible, title: {login, signup}}) {
  const register = useSelector(Selectors.profile.isRegister)

  return (
    <>
      {register ? <Signup callback={callback} title={signup} /> : <Login callback={callback} title={login} />}
    </>
  )
}

export default LoginSignUp