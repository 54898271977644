import React from 'react';
import ReactDOM from 'react-dom';
import { Root } from 'components/views';
import { RootRouteComponentWithSubRoutes, routes } from 'components/routes'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter } from 'react-router-dom'
import configureStore from 'store'
import reportWebVitals from './reportWebVitals';
import Narrate, { configureMobile } from '@narrate/app-sdk';
import { getConfig } from 'store/firebase'
import axios from 'axios';

const { store, persistor } = configureStore()
const startApp = async () => {
  const config = await getConfig();

  configureMobile(Narrate, {
    config,
    axios,
    store,
  });

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Root />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

startApp();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
