import { createActions } from 'redux-actions';
import { DefaultRoutine } from 'utils';

const Actions = createActions({
  CONVERSATIONS: {
    LOAD_LIST: DefaultRoutine,
    LOAD_MORE: DefaultRoutine,
    LOAD_ONE: DefaultRoutine,
    LOAD_NEXT: DefaultRoutine,
    LOAD_PREVIOUS: DefaultRoutine,
    SET_UNREAD_STATUS: DefaultRoutine,
    SEND_MESSAGE: DefaultRoutine,
    SEND_LOCAL_MESSAGE: undefined,
    DESTROY: DefaultRoutine,
    SEND_IMAGE: DefaultRoutine,
    NEW_MESSAGE_TOGGLE: undefined,
    IMAGE_DRAWER: {
      SHOW: undefined,
      HIDE: undefined,
    },
    CLEAR: undefined,
    ON_BOOST: undefined,
    IMAGES: {
      TOGGLE: undefined,
      ADD: undefined,
      REMOVE: undefined,
      CLEAR: undefined,
    },
    POLL: {
      START: undefined,
      STOP: undefined,
    },
    CONTENT: {
      GENERATE_LINK: DefaultRoutine
    }
  },
});

export default Actions;
