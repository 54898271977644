import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import Player from './Player'
import { Block, Text, Spinner } from 'ui'
import RefreshLink from './refreshLink'
import { getContent } from './gallery'
import { inapp, isMobile } from 'utils'


function Preview() {
  const { push } = useHistory()
  const { sk, id: mediaSetId, previewId } = useParams()
  const [photosetItem, setContent] = useState('')
  const [errors, setErrors] = useState('')
  const [loading, setLoading] = useState(true)
  

  useEffect(() => {
    async function load() {
      const { mediaSetItem, errors  } = await getContent({
        mediaSetId,
        sk
      })

      setContent(mediaSetItem)
      setErrors(errors)
      setLoading(false)
    }
    load()
  }, [mediaSetId, sk])

  const media = photosetItem?.media
  const currentIndex = media?.findIndex((item) => item.itemId === previewId) || null
  const current = media?.filter((item) => item.itemId === previewId)[0]
  const next = media && media[currentIndex + 1] ? media[currentIndex + 1].itemId : false || false;
  const previous = media && media[currentIndex - 1] ? media[currentIndex - 1].itemId : false || false;

  return (
    <>
      <Helmet>
        <title>{photosetItem?.title || 'Not Found'}</title>
        <meta property="og:title" content={photosetItem?.title || 'Not Found'} />
        <meta property="og:type" content="gallery" />
        <meta name="viewport" content="initial-scale=1.0, maximum-scale=2.0" />
      </Helmet>

      {errors?.Error === 'Not found' && (<RefreshLink errors={errors} mediaSetId={mediaSetId} />)}

      {current ? (
        <>
          <Block bg="black" position="fixed" top={0} left={0} bottom={0} right={0} zIndex={9999}>
            <Block
              textAlign="center"
              position="relative"
              className="narrate__container"
              style={{
                height:'100%'
              }}
            >
            <div className="narrate__controls">
              <button
                type="button"
                aria-label="Close lightbox"
                className="ril-close ril-toolbar__item__child ril__toolbarItemChild ril__builtinButton ril__closeButton"
                style={{
                  position: 'fixed',
                  top: 0,
                  right: 0,
                  zIndex: 9999
                }}
                onClick={() => {
                  push({
                    pathname: `/s/${mediaSetId}/${sk}`
                  })
                }}
              />

              {next && (
                <button
                  type="button"
                  className="ril-next-button ril__navButtons ril__navButtonNext"
                  aria-label="Next image"
                  onClick={() => {
                    push({
                      pathname: `/s/${mediaSetId}/${sk}/${next}`
                    })
                  }}
                />
              )}

              {previous && (
                <button
                  type="button"
                  className="ril-prev-button ril__navButtons ril__navButtonPrev"
                  aria-label="Previous image"
                  onClick={() => {
                    push({
                      pathname: `/s/${mediaSetId}/${sk}/${previous}`
                    })
                  }}
                />
              )}

              <Block
                flex={1}
                textAlign="center"
                position="fixed"
                zIndex={9999}
                bottom={0}
                right={0}
                left={0}
                style={{
                  pointerEvents: 'none'
                }}
              >
                <Text
                  variant="h6"
                  p={4}
                  color="white"
                >
                  {currentIndex + 1}
                  {' '}
                  of
                  {' '}
                  {photosetItem?.media?.length}
                </Text>
              </Block>

              </div>


              {current?.streamingUrl ? (
                <Player src={current?.streamingUrl} poster={current?.thumbnailUrl} inapp={inapp} />
              ) : (
                <>
                <Block
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  bottom={0}
                  zIndex={999}
                />
                <img
                  style={{
                    position: 'relative',
                    userSelect: 'none',
                    height:'100%',
                    width:'100%',
                    objectFit: 'contain',
                  }}
                  draggable={false}
                  src={current?.url}
                  alt=""
                />
                </>
              )}

            </Block>

          </Block>
        </>
      ) : (
        <Block
          flex={1}
          alignItems="center"
          display="flex"
          justifyContent="center"
          minHeight="calc(100vh)"
          p={5}
        >
          {!current && loading ? (
            <Spinner/>
          ) : (
          <Block
            flex={1}
            textAlign="center"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            maxWidth={350}
          >
            <Text variant="h2" pb={3} color="navy">No Content Added.</Text>
            <Text variant="subhead1" pb={4} color="greyDark">It appears no content has been added.</Text>
          </Block>
          )}
        </Block>
      )}
    </>
  )
}

// export async function getServerSideProps({ params, req }) {
//   const isMobile = (req
//     ? req.headers['user-agent']
//     : navigator.userAgent).match(/iPhone|iPad|iPod|Android/i)

//   const content = await getPhotoSet({
//     mediaSetId: params.id,
//     sk: params.sk
//   })
//   const media = content?.mediaSetItem?.media
//   const currentIndex = media?.findIndex((item) => item.itemId === params.preview) || null
//   const current = media?.filter((item) => item.itemId === params.preview)[0]


//   return {
//     props: {
//       content,
//       isMobile,
//       mediaSetId: params.id,
//       media: current || null,
//       currentIndex,
//       sk: params.sk,
//       next: media && media[currentIndex + 1] ? media[currentIndex + 1].itemId : false || false,
//       previous: media && media[currentIndex - 1] ? media[currentIndex - 1].itemId : false || false,
//       inapp: req.headers['user-agent'].match(/NarrateApp/i)
//     }
//   }
// }

export default Preview
