import { Route, Switch, Redirect } from "react-router-dom";
import ContentGallery from './gallery'
import ContentView from './preview'


function Content() {
  return (
    <Switch>
      <Route exact path="/s/:id/:sk" component={ContentGallery} />
      <Route path="/s/:id/:sk/:previewId" component={ContentView} />
      <Redirect exact from="/s/:id" to="/s/:id/expired" />
    </Switch>
  )
}

export default Content