import _ from 'lodash';
import { put, call, cancel } from 'redux-saga/effects';

export function routineSaga({ filters = [], routine, request, afterSuccess, onError, internal }) {
  return function*(action) {
    try {
      // eslint-disable-next-line no-restricted-syntax
      for (const filter of filters) {
        const allow = yield call(filter, action);
        if (!allow) {
          return;
        }
      }

      yield put(routine.request(action.payload));
      const returnValue = yield request(action);
      yield put(routine.success(returnValue));
      if (afterSuccess) {
        yield call(afterSuccess, returnValue);
      }
    } catch (error) {
      if (onError) {
        yield call(onError, error);
      } else {
        const errors = _.get(error, 'response.data.errors');
        const internalError = _.get(error, 'internal', internal);

        if (errors) {
          yield put(routine.failure({ error: errors, internal: internalError }));
        } else {
          yield put(routine.failure({ error, internal: internalError }));
        }
      }
    } finally {
      yield put(routine.fulfill());
    }
  };
}

export function sendTo(actionFunction, payloadOptions) {
  return function*({ payload }) {
    yield put(actionFunction({ ...payload, ...payloadOptions }));
  };
}

let nextTickProm;

function* deferredExecute(fn, ...args) {
  if (!nextTickProm) {
    nextTickProm = Promise.resolve().then(() => {
      nextTickProm = undefined;
    });
  }
  yield call(() => nextTickProm);
  if (typeof fn !== 'function') {
    return;
  }
  return yield call(fn, ...args);
}

export function* defer(effect, ...args) {
  if ([cancel].includes(effect) || !effect) {
    // deferred effects without fnDescriptor
    // footprint
    yield call(deferredExecute);
    if (!effect) {
      return;
    }
    return yield effect(...args);
  }
  return yield effect(deferredExecute, ...args);
}

export default {};
