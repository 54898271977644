import styled, { css } from 'styled-components'
import { get, COMMON } from './constants'
import { Icon, Text } from '.'

const avatarStyle = css`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: ${(props) => props.size / 2}px;
  overflow: hidden;
  background-color: ${get('colors.greyLight')};
  border-color: ${get('colors.white')};
  border-width: ${(props) => props.size / 30}px;
  border:2px solid #fff;
  transition: all 0.4s;
  ${COMMON}
  ${(props) => props.style}
  ${props => props.active && css`
    border-color: ${get('colors.brand')};
  `}
  ${props => props.onClick && css`
    cursor:pointer;
  `}
`

const IconContainer = styled.span`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  justify-content: center;
  align-items: center;
  display:inline-flex;
  border-radius:100%;
  position:relative;
  ${COMMON}
  ${(props) => props.style}
  z-index:1;
  &:after {
    content:'';
    position:absolute;
    top:50%;
    left:50%;
    width:70%;
    height:70%;
    border-radius:100%;
    transform:translate(-50%,-50%);
    background-color: ${props => get(`colors.${props.bgColor}`)};
  }
`

const IconAvatar = ({ name, size, color, iconBg = 'white', ...props }) => (
  <IconContainer {...props} size={size} bgColor={iconBg}>
    <Icon name={name} color={color} size={size} style={{zIndex: 1}} />
  </IconContainer>
)

const ImgAvatar = styled.img`
  ${avatarStyle}
`

export default function Avatar({ photo, onClick, username, size, iconStyle, defaultIcon, iconColor = "greyLight", ...props }) {
  if (photo) {
    return (
      <ImgAvatar
        {...props}
        size={size}
        src={photo}
        onClick={onClick}
      />
    )
  }

  return (
    <IconAvatar
      name={defaultIcon}
      size={size + 5}
      color={iconColor}
      style={iconStyle}
      onClick={onClick}
      {...props}
    />
  )
}

Avatar.defaultProps = {
  useInitials: false,
  defaultIcon: 'user',
  size: 30,
}
