import { Block, Text, TextInput, Button, Spinner, Notice } from 'ui'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import Selectors from 'selectors'
import { useState } from 'react'
import { ReportIssueValidation } from 'utils/validation'

const ReportContent = props => {
  const dispatch = useDispatch()
  const reportData = useSelector(Selectors.settings.reportData)
  const {loading} = useSelector(Selectors.settings.sendReport)
  const [message, setMessage] = useState(false)
  return (
    <Block width={{
      mobile: '100%',
      desktop: '400px'
    }} col>
      <Text
        variant="h4"
        mb={1}
        color="navy"
        textAlign="left"
      >
        Report Issue
      </Text>
      <Text color="greyDark" mb={8}>Please help us maintain safe and comfortable community by letting us know what you found inappropriate about this content.</Text>
      <Formik
        initialValues={{
          report_issue: ''
        }}
        validationSchema={ReportIssueValidation}
        onSubmit={(values, {resetForm}) => {
          const data = {
            ...values,
            ...reportData
          }


          dispatch(Actions.settings.reportIssue.trigger({ userId: data.userId, ...values, callback: () => {
            setMessage(`Thanks! We'll investigate this issue as soon as possible.`)
            setTimeout(() => {
              dispatch(Actions.settings.reportModal({
                visible: false
              }))
              resetForm({})
              setMessage(false)
            }, 3000)
          } }));
        }}
      >
         {(props) => {

          const { errors: clientError, handleBlur, touched, values, handleChange, handleSubmit } = props

          if(loading) {
            return (
              <Block col justifyContent="center" alignCenter="center" textAlign="center">
                <Spinner size={40} />
                <Text color="grey">loading...</Text>
              </Block>
            )
          }

          if(message) {
            return (
              <Notice text={message} visible mb={4} type="success" />
            )
          }

        return (
          <>
            <Block col>
              <TextInput
                name="report_issue"
                placeholder="Message"
                textarea
                value={values.report_issue}
                onBlur={handleBlur}
                onChange={handleChange}
                {...props}
              />
            </Block>
            <Button
              mt={3}
              size="large"
              block
              onClick={handleSubmit}
              text="Send"
              type="submit"
              variant="alt"
            />

          </>
        )

      }}
      </Formik>
    </Block>
  )
}

export default ReportContent