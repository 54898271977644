import React, { useState } from 'react'
import { Block, Text, Icon } from 'ui'
import styled from 'styled-components'
import { get } from 'components/common/ui/constants'

const Container = styled(Block)`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  background: ${get('colors.white')};
  border-radius: 6px;
  border: 1px solid ${get('colors.greyLightest')};
  box-shadow: 0 2px 3px rgba(0,0,0,0.03);
  overflow:hidden;

  @media (min-width: 320px) and (max-width : 736px)
  {
      box-shadow:none;
      border:none;
      background:transparent;

      img {
        border-radius:6px;
      }
  }

`

const List = styled.ul`
  list-style:none;
  max-width:330px;
  width:100%;
  margin:0 auto;
  padding:0 20px;
  text-align:center;
  display:flex;
  flex-direction:column;

  @media (min-width: 320px) and (max-width : 736px)
  {
      padding: 0 45px;
  }

  li {
    text-align:left;
    color:${get('colors.navy')};
    display:flex;
    align-items:center;
    margin:0 0 4px 0;
    font-size:14px;
    font-weight:500;

    @media (min-width: 320px) and (max-width : 736px) {
      font-size:12px;
    }
  }

  .circle {
    border-radius:100%;
    background:${get('colors.navy')};
    width:14px;
    display:flex;
    align-items:center;
    justify-content:center;
    height:14px;
    margin:0 6px 0 0;
  }
`

const CircleCheck = () => (
  <div className="circle">
    <Icon name="check" color="brand" size={16} />
  </div>
)

export default function ItemBlock({ isSub, ...props }) {
  const { accessImage, headline, description } = props

  const [detailVisible, setVisible] = useState(false)

  const toggleDetails = () => {
    setVisible(!detailVisible)
  }

  return (
    <Container
      display="flex"
      col
      flex={1}
      alignItems={{
        mobile: 'center',
        desktop: 'flex-end'
      }}
      bg="red"
      borderRadius="6"
    >
      <Block
        col
        width="100%"
        order={{
          mobile: 0,
          desktop: 0
        }}
        textAlign={{
          mobile: 'center',
          desktop: 'left'
        }}
      >
        <Block
          px={{
            mobile: 4,
            desktop: 0
          }}
          minHeight={{
            mobile: '190px',
            desktop: 'auto'
          }}
        >
          <img
            width="100%"
            draggable={false}
            src={accessImage}
            alt={headline}
            onError={(e) => {
              e.target.onerror = null
              e.target.src = '/images/default.jpg'
            }}
          />
        </Block>
        <Block textAlign="center" mb={2} id="benefits">
          <Block onClick={() => toggleDetails()} mt={3} alignItems="center">
            <Text variant="h5" color="navy" px={4}>
              {headline}
              <Icon ml={6} size={22} color="greyDark" name={detailVisible ? 'chevron-down' : 'chevron-up'} />
            </Text>
          </Block>
          {detailVisible && (
            <Text my={3} px={4} variant="subhead3">
              {description}
            </Text>
          )}
        </Block>
        <Block
          px={8}
          display={{
            mobile: 'block',
            desktop: 'none'
          }}
        >
          <Block bg="greyLight" width="100%" height="1px" px={3} />
        </Block>
        <Block mt={3} mb={4} textAlign="center">
          {isSub ? (
            <>
              <Text variant="subhead2" color="navy" mb={2}>SUBSCRIPTION BENEFITS</Text>
              <List>
                <li>
                  <CircleCheck />
                  {' '}
                  Connect and communicate privately
                </li>
                <li>
                  <CircleCheck />
                  {' '}
                  Instantly get exclusive access
                </li>
                <li>
                  <CircleCheck />
                  {' '}
                  Cancel your subscription at any time
                </li>
              </List>
            </>
          ) : (
            <>
              <Text variant="subhead2" color="navy" mb={2}>PURCHASE BENEFITS</Text>
              <List>
                <li>
                  <CircleCheck />
                  {' '}
                  Connect and communicate privately
                </li>
                <li>
                  <CircleCheck />
                  {' '}
                  Instantly get exclusive access
                </li>
                <li>
                  <CircleCheck />
                  {' '}
                  Become a VIP supporter
                </li>
              </List>
            </>
          )}
        </Block>
      </Block>
    </Container>
  )
}
