import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import { Block, Text, Avatar, Icon, Button, Spinner } from 'ui'
import Login from 'components/auth/Login'
import styled from 'styled-components';

const Wrapper = styled.div`
  height:100%;
  width:100%;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  padding:0 20px;
`

const Box = styled(Block)`
  width:100%;
  padding:30px;
  box-shadow:0 8px 30px rgb(0,0,0,0.05);
  max-width:450px;
`

function LogIn({ callback }) {
  const { push } = useHistory()
  return (
    <Wrapper>
      <Block textAlign="center" pb={5} col>
        <Icon name="logo" color="brand" size={40} />
      </Block>
      <Box bg="white" borderRadius={6}>
        <Login
          standalone
          callback={()=> {
            setTimeout(()=> push('/conversations'), 500) // wait 5ms for state to hydrate
          }}
          title="login to connect with creators"
        />
      </Box>
    </Wrapper>
  )
}

export default LogIn