import Narrate from '@narrate/app-sdk';
import { call, takeEvery } from 'redux-saga/effects';
import { routineSaga } from 'utils/sagas';
import Actions from 'actions';

const sendReport = routineSaga({
  routine: Actions.settings.reportIssue,
  *request({ payload: { report_issue, accessBlockId, userId, type, callback } }) {
    if (accessBlockId) {
      yield Narrate.api.report.reportAccessBlock(report_issue, accessBlockId);
    } else if (type === 'Payment') {
      yield Narrate.api.report.requestPayment(report_issue, userId);
    } else if (type === 'conversation') {
      yield Narrate.api.report.reportUser(report_issue, userId);
    } else {
      yield Narrate.api.report.reportAppIssue(report_issue);
    }

    if (callback) {
      callback();
    }
  },
});

export default function* combined() {
  yield takeEvery(Actions.settings.reportIssue.trigger, sendReport);
}
