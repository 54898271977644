import React from "react";
import { Route, Switch } from "react-router-dom";
import { Profile } from 'components/views'


export const routes = [
  {
    path: "/:username",
    component: Profile
  }
]

const routeMap = generateMap(routes)

function generateMap(routeMap) {
  return routeMap.map(route => {
    return {
      ...route,
      //key: `${route.component.name.toLowerCase()}-route`
    }
  })
}

export const RootAppComponentWithRoutes = ({ routes }) => (
  <RootRouteComponentWithSubRoutes routes={routes} />
)

export const RouteWithSubRoutes = (route) => {
  return (
    <Route
      path={route.path}
      render={props => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
}

export const RootRouteComponentWithSubRoutes = ({ routes }) => (
  <Switch>
    {routeMap.map((route) => <RouteWithSubRoutes key={route.key} {...route} />)}
  </Switch>
)


