import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Block, Text, TextInput, Button } from 'ui'
import { refreshLink } from 'modules/api'

const ResetSchema = Yup.object().shape({
  email: Yup.string().email().required('Email is required'),
})


function ResendLink({ errors, mediaSetId }) {
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')

  async function sendLink({ email }) {
    const result = await refreshLink({
      mediaSetId,
      userEmail: email
    })

    if (result?.errors) {
      setError(result?.errors.Error)
    }
    if (result?.status === 'success') {
      setMessage(result.message)
    }
  }

  return (
    <>
      {errors?.Error === 'Not found' && (
        <Block
          flex={1}
          alignItems="center"
          display="flex"
          justifyContent="center"
          minHeight="calc(100vh - 200px)"
          p={5}
        >
          <Block
            flex={1}
            textAlign="center"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            maxWidth={350}
          >
            <Text variant="h2" pb={8} color="navy">Link Expired.</Text>
            <Text variant="subhead1" pb={4} color="greyDark">This link is no longer valid. If you purchased this content, please enter your email and we'll send you a new link to access this content.</Text>

            <Formik
              initialValues={{
                email: ''
              }}
              validationSchema={ResetSchema}
              onSubmit={(values) => {
                sendLink({
                  email: values.email
                })
              }}
            >

              {(formik) => {
                const { errors: clientError, handleBlur, touched, values, handleChange, handleSubmit } = formik
                return (
                  <form
                    style={{
                      width: '100%'
                    }}
                    onSubmit={handleSubmit}
                  >
                    <TextInput
                      id="email"
                      name="email"
                      placeholder="Email Address"
                      type="email"
                      value={values.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={clientError.email && touched.email}
                      mb={2}
                    />

                    {message && (
                      <Text
                        variant="subhead3"
                        color="brand"
                      >
                        {message}
                      </Text>
                    )}
                    {clientError.email && touched.email && (
                      <Text
                        color="danger"
                      >
                        {clientError.email}
                      </Text>
                    )}
                    {error && !message && (
                      <Block>
                        <Text
                          style={{
                            textTransform: 'lowercase'
                          }}
                          color="danger"
                          display="block"
                        >
                          {error}
                        </Text>
                      </Block>
                    )}
                    <Button
                      mt={3}
                      size="large"
                      block
                      type="submit"
                      text="Send me a new link"
                    />
                  </form>
                )
              }}

            </Formik>
          </Block>
        </Block>
      )}
    </>
  )
}

export default ResendLink
