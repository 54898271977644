import { handleActionsImmer } from 'utils';
import Actions from 'actions';

export const initialState = {
  paymentIntent: {},
  previousIntent: {},
  loading: false,
  paymentMethods: [],
  errors: null
};

const {
  payment: {
    paymentIntent,
    clearIntent,
    paymentConfirm,
    paymentMethods,
    paymentChoice
  },
} = Actions;

const Reducer = handleActionsImmer(
  {
    [Actions.auth.logout.trigger]: () => initialState,
    [paymentIntent.success]: (draft, data) => {
      draft.paymentIntent = data
      draft.previousIntent = data
    },
    [paymentIntent.fulfill]: draft => {
      draft.loading = false
    },
    [paymentIntent.request]: draft => {
      draft.loading = true
    },
    [paymentIntent.failure]: (draft, { error }) => {
      draft.loading = false;
      draft.errors = error;
    },
    [clearIntent]: (draft) => {
      draft.paymentIntent = {}
    },
    [paymentConfirm.trigger]: draft => {
      draft.previousIntent = {}
      draft.paymentIntent = {}
    },
    [paymentMethods.success]: (draft, { paymentMethods }) => {
      draft.paymentMethods = paymentMethods
    },
    [paymentChoice]: (draft, data) => {
      draft.paymentIntent.paymentInfo = data
    }
  },
  initialState,
);

export default Reducer;
