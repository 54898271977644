import { all, fork } from 'redux-saga/effects';
import Auth from 'modules/auth';
import Conversations from 'modules/conversations';
import Home from 'modules/home';
import Profile from 'modules/profile';
import Posts from 'modules/posts';
import Search from 'modules/search';
import Settings from 'modules/settings';
import Payment from 'modules/payment';

export default function* sagas() {
  yield all([
    fork(Auth.Sagas),
    fork(Conversations.Sagas),
    // fork(Home.Sagas),
    fork(Profile.Sagas),
    // fork(Posts.Sagas),
    fork(Search.Sagas),
    fork(Settings.Sagas),
    fork(Payment.Sagas),
  ]);
}
