import styled, { css } from 'styled-components'
import { get } from './constants'
import Overlay from '../Overlay'
import { Icon, Block } from 'ui'

const Dialog = styled.div`
  background-color: ${get('colors.white')};
  border-radius: ${get('radii.2')}px;
  overflow: hidden;
  position: fixed;
  ${(props) => props.style}
  transition:all 0.4s;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  opacity:0;
  box-shadow: 0 20px 20px rgba(0,0,0,0.08);
  min-width: 400px;
  padding-top: 20px;
  pointer-events: none;

  ${props => props.visible && css`
    transform: translate(-50%, -50%);
    opacity: 1;
    pointer-events: auto;
  `}

  main {
    padding: 50px;
  }

  @media (min-width: 320px) and (max-width : 736px)
  {
    min-width:90%;

    main {
      padding: 30px;
    }
  }

`

const Close = styled(Block)`
  position: absolute;
  top: 20px;
  right:20px;
  cursor:pointer;
`

const Modal = ({isVisible = true, children, onClose}) => {
  return (
    <>
      <Dialog visible={isVisible}>
        <Close onClick={onClose}>
          <Icon name="close" size={26} position="absolute" color="grey" />
        </Close>
        <main>
          {children}
        </main>
      </Dialog>
      <Overlay visible={isVisible} onClick={onClose} />
    </>
  )
}

export default Modal