import { handleActionsImmer } from 'utils';
import Actions from 'actions';

export const initialState = {
  error: null,
  loading: false,
  feed: [],
};

const {
  home: { feed },
} = Actions;

const Reducer = handleActionsImmer(
  {
    [feed.load.request]: draft => {
      draft.loading = true;
    },
    [feed.load.success]: (draft, data) => {
      draft.loading = false;
      draft.feed = data;
    },
    [feed.load.failure]: draft => {
      draft.loading = false;
    },
  },
  initialState,
);

export default Reducer;
