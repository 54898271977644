import _ from 'lodash';
import { combineActions } from 'redux-actions';
import { handleActionsImmer } from 'utils';
import Actions from 'actions';


export const initialState = {
  loadList: {
    Count: 0,
    error: null,
    Items: [],
    HasMore: false,
    loading: false,
  },
  list: [], // has data needed for inbox listing
  conversations: {
    loading: false,
  }, // loaded conversations with full data
  paymentMethodSelected: false,
  selectedPaymentMethod: null,
  supportAmount: null,
  disablePurchaseButton: false,
  pendingPriorityMessage: null,
  boostLoading: false,
  lastMessage: null,
  priorityConvo: null,
  selectedLoading: false,
  error: false,
  priorityMessagesHistory: [],
  conversationMeta: {
    initialUserId: null,
    participantUserId: null,
  },
  sending: false,
  polling: false,
  localMessage: false,
  hasPreviousMessages: true,
  selectedPhotos: [],
  imageURL: null,
  isVisible: '',
  imageDrawerStatus: '',
  newMessages: [],
  newMessageVisible: false,
  contentLink: null,
  contentLinkLoading: false,
  loadMoreLoading: false
};

const { conversations: {
  loadList,
  loadOne,
  loadMore,
  sendMessage,
  sendLocalMessage,
  setUnreadStatus,
  loadPrevious,
  loadNext,
  destroy,
  clear,
  images,
  poll,
  sendImage,
  imageDrawer,
  newMessageToggle,
  content: {generateLink}
} } = Actions;

const Reducer = handleActionsImmer(
  {
    [Actions.auth.logout.trigger]: () => initialState,
    [loadList.request]: draft => {
      draft.loadList.loading = true;
    },
    [loadList.success]: (draft, data) => {
      const { Items, HasMore } = data;
      draft.list = Items;
      draft.loadList.HasMore = HasMore;
      draft.loadList.loading = false;
    },
    [loadMore.trigger]: draft => {
      draft.loadMoreLoading = true
    },
    [loadMore.success]: (draft, data) => {
      draft.loadMoreLoading = true;
      if (data) {
        draft.loadList.loading = false;
        // draft.loadList.total += data.Count
        draft.list = [...draft.list, ...data.Items];
        draft.loadList.HasMore = data.HasMore;
      } else {
        draft.loadList.HasMore = false;
      }
    },
    [loadOne.trigger]: draft => {
      draft.conversations.loading = true;
    },
    [loadOne.fulfill]: draft => {
      draft.conversations.loading = false;
    },
    [destroy.trigger]: (draft, { initialUserId, participantUserId }) => {
      draft.list = draft.list.filter(convo => !_.isMatch(convo, { initialUserId, participantUserId }));
    },
    [loadPrevious.success]: (draft, conversation) => {
      const { messages, key, currentMessages, hasMore } = conversation;
      draft.conversations[key] = {
        messages: [...messages, ...currentMessages],
      };
      draft.hasPreviousMessages = hasMore;
    },
    [loadNext.success]: (draft, conversation) => {
      // We need to filter out messages sent by the user of the
      // device since we are sending those instantly
      const { key, messages, newMessages, userId } = conversation;
      draft.newMessages = newMessages;
      const otherUserMessages = draft.newMessages.filter(message => message.userId !== userId);
      draft.conversations[key] = {
        messages: [...messages, ...otherUserMessages],
      };
    },
    [loadNext.success]: draft => {
      draft.newMessages = [];
    },
    [imageDrawer.show]: draft => {
      draft.imageDrawerStatus = 'ImageDrawer';
    },
    [imageDrawer.hide]: draft => {
      draft.imageDrawerStatus = '';
    },
    [sendLocalMessage.success]: (draft, data) => {
      draft.localMessage = true;
      if (data.msgType === '80') {
        draft.conversations[`${data.key}`].messages[data.messageNumber] = { ...data };
      } else {
        draft.conversations[`${data.key}`] = {
          messages: [...data.conversation.messages, {
            ...data,
          }],
        };
      }
    },
    [poll.start]: draft => {
      draft.polling = true;
    },
    [poll.stop]: draft => {
      draft.polling = false;
    },
    [images.toggle]: (draft, { item }) => {
      draft.selectedPhotos = draft.selectedPhotos.includes(item)
        ? draft.selectedPhotos.filter(i => i !== item)
        : [...draft.selectedPhotos, item];
    },
    [images.add]: (draft, { item }) => {
      draft.selectedPhotos = [...draft.selectedPhotos, item];
    },
    [images.remove]: (draft, { item }) => {
      draft.selectedPhotos = draft.selectedPhotos.filter(i => i !== item);
    },
    [images.clear]: draft => {
      draft.selectedPhotos = [];
    },
    [sendImage.success]: (draft, url) => {
      draft.imageURL = url;
    },
    [generateLink.trigger]: (draft) => {
      draft.contentLinkLoading = true;
    },
    [generateLink.success]: (draft, data) => {
      draft.contentLinkLoading = false;
      draft.contentLink = data;
    },
    [combineActions(
      sendMessage.trigger,
      sendLocalMessage,
    )]: (draft, { message, userId, msgType = '10', createdAt = new Date() }) => {
      draft.sending = true;
      const init = draft.conversationMeta.initialUserId;
      const part = draft.conversationMeta.participantUserId;
      const key = `${init}/${part}`;
      const activeUser = userId === init ? part : init;
      const msg = {
        createdAt,
        userId: activeUser,
        msgType,
        local: true,
        message: { message },
      };

      if (draft.conversations[key]?.messages) {
        draft.conversations[key] = {
          messages: [...draft.conversations[key].messages, msg],
        };
      } else {
        draft.sending = true;
      }
    },
    [sendMessage.failure]: (draft, { error }) => {
      console.log(error);
      draft.error = error;
    },
    // [sendMessage.success]: (draft, { conversation }) => {
    //   console.log(conversation);
    //   draft.lastMessage = conversation.lastMessage;
    //   draft.priorityConvo = conversation.priority;
    //   draft.priorityMessagesHistory = conversation.priorityMessagesHistory;
    //   draft.sending = false;
    //   draft.localMessage = false;
    //   draft.hasPreviousMessages = true;
    //   // update the conversation in the inbox with latest data
    //   const { initialUserId, participantUserId } = conversation;
    //   const index = _.findIndex(draft.list, { initialUserId, participantUserId });
    //   if (index === -1) {
    //     draft.list.push(conversation);
    //   } else {
    //     draft.list[index] = conversation;
    //   }
    // },
    [combineActions(
      loadOne.success,
      setUnreadStatus.success,
      sendMessage.success,
    )]: (draft, { conversation }) => {
      const { initialUserId, participantUserId } = conversation;
      draft.conversationMeta.initialUserId = initialUserId;
      draft.conversationMeta.participantUserId = participantUserId;
      draft.lastMessage = conversation.lastMessage;
      draft.priorityConvo = conversation.priority;
      draft.priorityMessagesHistory = conversation.priorityMessagesHistory;
      draft.conversations[`${initialUserId}/${participantUserId}`] = conversation;
      draft.conversations.loading = false;
      draft.sending = false;
      draft.localMessage = false;
      draft.hasPreviousMessages = true;

      // update the conversation in the inbox with latest data
      const index = _.findIndex(draft.list, { initialUserId, participantUserId });
      if (index === -1) {
        draft.list.push(conversation);
      } else {
        draft.list[index] = conversation;
      }
    },
    [loadOne.failure]: draft => {
      draft.priorityConvo = null;
    },
    [clear]: () => initialState,
    [loadOne.failure]: draft => {
      draft.priorityConvo = null;
    },
    [newMessageToggle]: (draft, status) => {
      draft.newMessageVisible = status
    }
  },
  initialState,
  { loadList },
);

export default Reducer;
