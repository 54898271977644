import {useState, useEffect} from 'react'
import { Route, Switch, Redirect, Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Block, Text, Avatar, Button, getStyle, TextInput, Notice, Icon } from 'ui'
import { useSelector, useDispatch } from 'react-redux';
import Selectors from 'selectors';
import Actions from 'actions';
import styled from 'styled-components'
import { Formik } from 'formik'
import { EditProfileValidation, ChangePasswordValidation } from 'utils/validation'
import { Label } from 'components/common/ui/TextInput'
import { isBrowser, BrowserView, MobileView } from "react-device-detect";


function Edit(){
  const dispatch = useDispatch()
  const { name = '', username = '', bio = '', photo = '' } = useSelector(Selectors.profile.myProfile)
  
  useEffect(() => {
    dispatch(Actions.profile.myProfile.load.trigger())
  }, [dispatch])
  const [messageVisible, showMessage] = useState(false)
  const uploadPhoto = e => {
    // dispatch(
    //   Actions.profile.myProfile.updateImage({
    //     profileImage: image,
    //   }),
    // ); 
    dispatch(Actions.profile.myProfile.uploadPhoto.trigger({ 
      file: e.target.files[0],
      onSuccess: () => {
        //
      }
    }));
  }
  return (
    <Block p={{
      mobile: 5,
      desktop:10
    }}>
      <Text variant="h4" mb={10}>Edit Profile</Text>
      {messageVisible && <Notice text="Profile Updated." visible mb={4} type="success" />}
      <Formik
        initialValues={{
          name,
          username,
          bio,
          photo
        }}
        enableReinitialize
        validationSchema={EditProfileValidation}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          showMessage(false)
          dispatch(
            Actions.profile.myProfile.update.trigger({
              ...values,
              // photo,
              // photoMeta,
              callback: () => {
                // show message
                showMessage(true)
              },
            }),
          );
        }}
      >
        {(props) => {
            const { errors: clientError, handleBlur, touched, values, handleChange, handleSubmit } = props
            return (
              <Block pr={{
                mobile: 0,
                desktop:10
              }}>
                <Block pb={3}>
                  <Label inline style={{alignItems: 'center'}}>
                    <Block as="aside" pr={{
                      mobile:0,
                      desktop:6
                    }} 
                    style={{marginTop:0}}
                    pb={{
                      mobile:1,
                      desktop:0
                    }}>
                      <label htmlFor="photo">
                        <Avatar size={80} photo={photo} />
                      </label>
                    </Block>
                    <Block as="label" htmlFor="photo" flex={1} row alignItems="center">
                      <Text variant="subhead1" color="navy">
                        {photo ? "Change Photo" : "Upload Photo"}
                      </Text>
                      <input 
                        style={{display: 'none'}}
                        onChange={uploadPhoto} 
                        type="file" 
                        name="photo" 
                        id="photo" 
                        accept="image/png, image/jpeg, .heic"
                      />
                    </Block>
                  </Label>
                </Block>

                <Block pb={3}>
                  <TextInput 
                    name="name" 
                    label="Name"
                    id="name"
                    value={values.name} 
                    inline
                    placeholder="Tell us your name"
                  />
                </Block>

                <Block pb={3}>
                  <TextInput 
                    name="username" 
                    label="Username" 
                    id="username"
                    value={values.username} 
                    inline
                    placeholder="Username"
                  />
                </Block>

                <Block pb={3}>
                  <TextInput
                    textarea
                    name="bio" 
                    label="About You" 
                    id="bio"
                    value={values.bio} 
                    inline
                    placeholder="Tell us about yourself"
                  />
                </Block>
                <Block display="flex">
                  <Block display={{
                    mobile: 'none',
                    desktop:'flex'
                  }} flex={{
                    mobile:1,
                    desktop:'0 0 135px'
                  }} />
                  <Button
                    block
                    size="medium"
                    onClick={handleSubmit}
                    text="Save"
                    type="submit"
                  />
                </Block>
              </Block>
            )
          }
        }
      </Formik>
    </Block>
  )
}

function Cards(){
  return (
    <div><i>Coming Soon</i></div>
  )
}

function ChangePassword(){
  const dispatch = useDispatch()
  const email = useSelector(Selectors.auth.email)
  const [messageVisible, showMessage] = useState(false)

  return (
    <>
      <Helmet>
        <title>Change Password - Narrate</title>
        <meta
          property="og:title"
          content="Change Password - Narrate"
        />
        <meta
          property="description"
          content="Change Password - Narrate"
        />
        <meta
          property="og:description"
          content="Change Password - Narrate"
        />
      </Helmet>
      <Block p={{
        mobile: 5,
        desktop:10
      }}>
        <Text variant="h4" mb={10}>Change Password</Text>
        {messageVisible && <Notice text="Password Updated." visible mb={4} type="success" />}
        <Formik
          initialValues={{
            previousPassword: '',
            newPassword: '',
            confirmPassword: '',
          }}
          validationSchema={ChangePasswordValidation}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            showMessage(false)
            dispatch(Actions.auth.changePassword.trigger({ 
              ...values, 
              email, 
              onSuccess: () => {
                showMessage(true)
              }
            }));
          }}
        >
          {(props) => {
              const { errors: clientError, handleBlur, touched, values, handleChange, handleSubmit } = props
              return (
                <Block pr={{
                  mobile: 0,
                  desktop:10
                }}>
                  <Block pb={3}>
                    <TextInput 
                      name="previousPassword" 
                      label="Current Password"
                      id="previousPassword"
                      value={values.previousPassword} 
                      inline
                      type="password"
                      placeholder="Current Password"
                    />
                  </Block>

                  <Block pb={3}>
                    <TextInput 
                      name="newPassword" 
                      label="New Password" 
                      id="newPassword"
                      value={values.newPassword} 
                      inline
                      type="password"
                      placeholder="New Password"
                    />
                  </Block>

                  <Block pb={3}>
                    <TextInput 
                      name="confirmPassword" 
                      label="Confirm Password" 
                      id="confirmPassword"
                      value={values.confirmPassword} 
                      inline
                      type="password"
                      placeholder="Confirm Password"
                    />
                  </Block>
                  <Block display="flex">
                    <Block display={{
                      mobile: 'none',
                      desktop:'flex'
                    }} flex={{
                      mobile:1,
                      desktop:'0 0 135px'
                    }} />
                    <Button
                      block
                      size="medium"
                      onClick={handleSubmit}
                      text="Change Password"
                      type="submit"
                    />
                  </Block>
                </Block>
              )
            }
          }
        </Formik>
      </Block>
    </>
  )
}

const Nav = styled.dl`
  list-style: none;
  padding:0;
  margin:0;
  flex:0;
  display:flex;
  flex-direction:column;
  dd {
    margin:0;
    //border-bottom:1px solid ${getStyle('colors.greyLighter')};
  }
  dt {
    padding:5px 20px;
    background-color: ${getStyle('colors.greyLightest')};
    border-bottom:1px solid ${getStyle('colors.greyLighter')};
    margin-bottom: 3px;
    & ~ dt {
      margin-top: 10px;
    }
    &:first-of-type {
      display:none;
    }
  }
  dd > a, dd > span {
    margin:0;
    padding: 10px 20px;
    display:flex;
    align-items:center;
    cursor: pointer;
    text-decoration:none;
    color: ${getStyle('colors.black')};
    
    &:hover {
      background-color: ${getStyle('colors.greyLightest')};
    }
    @media (min-width: 320px) and (max-width : 736px) {
      font-size: 16px;
      flex-direction: row;
    }
  }

  @media (min-width: 320px) and (max-width : 736px) {
    flex-direction: row;
    flex:1;
    padding: 20px 15px;
    border-bottom:1px solid ${getStyle('colors.greyLighter')};
    background-color: #fff;

    dt {
      display:none;
      &:first-of-type {
        display:block;
        background:none;
        border:none;
        padding:0 10px 0 0;
        margin:2px 0 0 0;
      }
    }
    dd {
      display:flex;
      align-items:center;
      &:last-of-type {
        display:none;
      }
    }
    dd > a {
      font-size:13px;
      padding:0 14px 0 0;
      justify-content:flex-start;
    }
  }

`

function AccountIndex() {
  return (
    <Nav>
      <dt><Icon name="settings" size={24} /></dt>
      <dt><Text variant="subhead3">Account</Text></dt>
      <dd>
        <Link to="/account/edit">Edit Profile</Link>
      </dd>
      <dd>
        <Link to="/account/change-password">Change Password</Link>
      </dd>
      {/* <dd>
        <Link to="/account/cards">My Cards</Link>
      </dd> */}
      <dt><Text variant="subhead3">Support</Text></dt>
      <dd>
        <Link to="/terms">Terms &amp; Policies</Link>
      </dd>
      <dd>
        <a href="mailto:support@narrateapp.com">Contact Us</a>
      </dd>
    </Nav>
  )
}

function Account(){
  const { photo, username, name } = useSelector(Selectors.profile.myProfile)
  const loggedIn = useSelector(Selectors.auth.loggedIn)

  return (
    <>
      <Helmet>
        <title>Edit Profile - Narrate</title>
        <meta
          property="og:title"
          content="Edit Profile - Narrate"
        />
        <meta
          property="description"
          content="Edit Profile - Narrate"
        />
        <meta
          property="og:description"
          content="Edit Profile - Narrate"
        />
      </Helmet>

      <MobileView>
        <AccountIndex /> 
      </MobileView>

      <Block as="article" maxWidth="1200px">
        <Block
          as="section"
          display="grid"
          borderRadius={6}
          border={1}
          borderColor="greyLighter"
          bg="white"
          gridTemplateColumns={{
            mobile: '1fr',
            table: '200px 1fr',
            desktop: '200px 1fr'
          }}
          gridGap={0}
        >
          <BrowserView>
            <Block py={5}>
              <AccountIndex /> 
            </Block>
          </BrowserView>         
          <Block as="article" p={0} borderLeft={1} borderColor="greyLighter" pb={{
            mobile: 5,
            desktop:0
          }}>
            <Switch>
              <Route exact path="/account" component={AccountIndex} />
              <Route path="/account/edit" component={Edit} />
              <Route path="/account/cards" component={Cards} />
              <Route path="/account/change-password" component={ChangePassword} />
              {isBrowser && <Redirect exact from="/account" to="/account/edit" />}
            </Switch>
          </Block>
        </Block>
      </Block>
    </>
  )
}

export default Account