import React,  {useRef, useState, useCallback, useEffect} from 'react'
import { Block, Text, Avatar, Icon, getStyle, Spinner } from 'ui'
import styled, { css } from 'styled-components'
import { useSelector, useDispatch } from 'react-redux';
import Selectors from 'selectors';
import _ from 'lodash';
import {truncate, formatAmount} from 'utils';
import { useHistory, useRouteMatch } from "react-router-dom";
import Actions from 'actions'
import { isMobile, isBrowser } from "react-device-detect";

export const List = styled.ul`
  list-style:none;
  padding:0;
  margin:0;
  max-height: calc(100vh - 260px);
  overflow-y: auto;
  background:#fff;
  li {
    display:grid;
    grid-template-columns: auto 1fr auto;
    align-items:center;
    padding:20px 30px;
    border-bottom: 1px solid ${getStyle('colors.greyLighter')};
    transition: all 0.4s;
    position:relative;
    cursor:pointer;
    &:last-of-type::not(:first) {
      border-bottom:none;
      border-bottom-left-radius: 6px;
    }
    &:hover {
      background-color: ${getStyle('colors.greyLightest')};
    }
    &:after {
      content: '';
      transition: all 0.4s;
      position:absolute;
      left:0;
      height:100%;
      width:0;
      background-color: ${getStyle('colors.navy')};
    }

    &.active {
      background-color: ${getStyle('colors.greyLightest')};
      &:after {
        width:5px;
      }
    }

    &.unread {
      &:after {
        width:5px;
        background-color: ${getStyle('colors.brand')};
      }
    }

  }

  @media (min-width: 320px) and (max-width : 736px)
  {

    max-height:100%;
    margin: ${props => props.search ? `0` : `60px 0 0 0`};
    padding-bottom: ${props => (props.search || props.messages) ? `59px` : `0`};
    min-height:auto;
    overflow-y:visible;
    ${props => props.recent && css`
      margin:0;
      padding:0;
    `}
  }

`

const Dot = styled.span`
  background-color:${getStyle('colors.brand')};
  width:6px;
  height:6px;
  border-radius:100%;
  display:inline-block;
`

const ListItem = ({user: userData}) => {
  const profiles = useSelector(Selectors.profile.usersIndex)
  const { push } = useHistory()
  const getProfile = ({ otherUserId }) => ({
    ..._.get(profiles, otherUserId)
  });
  const user = getProfile(userData)
  const {lastMessage: { message, msgType }, priority, otherUserId, hasUnreadMessages } = userData
  const url = `/conversations/${otherUserId}`

  const match = useRouteMatch({
    path: url,
    exact: true
  });

  const unread = hasUnreadMessages


  const Boosted = () => {
    if (priority) {
      const  { amount, transaction } = priority
      return (
        <Block bg={!unread ? 'brandLightest' : 'brand'} px={2} py={1} borderRadius={4}>
          <Text color="white" variant="subhead2">{formatAmount(amount || transaction?.amount)}</Text>
        </Block>
      )
    }

    if(unread) {
      return <Dot/>
    }

    return null
  }

  return (
    <li 
      onClick={() => {
        push(`/conversations/${otherUserId}`)
      }}
      className={`${match ? 'active' : ''} ${unread && 'unread'}`}
    >
      <Avatar size={70} photo={user?.photo} iconColor="greyLight" />
      <Block flex={1} col>
        <Text as="h4" variant="subhead2" ml={3} mb={1} color="navy">{`@${user?.username}`}</Text>
        <Text ml={3}>{truncate(message)}</Text>
      </Block>
      <Boosted/>
      
    </li>
  )



}


const MessageList = ({messages, loading = true}) => {
  const scrollRef = useRef()
  const [scrollDiv, setScrollDiv] = useState(null)
  const dispatch = useDispatch()
  const loadingMore = useSelector(Selectors.conversations.loadMoreLoading)

  const handleScroll = useCallback(e => {
    const scrollHeight = e.target.scrollHeight
    const elementHeight = e.target.clientHeight
    const scrollTop = e.target.scrollTop
    if(isBrowser && !loadingMore && (scrollHeight - scrollTop) === elementHeight) {
      dispatch(Actions.conversations.loadMore.trigger())
    }
  }, [dispatch, loadingMore])

  const handleScrollMobile = useCallback(e => {
    if (isMobile && !loadingMore && (window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
      if(loadingMore) {
        return;
      }
      dispatch(Actions.conversations.loadMore.trigger())
    }
  }, [loadingMore, dispatch])


  useEffect( () => {
    if(isMobile) {
      window.addEventListener('scroll', handleScrollMobile)
    }
    return () => {
      if(isMobile) {
        window.removeEventListener('scroll', handleScrollMobile)
      }
    }
  }, [handleScrollMobile])

  useEffect( () => {
    setScrollDiv(scrollRef?.current)
    scrollDiv?.addEventListener('scroll', handleScroll)
    return () => {
      scrollDiv?.removeEventListener('scroll', handleScroll)
    }
  }, [scrollDiv, loading, handleScroll])

  if(loading) {
    return (
      <Block col justifyContent="center" alignCenter="center" textAlign="center">
        <Spinner size={40} />
        <Text color="grey">loading...</Text>
      </Block>
    )
  }

  return(
    <List ref={scrollRef} messages>
      {messages.map((user, idx) => (
        <ListItem user={user} key={idx} />
      ))}
    </List>
  )
}

export default MessageList