import { createSelector } from 'reselect';
import Actions from 'actions';
// import Screens from 'constants/screens';
import AuthSelectors from 'modules/auth/selectors';

const Screens = {}
const shareProfile = async ({ shareURL }) => {
  //share profile
};

const Selectors = {
  loading: state => state.settings.sendReport.loading,
  reportData: state => state.settings.reportData,
  sendReport: state => state.settings.sendReport,
  content: createSelector(
    AuthSelectors.loggedIn,
    loggedIn => {
      const dev = [
        {
          title: 'Account',
          data: [
            {
              name: 'Earnings',
              path: Screens.EARNINGS,
            },
            {
              name: 'Share Profile',
              action: ({ user: { username, photo, shareURL } }) => {
                shareProfile({ username, photo, shareURL });
              },
            },
            {
              name: 'Change Password',
              path: Screens.CHANGE_PASSWORD,
            },
            {
              name: ({ email }) => `Sign Out of ${email}`,
              action: ({ email, dispatch, navigation }) => {
                //Alert action
              },
            },
          ],
        },
        {
          title: 'Support',
          data: [
            {
              name: 'Terms & Policies',
              path: Screens.TERMS_CONDITIONS,
            },
            {
              name: 'Report Issue',
              path: Screens.REPORT_ISSUE,
            },
          ],
        },
        {
          title: 'Debugging',
          data: [
            {
              name: 'Change Environment',
              params: { name: Screens.CHG_ENV },
            },
          ],
        },
      ];

      const prod = [
        {
          title: 'Account',
          data: [
            {
              name: 'Earnings',
              path: Screens.EARNINGS,
            },
            {
              name: 'Share Profile',
              action: ({ user: { username, photo, shareURL } }) => {
                shareProfile({ username, photo, shareURL });
              },
            },
            {
              name: 'Change Password',
              path: Screens.CHANGE_PASSWORD,
            },
            {
              name: ({ email }) => `Sign Out of ${email}`,
              action: ({ email, dispatch, navigation }) => {
                //Sign out action
              },
            },
          ],
        },
        {
          title: 'Support',
          data: [
            {
              name: 'Terms & Policies',
              path: Screens.TERMS_CONDITIONS,
            },
            {
              name: 'Report Issue',
              path: Screens.REPORT_ISSUE,
            },
          ],
        },
      ];

      const whiteList = ['dev', 'qa', 'staging'];

      return whiteList.includes(process.env.ENV) ? dev : prod;
    },
  ),
  environment: createSelector(
    state => state.settings.environment,
    environment => {
      console.log('environment selector = ', environment);
      return environment;
    },
  ),
};

export default Selectors;
