import { Helmet } from "react-helmet";
import React, { useState, useEffect } from 'react'
import Header from 'components/common/Header'
import ContentItem from './item'
import { Block, Text } from 'ui'
import { getPhotoSet } from 'modules/api'
import { useParams } from "react-router-dom";
import RefreshLink from './refreshLink'

export async function getContent({ mediaSetId, sk }) {
  const {errors, mediaSetItem} = await getPhotoSet({
    mediaSetId,
    sk
  })
  
  return {
    mediaSetItem,
    errors
  }
}


function Gallery() {

  const isMobile = navigator.userAgent.match(/iPhone|iPad|iPod|Android/i)
  const inapp = navigator.userAgent.match(/NarrateApp/i)
  const { sk, id: mediaSetId } = useParams()
  const [photosetItem, setContent] = useState('')
  const [errors, setErrors] = useState('')

  useEffect(() => {
    async function load() {
      const { mediaSetItem, errors  } = await getContent({
        mediaSetId,
        sk
      })

      setContent(mediaSetItem)
      setErrors(errors)
    }
    load()
  }, [mediaSetId, sk])

  

  return (
    <>
      <Helmet>
        <title>{photosetItem?.title || 'Not Found'}</title>
        <meta property="og:title" content={photosetItem?.title || 'Not Found'} />
        <meta property="og:type" content="gallery" />
      </Helmet>
      {!inapp && (
        <Header
          smallLogo
          hideNotice
          flex={1}
          row
          noclick
          download={() => false}
          position={{
            mobile: 'static',
            desktop: 'static'
          }}
          top={10}
          left={{
            mobile: 0,
            desktop: 10
          }}
          p={{
            mobile: 5,
            desktop: 3
          }}
          justifyContent={{
            mobile: 'flex-start',
            desktop: 'flex-start'
          }}
          display={{
            mobile: 'flex',
            desktop: 'flex'
          }}
          right={0}
          alignItems="center"
        />
      )}

      {errors?.Error === 'Not found' && (<RefreshLink errors={errors} mediaSetId={mediaSetId} />)}
      
      {!inapp && (
        <Block maxWidth={1200} margin="0 auto" p={3}>
          <Text
            color="navy"
            variant="h5"
            fontSize={{
              desktop: 20
            }}
            height="100%"
            maxWidth={200}
          >
            {photosetItem?.title || ''}
          </Text>
        </Block>
      )}

      <Block
        as="section"
        display="grid"
        gridTemplateColumns={{
          mobile: '1fr 1fr',
          desktop: 'repeat(auto-fill, minmax(200px, 1fr))'
        }}
        gridGap={3}
        p={3}
        gridTemplateRows="1fr"
        maxWidth={1200}
        margin="0 auto"
        pt={inapp ? 3 : 0}
      >
        {photosetItem?.media
          && photosetItem?.media.map((item) => (
            <ContentItem
              key={item.itemId}
              item={item}
              inapp={inapp}
              mediaSetId={mediaSetId}
              isMobile={isMobile}
              sk={sk}
            />
          ))}
      </Block>
      {photosetItem?.media?.length > 0 && (
        <Block flex={1} textAlign="center" py={4} pb={7}>
          <Text
            variant="h6"
          >
            {photosetItem?.media?.length}
            {' '}
            Images
          </Text>
        </Block>
      )}

      {photosetItem?.media?.length === 0 && (
        <Block
          flex={1}
          alignItems="center"
          display="flex"
          justifyContent="center"
          minHeight="calc(100vh - 200px)"
          p={5}
        >
          <Block
            flex={1}
            textAlign="center"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            maxWidth={350}
          >
            <Text variant="h2" pb={3} color="navy">No Content Added.</Text>
            <Text variant="subhead1" pb={4} color="greyDark">It appears no content has been added.</Text>
          </Block>
        </Block>
      )}

    </>
  )
}


export async function getServerSideProps({ params, req }) {
  const isMobile = (req
    ? req.headers['user-agent']
    : navigator.userAgent).match(/iPhone|iPad|iPod|Android/i)

  const content = await getPhotoSet({
    mediaSetId: params.id,
    sk: params.sk
  })


  return {
    props: {
      content,
      isMobile,
      sk: params.sk,
      mediaSetId: params.id,
      inapp: req.headers['user-agent'].match(/NarrateApp/i)
    }
  }
}

export default Gallery
