import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { Reducer as tokens } from '@narrate/base-sdk/dist/redux';
import Auth from 'modules/auth';
import Profile from 'modules/profile';
import Posts from 'modules/posts';
import Settings from 'modules/settings';
import Home from 'modules/home';
import Conversations from 'modules/conversations';
import Search from 'modules/search';
import Payment from 'modules/payment';

import { persistStoreMigrate as migrate, version } from './migrations';

const __DEV__ = process.env.NODE_ENV === 'development'

const reducers = {
  auth: Auth.Reducer,
  tokens,
  profile: Profile.Reducer,
  posts: Posts.Reducer,
  settings: Settings.Reducer,
  home: Home.Reducer,
  conversations: Conversations.Reducer,
  search: Search.Reducer,
  payment: Payment.Reducer,
};

export default persistReducer(
  {
    migrate,
    storage,
    version,
    key: __DEV__ ? `narrate-debug-${process.env.REACT_ENV}` : `narrate-${process.env.REACT_ENV}`,
    whitelist: ['auth', 'tokens', 'profile'],
  },
  combineReducers(reducers),
);
