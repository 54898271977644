import React, { useState, useEffect } from 'react'
import { Block, Icon, Text, Avatar, getStyle, Badge } from 'ui'
import { useSelector, useDispatch } from 'react-redux';
import { Link, useRouteMatch, useHistory } from "react-router-dom";
import Selectors from 'selectors'
import Logo from './logo'
import Overlay from '../Overlay'
import styled, { css } from 'styled-components'
import Actions from 'actions'
import { push } from 'react-router-redux';
import { isMobile, BrowserView, MobileView, isBrowser } from "react-device-detect";

function LoggedInHeader() {

  const dispatch = useDispatch()
  const { push } = useHistory()
  const { photo } = useSelector(Selectors.profile.myProfile)
  const unreadCount = useSelector(Selectors.conversations.unreadCount)
  const isOpen = useSelector(Selectors.profile.isOpen)
  const isLoggedIn = useSelector(Selectors.auth.loggedIn)
  const showSidebar = () => {
    dispatch(Actions.profile.toggleSidebar(true))
  }

  const convoActive = useRouteMatch({
    path: '/conversations',
    exact: false
  });

  return (
    <>
      <Block 
        bg={isMobile ? "white" : "navy"}
        position={isMobile ? "fixed" : "relative"}
        bottom={isMobile ? 0 : null}
        left={isMobile ? 0 : null}
        right={isMobile ? 0 : null}
        borderTopWidth={1}
        borderTopStyle="solid"
        borderColor="greyLighter"
        zIndex={999}
        py={3}


      >
        <Block as="header" maxWidth="1200px" margin="0 auto" padding="0 20px" row justifyContent="space-between" alignItems="center">
          <BrowserView>
            <Block flex={1}>
              <Link to="/">
                <Logo/> 
              </Link>
            </Block>
          </BrowserView>
          <Block row flex={1} justifyContent={isMobile ? "space-between" : "flex-end"} alignItems="center">
            {isLoggedIn ? (
              <Badge count={unreadCount}>
                <Icon name={convoActive ? 'chat-convo-fill' : 'chat-convo'} color={convoActive ? 'brand' : (isMobile ? 'grey' :'white')} size={24} onClick={() => push('/conversations')} />
              </Badge>
            ) : (
              <MobileView>
                <Icon name="plane" color="grey" size={28} onClick={() => {
                  dispatch(Actions.profile.toggleRegister(true))
                  dispatch(Actions.auth.showModal({visible: true}))
                }} />
              </MobileView>
            )}
            <Avatar onClick={showSidebar} photo={photo} size={29} ml={isMobile ? 0 : 3} iconBg={isMobile ? "white" : "navy"} active={isOpen} />
          </Block>
        </Block>
      </Block>
      <SideBar/>
    </>
  )
}

const Bar = styled(Block)`
  position:fixed;
  top:0;
  right:0;
  min-height:100vh;
  width:310px;
  z-index:9999;
  transform: translateX(100%);
  transition: all 0.4s cubic-bezier(0.45, 0, 0.55, 1);
  box-shadow: 0px 0 0px rgba(0,0,0,0.2);
  ${(props) => props.isOpen
    && css`
      transform: translateX(0);
      box-shadow: -2px 0 10px rgba(0,0,0,0.2);
    `
  }
`

const Nav = styled.ul`
  list-style: none;
  padding:0;
  margin:0;
  flex:0;
  display:flex;
  flex-direction:column;

  ${props => props.footer && css`
    @media (min-width: 320px) and (max-width : 736px) {
      display:none;
    }
  `}

  ${props => props.loggedIn && css`
    li {
      &:last-of-type {
        @media (min-width: 320px) and (max-width : 736px) {
          margin-top:20px;
          border-top:1px solid ${getStyle('colors.greyLighter')};
        }
      }
    }    
  `}


  li > a, li > span {
    margin:0;
    padding: 10px 20px;
    display:flex;
    align-items:center;
    cursor: pointer;
    text-decoration:none;
    color: ${getStyle('colors.navy')};
    &:hover {
      background-color: ${getStyle('colors.greyLightest')};
    }
  }
`

const NavLink = ({label, to, icon, onClick}) => {

  const Li = React.forwardRef((props, ref) => (
    <li>
      <a ref={ref} {...props}>
        <Icon name={icon} size={30} />
        <Text ml={3}>{label}</Text>
      </a>
    </li>
  ))

  if(onClick) {
    return (
      <li onClick={onClick}>
        <span>
          <Icon name={icon} size={30} />
          <Text ml={3}>{label}</Text>
        </span>
      </li>
    )
  }
  
  return (
    <Link to={to} component={Li} />
  )
}

function SideBar() {
  const dispatch = useDispatch()
  const isOpen = useSelector(Selectors.profile.isOpen)
  const isLoggedIn = useSelector(Selectors.auth.loggedIn)
  const { photo, username, name } = useSelector(Selectors.profile.myProfile)
  const history = useHistory()

  useEffect(() => {
    dispatch(Actions.profile.toggleSidebar(false))
  }, [history, dispatch])

  const menu = isLoggedIn ? [
    {
      title: 'My Profile',
      icon: 'user',
      url: `/${username}`
    },
    {
      title: 'Settings',
      icon: 'settings',
      url: '/account/edit'
    },
    // {
    //   title: 'My Cards',
    //   icon: 'wallet',
    //   url: '/account/cards'
    // },
    {
      title: 'Terms & Policies',
      icon: 'alert',
      url: '/terms'
    },
    ...(isMobile ? [{
      title: 'Sign Out',
      icon: 'sign-out',
      onClick: () => {
        dispatch(Actions.auth.logout.trigger({
          callback: () => {
            window.location.href = '/login'
          }
        }));
        return false;
      }
    }] : [])
  ]:[
    {
      title: 'Sign Up',
      icon: 'user',
      onClick: () => {
        dispatch(Actions.profile.toggleRegister(true))
        dispatch(Actions.auth.showModal({visible: true}))
        dispatch(Actions.profile.toggleSidebar(false))
      }
    },
    {
      title: 'Login',
      icon: 'settings',
      onClick: () => {
        dispatch(Actions.profile.toggleRegister(false))
        dispatch(Actions.auth.showModal({visible: true}))
        dispatch(Actions.profile.toggleSidebar(false))
      }
    },
    {
      title: 'Terms & Policies',
      icon: 'alert',
      url: '/terms'
    }
  ]
  const hideSideBar = () => {
    dispatch(Actions.profile.toggleSidebar(false))
  }
  return(
    <>
      <Bar
        bg="white"
        textAlign="center"
        isOpen={isOpen}
        col
      >
        {isLoggedIn ? (
          <Block
            flex={0}
            p={4}
            bg="white"
            mb={4}
            borderBottom={1}
            borderBottomColor="greyLighter"
            justifyContent="center"
          >
            <Block
              row
            >
              <Avatar
                size={50}
                mr={3}
                photo={photo}
              />
              <Block flex={1} col justifyContent="center">
                <Block row>
                <Text
                  variant="subhead2"
                  mb={0}
                >
                  {name}
                </Text>
                </Block>
                <Block row>
                  <Text
                    mb={0}
                  >
                    {username && `@${username.toLowerCase()}`}
                  </Text>
                </Block>
              </Block>
            </Block>
          </Block>
        ) : (
          <Block 
            p={4}
            flex={0}
            col
            alignItems="center"
            borderBottom={1}
            borderBottomColor="greyLighter"
          >
            <Logo/>
            <Text variant="subhead2" color="navy" mt={1}>connect with creators</Text>
          </Block>
        )}
        <Block flex={1} col justifyContent="space-between">
          <Block flex={1} col>
            <Nav loggedIn={isLoggedIn}>
              {menu.map((item, i) => (
                <NavLink key={i} to={item.url} icon={item.icon} label={item.title} onClick={item.onClick} />
              ))}
            </Nav>
          </Block>
          {isLoggedIn && isBrowser && (
            <Block
              flex={0}
              col
              borderTop={1}
              borderTopColor="greyLighter"
              justifyContent="center"
            >
              <Nav footer>
                <li>
                  <span onClick={() => {
                    dispatch(Actions.auth.logout.trigger({
                      callback: () => {
                        window.location.href = '/login'
                      }
                    }));
                    return false;
                  }}>
                    <Icon name="sign-out" size={30} />
                    <Text ml={3}>Sign Out</Text>
                  </span>
                </li>
              </Nav>
            </Block>
          )}
        </Block>
      </Bar>
      <Overlay visible={isOpen} onClick={hideSideBar} />
    </>
  )
}

export default LoggedInHeader