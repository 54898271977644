import {useState, useEffect} from 'react'
import { Block, Text, TextInput, Button, Spinner, getStyle, Icon } from 'ui'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import Actions from 'actions'
import Selectors from 'selectors'

const Option = styled.div`
  padding: 8px 24px;
  margin:0;
  display:grid;
  align-items:center;
  grid-template-columns: 1fr auto auto;
  
`

const List = styled.ul`
  position: absolute;
  bottom:0;
  left:-2px;
  right:0px;
  margin:0;
  padding:0;
  width:101%;
  grid-template-rows: 1fr;
  transform:translateY(100%);
  z-index:99;
  background:#fff;
  list-style:none;
  max-height:300px;
  overflow-y: auto;
  border-left:2px solid ${getStyle('colors.grey')};
  border-right:2px solid ${getStyle('colors.grey')};
  border-bottom:2px solid ${getStyle('colors.grey')};
  border-top:1px solid ${getStyle('colors.grey')};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  li {
    padding: 8px 24px;
    display:grid;
    align-items:center;
    grid-template-columns: 1fr auto auto;
    border-top:1px solid ${getStyle('colors.greyLightest')};
    &:last-of-type {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    &:hover {
      background-color: ${getStyle('colors.greyLightest')};
    }
  }

  @media (min-width: 320px) and (max-width : 736px) {
    width:101.1%;
  }

`

const Select = styled.div`
  border-radius: 40px;
  border:2px solid ${getStyle('colors.grey')};
  margin:0;
  padding:0;
  cursor:pointer;
  position:relative;
  ${props => props.isOpen && css`
    border-radius:0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  `}

  ${props => props.error && css`
    border-color: ${getStyle('colors.danger')};
  `}
`

const PaymentSelect = ({lastPayment = {}, userId, itemId, error}) => {
  const { id, cardType, lastFour } = lastPayment
  const [isOpen, setToggle] = useState(false)
  const dispatch = useDispatch()
  const paymentMethods = useSelector(Selectors.payment.paymentMethods)
  const clearIntent = () => {
    dispatch(Actions.payment.clearIntent())
  }

  const selectCard = card => {
    // update paymentOptions paymentIntent
    dispatch(Actions.payment.paymentChoice(card))
    setToggle(false)
  }

  useEffect(()=> {
    dispatch(Actions.payment.paymentMethods.trigger())
  }, [dispatch])


  return (
    <Select isOpen={isOpen} error={error}>
      <Option id={id} onClick={()=> setToggle(!isOpen)}>
        <Text display={{
          mobile: 'none',
          desktop: 'block'
        }} variant="subhead1">payment type</Text>
        <Block row alignItems="center" mr={2}>
          <img width={40} src={`/images/cards/${cardType}.png`} alt=""/>
          <Text color={error ? 'danger' : 'black'} ml={2} variant="subhead1" textTransform="capitalize">{cardType} {lastFour}</Text>
        </Block>
        <Icon color="greyDark" name={isOpen ? "chevron-down" : "chevron-right"} size={20} />
      </Option>
      {isOpen && (
        <List>
          <Option as="li" onClick={() => clearIntent()}>
            <Text color="navy">Use a different Card</Text>
            <Icon color="navy" name="chevron-right" size={18} />
          </Option>
          {paymentMethods.map(card => (
            <Block key={card.id} as="li" row alignItems="center" onClick={()=> selectCard(card)}>
              <img width={40} src={`/images/cards/${card.cardType}.png`} alt=""/>
              <Text ml={2} variant="subhead1" textTransform="capitalize">{card.cardType} {card.lastFour}</Text>
            </Block>
          ))}
        </List>
      )}
    </Select>
  )
}

export default PaymentSelect