import { takeEvery, select, put, all, call } from 'redux-saga/effects';
import _ from 'lodash';
import Narrate from '@narrate/app-sdk';
import { routineSaga, sendTo } from 'utils/sagas';
import Actions from 'actions';
import Selectors from 'selectors';
import ProfileSelector from './selectors';
import { authSuccessActions } from '../auth/actions';

const Platform = {}

class ProfileSaga {
  update = routineSaga({
    routine: Actions.profile.myProfile.update,
    *request({ payload: { name, bio, username, photo, photoMeta, callback } }) {
      const { data } = yield Narrate.api.profile.save({name, bio, username, photo, photoMeta });
      const { userId, ...rest } = yield select(Selectors.profile.myProfile);
      if (callback) {
        callback();
      }
      return { ...data, userId, ...rest };
    },
  });

  loadMyProfile = routineSaga({
    routine: Actions.profile.myProfile.load,
    *request() {
      // TODO: remove Item stuff from response
      const { Item: profile } = yield Narrate.api.profile.find();
      return profile;
    },
  });

  loadUser = routineSaga({
    routine: Actions.profile.loadUser,
    *request({ payload: { userId } }) {
      const { Item: profile } = yield Narrate.api.profile.find(userId);
      return profile;
    },
  });

  loadProfile = routineSaga({
    routine: Actions.profile.loadProfile,
    *request({ payload: { username, onSuccess } }) {
      const { userId, bio, name, photo, accessBlocks: { items } } = yield call(Narrate.api.profile.profileView, username)

      return {
        photo,
        bio,
        name,
        userId,
        username,
        blocks: items,
        onSuccess
      }
    },
    *afterSuccess({ onSuccess }) {
      if (onSuccess) {
        yield call(() => onSuccess());
      }
    },
  });

  uploadPhoto = routineSaga({
    routine: Actions.profile.myProfile.uploadPhoto,
    *request({ payload: { onSuccess, file } }) {
      const myProfile = yield select(ProfileSelector.myProfile);

      const photoToUpload = {
        name: 'profile.jpeg',
        type: 'image/jpeg',
        uri: URL.createObjectURL(file)
      };

      console.log(photoToUpload)

      const uploadedPhoto = yield Narrate.api.photo.upload(file, 'profile');

      return { photo: uploadedPhoto, onSuccess };
    },

    *afterSuccess({ photo, onSuccess }) {
      const myProfile = yield select(ProfileSelector.myProfile);
      const payload = {
        ...myProfile,
        photo: photo.renditions.profile_3x,
        photoMeta: photo,
        callback: onSuccess,
      };

      yield put(Actions.profile.myProfile.update.trigger(payload));
    },
  });

  loadEarnings = routineSaga({
    routine: Actions.profile.loadEarnings,
    *request() {
      return yield Narrate.api.transactions.earningsSummary();
    },
  });

  loadEarningsSummary = routineSaga({
    routine: Actions.profile.loadEarningsSummary,
    *request() {
      const { Item } = yield call(() => Narrate.api.transactions.getAccountSummary());
      return Item;
    },
  });

  getProfileLink = routineSaga({
    routine: Actions.profile.getProfileLink,
    *request({ payload: { username } }) {
      return yield call(Narrate.api.profile.getLink, username);
    },
  });
}

const loadInboxUsers = function*() {
  const inbox = yield select(Selectors.conversations.inbox);
  const loadedProfiles = yield select(Selectors.profile.usersIndex);
  const inboxUserIds = _.map(inbox, item => item.otherUserId);
  const userIds = _.filter(inboxUserIds, userId => !_.has(loadedProfiles, userId));

  yield all(userIds.map(userId => put(Actions.profile.loadUser.trigger({ userId }))));
};

const saga = new ProfileSaga();

export default function* combined() {
  yield takeEvery(Actions.profile.myProfile.update.trigger, saga.update);
  yield takeEvery(Actions.profile.myProfile.load.trigger, saga.loadMyProfile);
  yield takeEvery(Actions.profile.myProfile.uploadPhoto.trigger, saga.uploadPhoto);
  yield takeEvery(Actions.profile.loadUser.trigger, saga.loadUser);
  yield takeEvery(Actions.profile.loadProfile.trigger, saga.loadProfile);
  yield takeEvery(Actions.profile.loadEarnings.trigger, saga.loadEarnings);
  yield takeEvery(Actions.profile.loadEarningsSummary.trigger, saga.loadEarningsSummary);
  yield takeEvery([...authSuccessActions], saga.loadMyProfile);
  yield takeEvery(
    [Actions.conversations.loadList.success, Actions.conversations.loadMore.success],
    loadInboxUsers,
  );
  yield takeEvery(Actions.profile.myProfile.update.success, sendTo(Actions.profile.myProfile.load.trigger));
  yield takeEvery(Actions.profile.getProfileLink.trigger, saga.getProfileLink);
}
