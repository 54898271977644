import { createSelector } from 'reselect';
import _ from 'lodash';
import AuthSelectors from 'modules/auth/selectors';

const Selectors = {
  conversations: state => state.conversations.conversations,
  list: state => state.conversations.list,
  loadList: state => state.conversations.loadList,
  inboxLoading: state => state.conversations.loadList.loading,
  hasSelectedPaymentMethod: state => state.conversations.paymentMethodSelected,
  selectedPaymentMethod: state => state.conversations.selectedPaymentMethod,
  pendingPriorityMessage: state => _.get(state, 'conversations.pendingPriorityMessage'),
  disablePurchaseButton: state => state.conversations.disablePurchaseButton,
  selectedLoading: state => state.conversations.selectedLoading,
  boostLoading: state => state.conversations.boostLoading,
  boostTrigger: state => state.conversations.boostTrigger,
  lastMessage: state => state.conversations.lastMessage,
  priorityConvo: state => state.conversations.priorityConvo,
  priorityMessagesHistory: state => state.conversations.priorityMessagesHistory,
  conversationMeta: state => state.conversations.conversationMeta,
  error: state => state.conversations.error,
  sending: state => state.conversations.sending,
  polling: state => state.conversations.polling,
  localMessage: state => state.conversations.localMessage,
  hasPreviousMessages: state => state.conversations.hasPreviousMessages,
  selectedPhotos: state => state.conversations.selectedPhotos,
  imageDrawerStatus: state => state.conversations.imageDrawerStatus,
  imageURL: state => state.conversations.imageURL,
  newMessageVisible: state => state.conversations.newMessageVisible,
  currentConvoUser: state => state.profile.convoUser,
  contentLink: state => state.conversations.contentLink,
  contentLinkLoading: state => state.conversations.contentLinkLoading,
  loadMoreLoading: state => state.conversations.loadMoreLoading
};

Selectors.normalizedList = createSelector(
  Selectors.list,
  AuthSelectors.userId,
  (list, userId) => list.map(conversation => {
    let user;
    let other;

    if (conversation.initialUserId === userId) {
      user = 'initial';
      other = 'participant';
    } else {
      user = 'participant';
      other = 'initial';
    }

    return {
      ...conversation,
      inboxStatus: conversation[`${user}UserInboxStatus`],
      otherUserId: conversation[`${other}UserId`],
    };
  }),
);

Selectors.unreadCount = createSelector(
  Selectors.normalizedList,
  list => list.filter(item => item.hasUnreadMessages === true).length,
);

Selectors.normalizedConversations = createSelector(
  Selectors.conversations,
  AuthSelectors.userId,
  (conversations, userId) => {
    const results = {};

    _.forEach(conversations, (conversation, key) => {
      let user;
      let other;

      if (conversation.initialUserId === userId) {
        user = 'initial';
        other = 'participant';
      } else {
        user = 'participant';
        other = 'initial';
      }

      results[key] = {
        ...conversation,
        inboxStatus: conversation[`${user}UserInboxStatus`],
        otherUserId: conversation[`${other}UserId`],
      };
    });

    return results;
  },
);

Selectors.inbox = createSelector(
  Selectors.normalizedList,
  list => _.reverse(_.sortBy(list, ['inboxStatus'])),
);

Selectors.keyByOtherUserId = createSelector(
  Selectors.normalizedList,
  Selectors.normalizedConversations,
  (list, conversations) => {
    // since starting a new conversation updates the conversations reducer
    // but doesn't refresh the inbox listing, make sure we build this index
    // from both sources
    const allItems = [...list, ...Object.values(conversations)];

    return _.reduce(
      allItems,
      (index, { initialUserId, participantUserId, otherUserId }) => Object.assign(
        index,
        { [otherUserId]: `${initialUserId}/${participantUserId}` },
      ),
      {},
    );
  },
);

Selectors.conversationsbByOtherUserId = createSelector(
  Selectors.inbox,
  Selectors.keyByOtherUserId,
  Selectors.conversations,
  (inbox, keys, conversations) => _.keyBy(inbox, item => _.get(conversations, keys[item.otherUserId])),
);

export default Selectors;
