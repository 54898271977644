import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet";
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useHistory } from "react-router-dom";
import { Block, Text, Avatar, Icon, Button, Spinner } from 'ui'
import { formatAmount } from 'utils'
import { event } from 'utils/ga'
import ItemBlock from 'components/purchase/block'
import Footer from 'components/common/Footer'
import Header from 'components/common/Header'
import CheckoutForm from 'components/purchase/form'
import LoginSignup from 'components/auth'
import { useParams } from "react-router-dom";
import { getAccessBlock } from 'modules/api'
import { connect, useDispatch, useSelector } from 'react-redux';
import Selectors from 'selectors'
import Actions from 'actions'

const stripePromise = loadStripe(process.env.STRIPE_SECRET_KEY)

export async function loadData({username, itemId, userId}) {
  const { Item } = await getAccessBlock({
    itemId,
    userId
  }).catch(err => {
    console.log(err)
  })


  return {
    accessBlock: Item
  }
}

function Checkout({url="", loggedIn}) {
  const dispatch = useDispatch()
  const user = useSelector(Selectors.profile.user)
  const {paymentInfo} = useSelector(Selectors.payment.paymentIntent)
  const [ accessBlock, setAccessBlock ] = useState({
    headline: null,
    amount: null,
    accessImage: null,
    description: null,
    paymentTerms: null
  })
  const { item, username } = useParams()
  const [ userId, itemId ] = item.split('@')


  useEffect(() => {
    dispatch(Actions.profile.loadProfile.trigger({
      username
    }))
  }, [username, dispatch])
  

  useEffect(() => {
    async function load() {
      const { accessBlock } = await loadData({
        username,
        itemId,
        userId
      })


      setAccessBlock(accessBlock)
    }
    load()

  }, [username, itemId, userId])



  dispatch(Actions.profile.toggleRegister(true))

  const router = useHistory()

  const [scrolling, setScrolling] = useState(false)


  if (!accessBlock || !user) {
    return (
      <Block
        flex={1}
        alignItems="center"
        display="flex"
        justifyContent="center"
        minHeight="calc(100vh)"
        p={5}
      > 
        <Spinner/>
      </Block>
    )
  }

  const { photo } = user

  const {
    headline,
    amount,
    accessImage,
    description,
    paymentTerms
  } = accessBlock

  const isSub = () => paymentTerms === 'monthly'


  const goToApp = (small) => {
    const now = new Date().valueOf()
    setTimeout(() => {
      if (new Date().valueOf() - now > 100) return
      window.location = `https://9cwbj-alternate.app.link?$username=${username}&$ios_deepview=false`
    }, 50)
    window.location = `narrate://profile/${username}`
    event({
      category: 'Profile',
      action: 'download_click',
      label: small === 'small' ? 'top_button' : 'center_button'
    })
  }

  const fontPath = `${window.location.origin}${require('assets/fonts/Gilroy-Regular.woff2').default}`

  return (
    <>
      <Helmet>
        <title>{`Secure Checkout | @${username} | Narrate`}</title>
        <meta property="og:title" content={`Secure Checkout | @${username} | Narrate`} />
        <meta property="og:type" content="profile" />
        <meta
          property="og:url"
          content={`${window.location.href}`}
        />
        <meta property="og:image" content={accessImage} />
        <meta property="og:description" content={description} />
        <meta property="description" content={description} />
      </Helmet>
      <Header
        smallLogo
        hideNotice
        float
        flex={1}
        row
        noclick
        download={() => goToApp('small')}
        position={{
          mobile: 'static',
          desktop: 'absolute'
        }}
        top={10}
        left={{
          mobile: 0,
          desktop: 10
        }}
        p={{
          mobile: 5,
          desktop: 0
        }}
        justifyContent={{
          mobile: 'center',
          desktop: 'flex-start'
        }}
        display={{
          mobile: 'none',
          desktop: 'flex'
        }}
        right={0}
      />
      <Block
        pt={{
          mobile: 0,
          desktop: 0
        }}
      >
        <Block
          as="section"
          display="grid"
          id="main"
          gridTemplateColumns={{
            mobile: '1fr',
            table: '1fr 1fr',
            desktop: '1fr 1fr'
          }}
          gridGap={0}
          minHeight={{
            mobile: 'auto',
            desktop: '100vh'
          }}
          alignItems="center"
          justifyContent="center"
        >
        <Block
          flex={1}
          display="flex"
          justifyContent={{
            desktop:'flex-end'
          }}
        >

          <Block
            px={{
              mobile: 0,
              desktop: 10
            }}
            pt="80px"
            flex={1}
            alignItems={{
              mobile: 'center',
              desktop: 'flex-end'
            }}
            maxWidth={{
              desktop:600,
              tablet:1024
            }}
          >
            <Block
              display="flex"
              flex={1}
              alignItems="center"
              justifyContent="space-between"
              className="checkout-header"
              position={{
                mobile: 'fixed',
                desktop: 'static'
              }}
              mt={{
                mobile: 0,
                desktop: 0
              }}
              mb={{
                mobile: 3,
                desktop: 5
              }}
              left={{
                mobile: 0,
                desktop: 0
              }}
              top={{
                mobile: 0,
                desktop: 0
              }}
              right={{
                mobile: 0,
                desktop: 0
              }}
              padding={{
                mobile: '10px 0 10px 8px',
                desktop: 0
              }}
              bg={{
                mobile:'rgba(255,255,255,0.90)',
                desktop: 'transparent'
              }}
              boxShadow={{
                mobile: '0 2px 4px rgba(0,0,0,0.08)',
                desktop: 'none'
              }}
              zIndex={9999}
            >
              <Block
                display="flex"
                flex={1}
                alignItems="center"
                onClick={() => router.push({
                  pathname: `/${username}`
                })}
                position="relative"
                left={{
                  desktop:-28,
                  mobile:0
                }}
              >
                <Icon size={24} color={{
                  mobile: 'greyDark',
                  desktop: 'greyDark'
                }}
                name="chevron-left"
                />
                <Avatar ml={1} size={40} photo={photo} />
                <Text variant="subhead1" mx={2} color={{
                  mobile: 'navy',
                  desktop: 'navy'
                }}>
                  {`@${username}`}
                </Text>
              </Block>
              <Block
                alignItems="center"
                flex={1}
                justifyContent="flex-end"
                display={{
                  mobile: 'flex',
                  desktop: 'none'
                }}
                mr={{
                  mobile: 15,
                  desktop: 0
                }}
              >
                <Button
                  size="medium"
                  variant="alt"
                  text={isSub() ? `${formatAmount(amount)}/mo` : `Buy ${formatAmount(amount)}`}
                  onClick={e => {
                    e.preventDefault()
                    window.scrollTo(0,document.body.scrollHeight)
                    if(paymentInfo) {
                      document.querySelector('#submit-payment').click()
                    }
                  }}
                />
              </Block>
            </Block>
            <ItemBlock
              accessImage={accessImage}
              headline={headline}
              description={description}
              isSub={isSub()}
            />
            <Footer
              mt={10}
              display={{
                mobile: 'none',
                desktop: 'block'
              }}
              textAlign="center"
            />
            </Block>
          </Block>
          <Block
            bg="white"
            height="100%"
            alignItems={{
              mobile: 'flex-start',
              desktop: 'center'
            }}
            justifyContent={{
              mobile: 'center',
              desktop: 'flex-start'
            }}
            flex={1}
            display="flex"
            pt={3}
            px={{
              mobile: 5,
              desktop: 10
            }}
          >
            <Block maxWidth={500} width="100%" flex={1}>
              {!loggedIn ? (
                <LoginSignup
                  title={{
                    login: 'log in to purchase',
                    signup: 'sign up to purchase'
                  }}
                />
              ) : (
              <Elements
                stripe={stripePromise}
                options={{
                  fonts: [
                    {
                      src: `url(${fontPath})`,
                      family: 'Gilroy',
                      style: 'normal'
                    }
                  ]
                }}
              >
                <CheckoutForm
                  itemId={itemId}
                  userId={userId}
                  username={username}
                  isSub={isSub()}
                  amount={formatAmount(amount)}
                  onSuccess={() => {
                    router.push({
                      pathname: `/conversations/${userId}`
                    })
                  }}
                />
                </Elements>
              )}
            </Block>
          </Block>
        </Block>
      </Block>
    </>
  )
}

const mapStateToProps = state => ({
  loggedIn: Selectors.auth.loggedIn(state),
  signup: Selectors.auth.signup(state)
});

export default connect(mapStateToProps)(Checkout)
