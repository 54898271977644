import AuthActions from 'modules/auth/actions';
import ConversationActions from 'modules/conversations/actions';
import HomeActions from 'modules/home/actions';
import PostActions from 'modules/posts/actions';
import ProfileActions from 'modules/profile/actions';
import SearchActions from 'modules/search/actions';
import SettingsActions from 'modules/settings/actions';
import PaymentActions from 'modules/payment/actions';


export default {
  auth: AuthActions.auth,
  conversations: ConversationActions.conversations,
  home: HomeActions.home,
  posts: PostActions.posts,
  profile: ProfileActions.profile,
  search: SearchActions.search,
  settings: SettingsActions.settings,
  payment: PaymentActions.payment,
};
