import { Block, Text } from 'ui'
import { isSameDay } from './chatUtils'
import dayjs from 'dayjs'


const Day = ({currentMessage, previousMessage}) => {
  if (currentMessage && !isSameDay(currentMessage, previousMessage)) {
    return (
      <Block textAlign="center" py={4}>
        <Text variant="subhead1" color="grey">
          {dayjs(currentMessage?.createdAt).format('MMM DD, YYYY')}
        </Text>
      </Block>
    )
  }
  return null
}

export default Day