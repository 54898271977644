import Narrate from '@narrate/app-sdk';
import { takeEvery, call, select, put, take, delay, race, fork, cancel, debounce } from 'redux-saga/effects';
import _ from 'lodash';
import { routineSaga, sendTo } from 'utils/sagas';
import { authSuccessActions } from 'modules/auth/actions';
import Actions from 'actions';
import Selectors from 'selectors';

const loadList = routineSaga({
  routine: Actions.conversations.loadList,
  *request() {
    return yield call(() => Narrate.api.conversations.list(30));
  },
});

const loadMore = routineSaga({
  routine: Actions.conversations.loadMore,
  *request() {
    const { HasMore } = yield select(Selectors.conversations.loadList);
    if (HasMore) {
      return yield call(() => Narrate.api.conversations.listNextPage());
    }
    return null;
  },
});

const loadOne = routineSaga({
  routine: Actions.conversations.loadOne,
  *request({ payload: { key, markRead, userId, onSuccess } }) {
    const listLoaded = yield select(state => state.conversations.loadList.loadedAt);

    if (!listLoaded || !key) {
      console.log('loading conversations list');
      yield put(Actions.conversations.loadList.trigger());
      yield take(Actions.conversations.loadList.fulfill);

      const userIdMap = yield select(Selectors.conversations.keyByOtherUserId);
      key = userIdMap[userId];
    }


    const [initialUserId, participantUserId] = key.split('/');
    const { Item: conversation } = yield call(() => Narrate.api.conversations.get({ initialUserId, participantUserId }, 30));

    if (onSuccess) {
      yield call(() => onSuccess());
    }

    if (markRead) {
      const { messageSequence } = conversation;
      yield put(
        Actions.conversations.setUnreadStatus.trigger({
          initialUserId,
          participantUserId,
          messageSequence,
          unread: false,
        }),
      );
    }

    return {
      conversation,
      isNew: false,
    };
  },
});

const loadNextMessages = routineSaga({
  routine: Actions.conversations.loadNext,
  *request({ payload: { key, userId } }) {
    if (!key) {
      console.log('loading conversations list');
      yield put(Actions.conversations.loadList.trigger());

      const userIdMap = yield select(Selectors.conversations.keyByOtherUserId);
      key = userIdMap[userId];
    }

    const conversations = yield select(Selectors.conversations.conversations);
    const { messages } = conversations[key];
    const { Items } = yield call(() => Narrate.api.conversations.getNewMessages());
    // If there are new messages set them as read
    // if (Items.length > 0) {
    //   yield put(Actions.conversations.setUnreadStatus.trigger({
    //     initialUserId,
    //     participantUserId,
    //     messageSequence: Items.pop().messageNumber + 1,
    //     unread: false,
    //   }));
    // }

    return {
      messages,
      userId,
      newMessages: Items,
      key,
    };
  },
});

const loadPreviousMessages = routineSaga({
  routine: Actions.conversations.loadPrevious,
  *request({ payload: { key, callback } }) {
    const conversations = yield select(Selectors.conversations.conversations);
    const currentConversation = conversations[key];
    const { Items, HasMore } = yield call(() => Narrate.api.conversations.listMessagesPreviousPage());
    yield call(() => callback());
    return {
      hasMore: HasMore,
      currentMessages: currentConversation.messages,
      messages: Items,
      key,
    };
  },
});

const setUnreadStatus = routineSaga({
  routine: Actions.conversations.setUnreadStatus,
  *request({ payload: { unread, initialUserId, participantUserId, messageSequence } }) {
    const { Item: conversation } = yield call(() => Narrate.api.conversations.setUnreadStatus(unread, {
      initialUserId,
      participantUserId,
      messageSequence,
    }));

    return conversation;
  },
});

function* setupConversation({ userId }) {
  const keys = yield select(Selectors.conversations.keyByOtherUserId);
  const key = keys[userId];
  let conversation;
  let isNew = false;

  if (key) {
    const [initialUserId, participantUserId] = key.split('/');
    conversation = { initialUserId, participantUserId };
  } else {
    isNew = true;
    const { Item } = yield call(() => Narrate.api.conversations.create(userId));
    conversation = Item;
  }

  return {
    conversation,
    isNew,
  };
}

const sendMessage = routineSaga({
  routine: Actions.conversations.sendMessage,
  *request({ payload: { userId, message, image } }) {
    const { conversation, isNew } = yield call(() => setupConversation({ userId }));

    if (image) {
      yield put(Actions.conversations.sendImage.trigger({ image, userId }));
      yield cancel();
    }

    const { Item } = yield call(() => Narrate.api.conversations.addMessage(
      {
        message,
      },
      conversation,
    ));

    if (isNew) {
      yield put(Actions.conversations.loadOne.success({ conversation: Item }));
    }

    return {
      conversation: Item,
      isNew,
    };
  },
});


const generateLink = routineSaga({
  routine: Actions.conversations.content.generateLink,
  *request({ payload: { photosetId, entitlementId, onSuccess } }) {
    const link = yield call(() => Narrate.api.media.createSecureLink(photosetId, entitlementId));
    if(onSuccess) {
      yield call(onSuccess, link)
    }
    return link
  },
});

// function* getAssetFileAbsolutePath(path) {
//   const ext = path.match(/ext=([^&]*)/)[1].toLowerCase();

//   const dest = `${RNFS.TemporaryDirectoryPath}${Math.random()
//     .toString(36)
//     .substring(7)}.${ext}`;
//   const uri = yield call(() => RNFS.copyAssetsFileIOS(path, dest, 0, 0));
//   return {
//     ext,
//     uri,
//   };
// }

// const sendImage = routineSaga({
//   routine: Actions.conversations.sendImage,
//   *request({ payload: { image, userId } }) {
//     const { uri, ext } = yield call(getAssetFileAbsolutePath, image);
//     const photoToUpload = {
//       name: `photo.${ext}`,
//       type: `image/${ext}`,
//       uri,
//     };

//     const { url } = yield call(() => Narrate.api.photo.upload(photoToUpload, 'photo'));

//     if (!url) {
//       yield put(Actions.conversations.sendImage.failure({ error: 'Failed Upload Image' }));
//       yield cancel();
//     }

//     return {
//       url,
//       userId,
//     };
//   },
//   *afterSuccess({ url, userId }) {
//     const { conversation, isNew } = yield call(() => setupConversation({ userId }));
//     const { Item } = yield call(() => Narrate.api.conversations.addMessage(
//       {
//         image: url,
//       },
//       conversation,
//     ));

//     yield put(
//       Actions.conversations.sendMessage.success({
//         conversation: Item,
//         isNew,
//       }),
//     );
//   },
// });

const destroy = routineSaga({
  routine: Actions.conversations.destroy,
  *request({ payload: { initialUserId, participantUserId } }) {
    yield call(() => Narrate.api.conversations.delete({ initialUserId, participantUserId }));
  },
});

// function* setBadge() {
//   const unread = yield select(Selectors.conversations.unreadCount);
//   console.log('About to set badge: ', unread);
//   if (Platform.OS === 'ios') {
//     const notification = new firebase.notifications.Notification().ios.setBadge(unread);
//     yield firebase.notifications().displayNotification(notification);
//   }
// }

function* pollConvo(payload) {
  const { key, userId, timeout = 10000 } = payload;
  while (true) {
    try {
      yield put(Actions.conversations.loadNext.trigger({ key, userId }));
      yield delay(timeout);
    } catch (err) {
      yield put(Actions.conversations.poll.stop);
    }
  }
}

function* pollConvoWatch() {
  while (true) {
    const action = yield take(Actions.conversations.poll.start);
    yield race([call(pollConvo, action.payload), take(Actions.conversations.poll.stop)]);
  }
}

function* handleNotification({ payload }) {
  const data = _.get(payload, 'payload._data', {});
  const userId = yield select(Selectors.auth.userId);
  const { initialUserId, participantUserId } = JSON.parse(_.get(data, 'payload', '{}'));
  const belongsToCurrentUser = userId === initialUserId || userId === participantUserId;
  const activeUser = userId === initialUserId ? participantUserId : initialUserId;
  const key = `${initialUserId}/${participantUserId}`;

  if (payload.foreground) {
    if (initialUserId && participantUserId && belongsToCurrentUser) {
      console.log('about to trigger loadOne from handleNewMessageNotification');
      const cm = yield select(Selectors.conversations.conversationMeta);
      const currentKey = `${cm.initialUserId}/${cm.participantUserId}`;

      if (key === currentKey) {
        yield put(Actions.conversations.loadOne.trigger({ key }));
      }
    }
  }

  // if (!payload.foreground) {
  //   if (data.type === 'unreplied_boost') {
  //     NavigatorService.navigate(Screens.INBOX);
  //   } else {
  //     console.log('about to trigger loadOne from handleNewMessageNotification');
  //     yield put(Actions.conversations.loadOne.trigger({ key }));
  //     NavigatorService.navigate('Inbox', {
  //       screen: Screens.INBOX_CONVERSATION,
  //       params: { key, userId: activeUser },
  //     });
  //   }
  // }

  // const badge = _.get(payload, 'payload._ios._badge', null);
  // const unreadCount = yield select(Selectors.conversations.unreadCount);
  // if (badge && badge !== unreadCount) {
  //   yield put(Actions.conversations.loadList.trigger());
  // }

  // Analytics.logEvent('push_notification_receive');
}

export default function* combined() {
  yield takeEvery(
    [Actions.conversations.loadList.trigger, Actions.conversations.destroy.success],
    loadList,
  );
  yield takeEvery(Actions.conversations.loadOne.trigger, loadOne);
  yield takeEvery(Actions.conversations.loadNext.trigger, loadNextMessages);
  yield takeEvery(Actions.conversations.loadPrevious.trigger, loadPreviousMessages);
  yield takeEvery(Actions.conversations.setUnreadStatus.trigger, setUnreadStatus);
  yield takeEvery(Actions.conversations.sendMessage.trigger, sendMessage);
  yield takeEvery(Actions.conversations.destroy.trigger, destroy);
  // yield takeEvery(Actions.conversations.loadList.success, setBadge);
  // yield takeEvery(Actions.notifications.push.trigger, handleNotification);
  yield takeEvery(authSuccessActions, sendTo(Actions.conversations.loadList.trigger));
  // yield takeEvery(Actions.conversations.sendImage.trigger, sendImage);
  //yield takeEvery(Actions.conversations.loadMore.trigger, loadMore);
  yield debounce(800, Actions.conversations.loadMore.trigger, loadMore);
  yield takeEvery(Actions.conversations.content.generateLink.trigger, generateLink);
  // yield takeEvery(Actions.conversations.loadOne.failure, sendTo(Actions.conversations.poll.stop));
  yield fork(pollConvoWatch);
}
