import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown/with-html';
import gfm from 'remark-gfm';
import marked from 'marked';
import terms from './terms.md';
import { useComponentWillMount } from 'utils'
import Logo from 'components/common/Logo'


function Terms() {
  const [content, setContent] = useState('');

  marked.setOptions({
    smartLists: true
  })

  useComponentWillMount(() => {
    fetch(terms).then((response) => response.text()).then((text) => {
      setContent(text)
    })
  });

  useEffect(() => {
    const hash = window.location.hash
    if(!hash) return;
    const element = document.querySelector(hash);
    if(element) {
      element.scrollIntoView({
        behavior: 'smooth'
      });
    }

  }, [content])

  return (
    <article>
      <header>
        <hgroup>
          <Logo small />
          <h1>Terms of Use</h1>
          <p className="updated" />
        </hgroup>
      </header>
      <main>
        <ReactMarkdown plugins={[gfm]} children={content} allowDangerousHtml />
      </main>
    </article>
  )
}

export default Terms;