import React, { useEffect, useRef, useState, useCallback } from 'react'
import { Block, Text, Avatar, Icon, getStyle, colors, Spinner } from 'ui'
import styled, { css } from 'styled-components'
import { useSelector, useDispatch } from 'react-redux';
import Selectors from 'selectors';
import Actions from 'actions';
import _ from 'lodash';
import { truncate, formatAmount } from 'utils'
import Messages from './Message'

const MessageContainerWrapper = styled.section`
    max-height:calc(100vh - 300px);
    overflow-y: auto;
    padding-top: 80px;

    @media (min-width: 320px) and (max-width : 736px)
    {
      max-height:100%;
      overflow-y: visible;
    }

    @media (min-width: 736px) and (max-width: 1024px)
    {
      max-height:100%;
      overflow-y: visible;
    }

    & > div {
      display:inline-flex;
      flex-direction:column;
      justify-content: flex-end;
      flex:1;
      height:100%;
      width:100%;




      & > main {
        /* display: flex;
        flex-direction: column;
        justify-content: flex-end; */
        padding:60px 0 20px;

        @media (min-width: 320px) and (max-width : 736px) {
          padding:60px 0 120px;
        }
      }
    }
`

const LoadMoreButton = styled(Block)`
  border-radius: 15px;
  padding:8px 30px;
  justify-content:center;
  align-items:center;
  background-color: ${getStyle('colors.greyLight')};
  display:inline-flex;
  margin:0 60px;

`

function MessageContainer({ conversation, otherUserId, onLoadPrevious, containerRef }){
  const profiles = useSelector(Selectors.profile.usersIndex)
  const currentUser = useSelector(Selectors.profile.myProfile)
  const currentUserId = useSelector(Selectors.auth.userId)

  const otherUsr = () => {
    return {
      _id: otherUserId,
      ..._.get(profiles, otherUserId, {}),
    };
  }

  const otherUser = otherUsr()


  /**
   *  get messages
   */
  const formatMessages = c => {

    if (!c) {
      return [];
    }

    const { messages } = c
  
    const lastMessageIndex = _.findLastIndex(messages, { userId: currentUserId });
    const rendered = _.flatMap(messages, (message, index) => {
      const items = [];
  
      const processedMessage = {
        _id: index,
        ...message,
        user: formatUser(message?.userId),
        ...(message?.message?.image
          ? { image: message?.message?.image }
          : { text: message?.message?.message }),
        msgType: message?.msgType,
        lastMessage: lastMessageIndex === index,
        createdAt: message?.createdAt,
      };
  
      if (message?.message?.transaction || message?.transaction) {
        const supportMessage = formatSupportMessage(message, index);
        if (supportMessage) {
          items.push(supportMessage);
        }
      }
  
      items.push(processedMessage);
  
      return items;
    });
  
    return rendered;
  }

  /**
   *  formatUser
   */

  const formatUser = userId => {
    const { username, name, avatar, photo } = _.get(profiles, userId, {});
  
    return {
      _id: userId,
      username,
      name,
      avatar,
      photo,
    };
  };
  
  /**
   *  formatSupportMessage
   */
  const formatSupportMessage = (message, index) => {
    let text;
  
    if (_.get(message.message, 'transaction.purchaseObjectType') === 'AccessBlock') {
      const {
        message: { transaction },
      } = message;
  
      if (transaction.transactionUserId === currentUserId) {
        text = `You supported @${otherUser.username} by purchasing `
          + `"${transaction.headline}" for `
          + `${formatAmount(transaction.amount)}!`;
      } else {
        text = `@${otherUser.username} supported you by purchasing `
          + `"${transaction.headline}" for `
          + `${formatAmount(transaction.amount)}!`;
      }
  
      return {
        _id: `support-${index}`,
        system: true,
        text,
        data: {
          users: {
            them: otherUser,
            me: currentUser,
          },
        },
      };
    }
  
    if (_.get(message, 'transaction.purchaseObjectType') === 'ConversationPriority') {
      const { transaction } = message;
      if (transaction.userId === currentUserId) {
        text = `You supported @${otherUser.username} with ${formatAmount(
          transaction.amount,
        )}!`;
      } else {
        text = `You received ${formatAmount(transaction.amount)} for your reply!`;
      }
  
      return {
        _id: `support-${index}`,
        system: true,
        text,
        data: {
          users: {
            them: otherUser,
            me: currentUser,
          },
        },
      };
    }
  
    return null;
  };


  const messages = formatMessages(conversation)
  const user = formatUser(currentUserId)
  const [scrollDiv, setScrollDiv] = useState(null)
  const ref = useRef()
  const scrollRef = useRef()
  const TOP_DISTANCE_TRIG = 10
  const MAX_MESSAGES_VISIBLE = 30
  const [loadingPrev, setLoadingPrev] = useState(false)
  const canLoadMore = conversation?.messageCount >= messages?.length

  const loadPrevious = useCallback(e => {
    setLoadingPrev(true)
    onLoadPrevious(() => {
      setLoadingPrev(false)
    })
  }, [onLoadPrevious])

  const handleScroll = useCallback(e => {
    const scrollTop = e.target.scrollTop

    if(scrollTop <= TOP_DISTANCE_TRIG && canLoadMore) {
      // loadMore
      loadPrevious()
    }
  }, [loadPrevious, canLoadMore])

  useEffect(() => { 
    const chatDiv = ref?.current;
    chatDiv?.scrollIntoView({block: "end"});
  }, [])

  useEffect(() => { 
    setScrollDiv(scrollRef?.current)
    scrollDiv?.addEventListener('scroll', handleScroll)

    return () => {
      scrollDiv?.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll, scrollDiv])


  return (
    <MessageContainerWrapper ref={scrollRef}>
      <div>
        <main ref={ref} id="scroll-container">
          {canLoadMore && (
            <Block row justifyContent="center" mb={3}>
              <LoadMoreButton onClick={loadPrevious}>
                {loadingPrev ? (
                  <Spinner light size={30} />
                ):(
                  <Text color="navyLighter">
                    load earlier messages
                  </Text>
                )}
              </LoadMoreButton>
            </Block>
          )}
          {messages.map((item, index) => {
            const previousMessage = messages[index - 1] || {}
            const nextMessage = messages[index + 1] || {}
            const messageProps = {
              user,
              system: item?.system || false,
              key: item?._id,
              currentMessage: item,
              previousMessage,
              nextMessage,
              position: item?.user?._id === user?._id ? 'right' : 'left',
            }

            return (
              <Messages {...messageProps} />
            )
          })}
        </main>
      </div>
    </MessageContainerWrapper>
  )
}

export default MessageContainer