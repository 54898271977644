// // import { NativeEventEmitter, NativeModules } from 'react-native';
import { call, put, select, takeEvery, take, fork, delay, race } from 'redux-saga/effects';
import { channel } from 'redux-saga';
import Narrate from '@narrate/app-sdk';
import { routineSaga, sendTo } from 'utils/sagas';
import Actions from 'actions'; 
import Selectors from 'selectors';

const paymentIntent = routineSaga({
  routine: Actions.payment.paymentIntent,
  *request({ payload: { userId, itemId, onSuccess, data, newlyAdded = false } = {} }) {
    let intent
    if(data) {
      intent = yield call(() => Narrate.api.accessBlock.purchase({ userId, id: itemId }, {
        action: 'update-payment',
        client_secret: data.client_secret,
        txId: data.txId,
        paymentMethodId: data?.paymentInfo?.id
      }))
    }else{
      intent = yield call(() => Narrate.api.accessBlock.purchase({ userId, id: itemId }))
    }


    if(onSuccess) {
      yield call(onSuccess, intent)
    }

    return {...intent, newlyAdded}
  }
})

const paymentMethods = routineSaga({
  routine: Actions.payment.paymentMethods,
  *request() {
    return yield call(Narrate.api.account.paymentMethods)
  }
})

// const completePayment = routineSaga({
//   routine: Actions.payment.completePayment,
//   *request({ payload: { newPayment, paymentMethod, onSuccess } = {} }) {
//     const { paymentInfo, client_secret, txId } = yield select(Selectors.payment.paymentIntent);
//     const result = yield call(() => stripe.confirmCardPayment(client_secret, {
//       payment_method: paymentMethod,
//       setup_future_usage: 'off_session'
//     }) )

//     if(result.error) {
//       yield put(
//         Actions.payment.completePayment.failure({
//           error: result.error.message
//         }),
//       );
//     } else if (result.paymentIntent.status === 'succeeded') {
//       yield put(
//         Actions.payment.paymentConfirm.trigger({
//           txId,
//           onSuccess: () => {
//             if (onSuccess) {
//               onSuccess()
//             }
//           }
//         })
//       )
//     }
//   }
// })

const paymentConfirm = routineSaga({
  routine: Actions.payment.paymentConfirm,
  *request({ payload: { txId, onSuccess } = {} }) {
    const data = yield call(() => Narrate.api.accessBlock.paymentConfirmed({ txId }))

    if(onSuccess) {
      yield call(onSuccess)
    }

    return data
  }
})



export default function* combined() {
  yield takeEvery(Actions.payment.paymentIntent.trigger, paymentIntent);
  // yield takeEvery(Actions.payment.completePayment.trigger, completePayment);
  yield takeEvery(Actions.payment.paymentConfirm.trigger, paymentConfirm);
  yield takeEvery(Actions.payment.paymentMethods.trigger, paymentMethods);
}