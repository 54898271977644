import styled, { css } from 'styled-components'
import { variant, maxWidth } from 'styled-system'
import PropTypes from 'prop-types'
import theme, { Typography } from './theme'
import { TYPOGRAPHY, COMMON, LAYOUT } from './constants'

const variants = variant({
  variants: {
    ...Typography,
  },
})

const Text = styled.p`
  ${(props) => props.center && css`textAlign: center;`};
  ${(props) => props.right && css`textAlign: right;`};
  ${(props) => props.left && css`textAlign: left;`};
  ${(props) => props.noWrap && css`white-space:nowrap;`};
  ${(props) => props.onClick && css`cursor:pointer;`};
  margin:0;
  ${maxWidth}
  ${variants}
  ${TYPOGRAPHY}
  ${COMMON}
  ${props => props.onClick && css`
    cursor:pointer;
  `}
`

Text.defaultProps = {
  theme,
  variant: 'body',
}

Text.propTypes = {
  // variant: PropTypes.oneOf([
  //   'button',
  //   'body',
  //   'bodyRegular',
  //   'bodySmall',
  //   'overline',
  //   'settings',
  //   'subhead4',
  //   'subhead3',
  //   'subhead2',
  //   'subhead1',
  //   'pageHeader',
  //   'overline',
  //   'label',
  //   'caption',
  //   'h1',
  //   'h2',
  //   'h3',
  //   'h4',
  //   'h5',
  //   'h6',
  // ]),
  ...TYPOGRAPHY.propTypes,
  ...COMMON.propTypes,
}

export default Text
