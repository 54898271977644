import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import { COMMON, FLEX, LAYOUT, BORDER, GRID, TYPOGRAPHY, POSITION } from './constants'
import theme from './theme'

const Box = styled.div`
  ${COMMON}
  ${FLEX}
  ${LAYOUT}
  ${BORDER}
  ${GRID}
  ${TYPOGRAPHY}
  ${POSITION}
  ${(props) => props.center && css`align-items: center;`}
  ${(props) => props.row && css`display: flex; flex-direction: row;`}
  ${(props) => props.col && css`display: flex; flex-direction: column;`}
  ${(props) => props.middle && css`justify-content: center;`}
  ${(props) => props.transFormCenter && css`top:50%; left:50%; transform: translate(-50%, -50%);`}
  ${(props) => props.onClick && css`cursor:pointer;`}
  ${(props) => props.style}
  ${(props) => props.gradient && css`background: linear-gradient(to right, ${rgba('#4c62cd', 0.9)}, ${rgba('#1A2249', 0.9)});`}
`

const Block = ({ scroll, children, ...props }) => (
  <Box {...props}>
    {children}
  </Box>
)

Block.defaultProps = {
  theme,
  row: false,
  col: false,
  middle: false,
  center: false,
  children: false,
  transFormCenter: false
}

Block.propTypes = {
  children: PropTypes.node,
  center: PropTypes.bool,
  row: PropTypes.bool,
  col: PropTypes.bool,
  middle: PropTypes.bool,
  right: PropTypes.bool,
  bottom: PropTypes.bool,
  transFormCenter: PropTypes.bool,
  theme: PropTypes.object,
  ...COMMON.propTypes,
  ...FLEX.propTypes,
  ...LAYOUT.propTypes,
  ...BORDER.propTypes,
  ...TYPOGRAPHY.propTypes,
  ...POSITION.propTypes
}

export default Block
