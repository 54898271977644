import React, { useEffect } from 'react'
import { Block, Text, Avatar, Icon, getStyle, colors } from 'ui'
import styled from 'styled-components'
import Day from './Day'
import Bubble from './Bubble'
import SystemMessage from './SystemMessage'

const ItemRow = styled(Block)`
  display:flex;
`

const renderDay = props => {
  const { currentMessage, previousMessage } = props
  if (currentMessage && currentMessage.createdAt) {
    return <Day currentMessage={currentMessage} previousMessage={previousMessage} />
  }
  return null
}

const renderSystemMessage = props => {
  const { currentMessage } = props
  return <SystemMessage currentMessage={currentMessage} />
}

const renderBubble = props => {
  const { currentMessage } = props
  return <Bubble currentMessage={currentMessage} {...props} />
}


const renderAvatar = props => {
  const { user, currentMessage, showUserAvatar = true } = props

  if (
    user &&
    user._id &&
    currentMessage &&
    currentMessage.user &&
    user._id === currentMessage.user._id &&
    !showUserAvatar
  ) {
    return null
  }

  if (
    currentMessage &&
    currentMessage.user &&
    currentMessage.user.avatar === null
  ) {
    return null
  }



  return (
    <Block 
      pl={4} 
      pr={{
        mobile: 0,
        desktop:3
      }}
      pb={3}
    >
      <Avatar photo={currentMessage?.user?.photo} iconColor="greyLight" size={45} />
    </Block>
  )
}


const Message = (props) => {

  const { currentMessage, nextMessage, previousMessage, position } = props

  if (currentMessage) {
    //const sameUser = isSameUser(currentMessage, nextMessage)
    return (
      <>
        {renderDay(props)}
        {currentMessage?.system ? (
          renderSystemMessage(props)
        ) : (
          <ItemRow pb={2}>
            {position === 'left' ? renderAvatar(props) : null}
            {renderBubble(props)}
            {/* position === 'right' ? renderAvatar(props) : null */}
          </ItemRow>
        )}
      </>
    )
  }
  return null
}

export default Message