import { createSelector } from 'reselect';
import _ from 'lodash';
import data from './data';

const Selectors = {
  profile: state => state.profile,
  userProfiles: state => state.profile.userProfiles,
  loading: state => state.profile.loading,
  user: state => state.profile.user,
  userLoading: state => state.profile.userLoading,
  earningsLoading: state => state.profile.earningsLoading,
  updateProfileLoading: state => state.profile.updateProfileLoading,
  convoUser: state => state.profile.convoUser,
  uploadingProfileImage: state => state.profile.myProfile.loading,
  isOpen: state => state.profile.isOpen,
  isRegister: state => state.profile.isRegister,
  myProfile: state => state.profile.myProfile
};

Selectors.current = createSelector(
  Selectors.profile,
  profile => profile.current || { name: '' },
);

// Selectors.myProfile = createSelector(
//   Selectors.profile,
//   profile => ({
//     ...profile.myProfile,
//     priorityMessageVirgin: true,
//   } || {}),
// );

Selectors.feed = createSelector(() => data.feed);

Selectors.profileCreated = createSelector(
  Selectors.myProfile,
  ({ name, about } = {}) => name || about,
);

Selectors.photo = createSelector(
  Selectors.myProfile,
  myProfile => myProfile.photo,
);

Selectors.profileLoaded = createSelector(
  Selectors.profile,
  profile => !!_.get(profile, 'loadMyProfile.loadedAt'),
);

Selectors.usersIndex = createSelector(
  Selectors.userProfiles,
  Selectors.myProfile,
  (profiles, myProfile) => {
    const index = _.keyBy(profiles, 'userId');
    if (myProfile.userId) {
      index[myProfile.userId] = myProfile;
    }
    return index;
  },
);

const _isMe = profile => userId => !userId || profile.userId === userId;

Selectors.isMe = createSelector(
  Selectors.myProfile,
  profile => _isMe(profile),
);

Selectors.email = createSelector(
  Selectors.profile,
  profile => profile.email,
);

export default Selectors;
