import MessageContainer from './MessageContainer'
import Input from './Input'
import { Block, Text, Avatar, Icon, getStyle, Spinner, Modal } from 'ui'
import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import Selectors from 'selectors';
import Actions from 'actions';
import _ from 'lodash';
import {formatAmount} from 'utils'
import React, { useEffect, useRef } from 'react';
import { isMobile, MobileView } from "react-device-detect";
import { useHistory } from 'react-router-dom';

const ContainerStyle = styled.section`
  list-style:none;
  padding:0;
  margin:0;
  min-height: 100%;
  background: linear-gradient(0deg, rgba(250,250,250,1) 0%, rgba(241,241,241,1) 80%);
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  display:grid;
  grid-template-rows:80px 1fr auto auto;

  @media (min-width: 320px) and (max-width : 736px)
  {
    grid-template-rows: 1fr auto;
    padding-top:60px;
  }

`

const Header = styled.header`
  background-color: ${getStyle('colors.white')};
  border-bottom:1px solid ${getStyle('colors.greyLighter')};
  display:grid;
  align-items:center;
  padding:0;
  grid-template-columns:1fr auto;
  border-top-right-radius: 6px;

  @media (min-width: 320px) and (max-width : 736px) {
    grid-template-columns:1fr auto;
    position:fixed;
    right:0;
    left:0;
    top:0;
    z-index:999;
    height:60px;
  }

`

const ConversationHeader = ({ user }) => {
  const { push } = useHistory()
  const dispatch = useDispatch()
  const action = () => {
    push(`/${user?.username}`)
  }

  const goBack = () => {
    push('/conversations')
  }

  if(user) {
    return (
      <Header>
        <Block 
          alignItems="center"
          row 
        >
          <MobileView>
            <Icon 
              name="chevron-left" 
              color="navy" 
              size={24}
              ml={3}
              onClick={goBack}
            />
          </MobileView>
          <Avatar
            photo={user?.photo}
            iconColor="greyLight"
            size={isMobile ? 40 : 50}
            ml={isMobile ? 2 : 6}
            onClick={action}
          />
          <Text variant="subhead1" ml={1} color="navy" onClick={action}>{`@${user?.username}`}</Text>
        </Block>
        <Icon name="dots-horizontal" color="grey" size={26} mr={isMobile ? 15 : 30} onClick={() => {
          dispatch(Actions.settings.reportModal({
            visible: true,
            data: user,
            type: 'conversation'
          }))
        }} />
      </Header>
    )
  }

  return (
    <Header>
      <Block 
        alignItems="center"
        row 
      >
        <MobileView>
          <Icon 
            name="chevron-left" 
            color="navy" 
            size={24}
            ml={3}
            onClick={action}
          />
        </MobileView>
      </Block>
    </Header>
  )
}

const Conversation = ({ conversation, otherUserId, loading = true, convoKey }) => {
  const dispatch = useDispatch()
  const profiles = useSelector(Selectors.profile.usersIndex)
  const priorityConvo = useSelector(Selectors.conversations.priorityConvo)
  const currentUserId = useSelector(Selectors.auth.userId)
  const containerRef = useRef(null)
  const user = _.get(profiles, otherUserId)
  const sendMessage = text => {
    dispatch(Actions.conversations.sendMessage.trigger({ 
      userId: user.userId,
      message: text
    }));
    document.querySelector('#scroll-container').scrollIntoView({block: "end"});
  }

  const loadPrevious = cb => {
    dispatch(
      Actions.conversations.loadPrevious.trigger({
        key: convoKey,
        callback: cb,
      }),
    );
  }

  if(loading) {
    return (
      <Block 
        height="100%"
        col
        justifyContent="center"
        alignCenter="center"
        textAlign="center"
      >
        <Spinner size={40} />
        <Text color="grey">loading...</Text>
      </Block>
    )
  }

  return(
    <ContainerStyle>
      <ConversationHeader user={user} />
      <MessageContainer 
        conversation={conversation}
        otherUserId={otherUserId}
        containerRef={containerRef}
        onLoadPrevious={loadPrevious}
      />
      {priorityConvo && priorityConvo?.transaction?.recipientUserId === currentUserId && priorityConvo?.status === 'placed' && (
        <Block
          bg="brand"
          p={3}
          row
          left={0}
          right={0}
          position={isMobile ? 'fixed' : 'static'}
          zIndex={999}
        >
          <Icon name="boost" color="white" size={20} />
          <Text variant={{
            mobile:'subhead2',
            desktop:'subhead1'
          }} color="white" ml={2}>
          {`You will receive ${formatAmount(priorityConvo?.transaction?.amount)} if you reply to this message.`}
          </Text>
        </Block>
      )}
      <Input onSend={sendMessage} />
    </ContainerStyle>
  )
}

export default Conversation