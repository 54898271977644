import { useHistory } from "react-router-dom";
import { Block, Card, CardBody, Text, Button, Avatar, Icon } from 'ui'
import { formatAmount } from 'utils'
import { useSelector, useDispatch } from 'react-redux';
import Selectors from 'selectors';
import Actions from 'actions';



export default function AccessBlock({ item, user }) {
  const selfCheck = useSelector(Selectors.profile.isMe)
  const loggedIn = useSelector(Selectors.auth.loggedIn)
  const isMe = loggedIn && selfCheck(user?.userId)
  const dispatch = useDispatch()
  const router = useHistory()
  const checkout = () => {
    if(!isMe) {
      router.push({
        pathname: `/${user.username.toLowerCase()}/${item.userId}@${item.itemId}/checkout`
      })
    }
  }

  const isSub = () => item.paymentTerms === 'monthly'


  if (!user) {
    return null
  }

  return (
    <Card key={item.id} flex={1}>
      <Block onClick={() => checkout()}>
        <img
          width="100%"
          draggable={false}
          alt={item?.headline}
          src={item?.accessImage}
          onError={(e) => { e.target.onerror = null; e.target.src = 'images/default.jpg' }}
        />
      </Block>
      <CardBody>
        <Block row justifyContent="space-between">
          <Block flex={1} col>
            <Block row justifyContent="space-between" center mb={3}>
              <Block flex={1} row alignItems="center">
                <Avatar size={32} photo={user?.photo} />
                <Text variant="subhead2" color="navy" pl={2}>
                  {`@${user?.username}`}
                </Text>
              </Block>
              {/* <Icon name="dots-horizontal" color="navy" size={20} allowFontScaling={false} onClick={() => {
                if(!isMe) {
                  dispatch(Actions.settings.reportModal({
                    visible: true,
                    data: item,
                    type: 'accessblock'
                  }))
                }
              }} /> */}
            </Block>
            <Text variant="subhead1" color="navy" onClick={() => checkout()}>{item?.headline}</Text>
            <Text pt={2} pb={4} onClick={() => checkout()}>
              {item?.description}
            </Text>
          </Block>
        </Block>
        <Block>
          <Button
            variant={isMe ? "outline" : "alt"}
            block
            size="medium"
            disabled={isMe}
            onClick={() => checkout()}
            text={isSub() ? `Subscribe ${formatAmount(item?.amount)}/mo` : `Buy ${formatAmount(item?.amount)}`}
          />
        </Block>
      </CardBody>
    </Card>
  )
}
