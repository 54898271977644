import Narrate from '@narrate/app-sdk';
import { takeEvery, put, call, cancel, select, take, fork, cancelled, delay } from 'redux-saga/effects';
import { eventChannel, buffers, END } from 'redux-saga';
import { routineSaga, sendTo } from 'utils/sagas';
import Actions from 'actions';
// import { Platform } from 'react-native';
// import NavigatorService from 'utils/NavigatorService';
import Selectors from 'selectors';
// import RNFetchBlob from 'rn-fetch-blob';
// import * as MediaLibrary from 'expo-media-library';

const { posts } = Actions;

function getPriceInCents(priceString) {
  const match = priceString.replace(/[^\d.]/g, '').split('.');
  const dollars = parseInt(match[0], 10);
  let cents = match[1] || '00';
  cents = parseInt(cents, 10);

  return dollars * 100 + cents;
}

// function navigateAway() {
//   NavigatorService.navigate(Screens.PROFILE);
// }

// function createUploadVideoChannel({ url, uri }) {
//   return eventChannel(emitter => {
//     RNFetchBlob.fetch('PUT', url, null, RNFetchBlob.wrap(uri))
//       .uploadProgress((written, total) => {
//         const progress = Math.floor((written / total) * 100);
//         console.log(progress);
//         if (progress < 100) {
//           emitter({ progress });
//         }
//       })
//       .then(res => {
//         emitter({ uploadedVideo: res });
//       }).catch(error => {
//         emitter(error);
//       });

//     return () => {};
//   });
// }

function createUploadImageChannel(media, assetType) {
  return eventChannel(emitter => {
    Narrate.api.photo.upload(media, assetType, ({ loaded, total }) => {
      const progress = Math.round((loaded * 100) / total);
      emitter({ progress });
    })
      .then(res => {
        emitter({ uploadedImage: res });
      })
      .catch(error => {
        emitter(error);
      });

    return () => {};
  });
}

function* mediaImageUpload({ image, assetType, mediaSetId, onSuccess, onError }) {
  const imageToUpload = {
    name: 'photo.jpeg',
    type: 'image/jpeg',
    uri: image.uri,
  };

  const channel = yield call(() => createUploadImageChannel(imageToUpload, assetType));
  while (true) {
    const { progress = 0, error, uploadedImage } = yield take(channel, buffers.sliding(5));
    const { count } = yield select(Selectors.posts.contentUploading);
    console.log(count);
    yield put(Actions.posts.content.uploadProgress({ image, progress, count }));
    if (error) {
      yield put(
        Actions.posts.content.upload.failure({
          ...image,
          uploading: false,
          failed: true,
        }),
      );
      channel.close();
    }
    if (uploadedImage?.url) {
      yield put(Actions.posts.content.upload.success({
        original: image,
        uploadedImage,
        mediaSetId,
      }));

      if (mediaSetId) {
        yield put(Actions.posts.content.addPhotos.trigger({
          content: [{ ...image, ...uploadedImage }],
          mediaSetId,
        }));
        yield delay(1000);
        yield put(Actions.posts.content.get.trigger({
          mediaSetId,
        }));
      }

      if (onSuccess) {
        yield call(onSuccess);
      }

      yield take(Actions.posts.content.upload.fulfill);
    }

    if (onError) {
      yield call(onError);
      yield put(
        Actions.posts.content.uploadStatus({
          status: false,
        }),
      );
    }
  }
}

function formData(file, name = 'file') {
  const data = new FormData();
  data.append(name, file);

  Object.keys(file).forEach(key => {
    data.append(key, file[key]);
  });

  return data;
}

// function* mediaVideoUpload({ video, mediaSetId = '', onSuccess, onError }) {
//   const options = {
//     mediaSetId,
//     width: video.width,
//     height: video.height,
//     quality: '1080p',
//   };

//   const { videoId, uploadRequest: { url } } = yield call(() => Narrate.api.media.newVideoUpload(options));

//   let uri;
//   const info = yield MediaLibrary.getAssetInfoAsync(video.id);
//   const { localUri } = info;

//   uri = (Platform.OS === 'ios') ? localUri.replace('file://', '') : localUri;

//   const channel = yield call(() => createUploadVideoChannel({ url, uri }));

//   while (true) {
//     const { progress = 0, error, uploadedVideo } = yield take(channel, buffers.sliding(5));
//     const { count } = yield select(Selectors.posts.contentUploading);
//     yield put(Actions.posts.content.uploadProgress({ video, progress, count }));
//     if (error) {
//       yield put(
//         Actions.posts.content.upload.failure({
//           ...video,
//           uploading: false,
//           failed: true,
//         }),
//       );
//       yield put(
//         Actions.posts.content.uploadStatus({
//           status: false,
//         }),
//       );
//       channel.close();
//     }
//     if (uploadedVideo?.respInfo) {
//       const { videoItem } = yield call(() => Narrate.api.media.updateVideo(videoId, video, { action: 'uploaded', mediaSetId }));
//       yield put(Actions.posts.content.upload.success({
//         ...video,
//         original: videoItem,
//         mediaSetId,
//         originalURI: video.uri,
//       }));

//       if (onSuccess) {
//         yield call(onSuccess);
//       }
//     }
//   }
// }

class PostsSaga {
  create = routineSaga({
    routine: posts.create,
    *request({ payload: { values, onSuccess } }) {
      values.amount = getPriceInCents(values.price);
      if (values.quantity) {
        values = {
          ...values,
          quantity: parseInt(values.quantity, 10),
        };
      }

      const content = yield select(Selectors.posts.selectedContent);

      if (content.length > 0) {
        const { itemId } = yield call(() => Narrate.api.media.save(
          {
            title: values.headline,
            media: content,
          },
        ));
        values.mediaSetId = itemId;
      }

      yield call(() => Narrate.api.accessBlock.save(values));
      yield call(() => onSuccess());
    },
  });

  destroy = routineSaga({
    routine: posts.destroy,
    *request({ payload: { id } }) {
      return yield Narrate.api.accessBlock.destroy(id);
    },
  });

  list = routineSaga({
    routine: posts.list,
    *request({ payload: { onSuccess } }) {
      const list = yield Narrate.api.accessBlock.list();
      if (onSuccess) {
        yield call(() => onSuccess());
      }
      return list;
    },
  });

  listByUser = routineSaga({
    routine: posts.listByUser,
    *request({ payload: { userId } }) {
      return yield Narrate.api.accessBlock.findByUserId(userId);
    },
  });

  show = routineSaga({
    routine: posts.show,
    *request({ payload: { id } }) {
      yield Narrate.api.accessBlock.get(id);
    },
  });

  update = routineSaga({
    routine: posts.update,
    *request({ payload: { id, values, onSuccess } }) {
      values.amount = getPriceInCents(values.price);
      if (values.quantity) {
        values = {
          ...values,
          quantity: parseInt(values.quantity, 10),
        };
      }

      const newContent = yield select(Selectors.posts.newContent);
      const deletedContent = yield select(Selectors.posts.deletedContent);
      const hasGetError = yield select(Selectors.posts.hasGetError);

      if (newContent.length > 0) {
        if (values.mediaSetId === '' || hasGetError) {
          const { itemId } = yield call(() => Narrate.api.media.save(
            {
              title: values.headline,
              media: newContent,
            },
          ));
          values.mediaSetId = itemId;
        }
      }

      if (deletedContent.length > 0) {
        console.log('Deleting old content');
        console.log(deletedContent.map(c => c.itemId));
        yield call(() => Narrate.api.media.removeItems(deletedContent.map(c => c.itemId).filter(x => x !== undefined), values.mediaSetId));
      }

      yield call(() => Narrate.api.accessBlock.save(values, id));
      if (onSuccess) {
        onSuccess();
      }
    },
  });

  uploadContent = routineSaga({
    routine: posts.content.upload,
    *request({ payload: { image, video, assetType = 'photo', mediaSetId, onSuccess } }) {
      if (image) {
        yield mediaImageUpload({
          image,
          assetType,
          mediaSetId,
          onSuccess,
        });
      }

      if (video) {
        // video sagas
        // yield mediaVideoUpload({
        //   video,
        //   mediaSetId,
        //   onSuccess,
        // });
      }
    },
  });

  saveMedia = routineSaga({
    routine: posts.content.save,
    *request({ payload: { content, photosetId } }) {
      return yield call(() => Narrate.api.media.save(content, photosetId));
    },
  });

  updatePhotoSet = routineSaga({
    routine: posts.content.update,
    *request({ payload: { content, photosetId } }) {
      return yield call(() => Narrate.api.media.update(content, photosetId));
    },
  });

  addPhotos = routineSaga({
    routine: posts.content.addPhotos,
    *request({ payload: { content, mediaSetId } }) {
      return yield call(() => Narrate.api.media.savePhotosTo(content, mediaSetId));
    },
  });

  removeItems = routineSaga({
    routine: posts.content.removeItems,
    *request({ payload: { content, mediaSetId } }) {
      return yield call(() => Narrate.api.media.removeItems(content, mediaSetId));
    },
  });

  getMedia = routineSaga({
    routine: posts.content.get,
    *request({ payload: { mediaSetId } }) {
      return yield call(() => Narrate.api.media.get(mediaSetId, { fetchSize: 100 }));
    },
  });

  generateLink = routineSaga({
    routine: posts.content.generateLink,
    *request({ payload: { photosetId, entitlementId } }) {
      return yield call(() => Narrate.api.media.createSecureLink(photosetId, entitlementId));
    },
  });

  // uploadImage = routineSaga({
  //   routine: posts.uploadImage,
  //   *request({ payload: { image, assetType = 'access', onSuccess } }) {
  //     const imageToUpload = {
  //       name: 'access.jpeg',
  //       type: 'image/jpeg',
  //       uri: Platform.OS === 'android' ? image : image.replace('file://', ''),
  //     };

  //     const uploadedImage = yield call(() => Narrate.api.photo.upload(imageToUpload, assetType));

  //     console.log('*** imageToUpload:', imageToUpload);
  //     console.log('*** uploadedImage:', uploadedImage);

  //     return { uploadedImage, onSuccess };
  //   },

  //   *afterSuccess({ uploadedImage, onSuccess }) {
  //     yield put(
  //       posts.updateImage.create({
  //         accessImage: uploadedImage.renditions.access_3x,
  //         accessImageMeta: uploadedImage,
  //       }),
  //     );
  //     if (onSuccess) {
  //       onSuccess();
  //     }
  //   },
  // });
}

const saga = new PostsSaga();

export default function* combined() {
  yield takeEvery(posts.create.trigger, saga.create);
  yield takeEvery(posts.destroy.trigger, saga.destroy);
  yield takeEvery(posts.list.trigger, saga.list);
  yield takeEvery(posts.listByUser.trigger, saga.listByUser);
  yield takeEvery(posts.show.trigger, saga.show);
  yield takeEvery(posts.update.trigger, saga.update);
  yield takeEvery(posts.uploadImage.trigger, saga.uploadImage);
  yield takeEvery(
    [
      posts.update.fulfill,
      // posts.destroy.success,
      posts.create.fulfill,
    ],
    sendTo(posts.list.trigger),
  );
  yield takeEvery(posts.content.upload.trigger, saga.uploadContent);
  yield takeEvery(posts.content.save.trigger, saga.saveMedia);
  yield takeEvery(posts.content.generateLink.trigger, saga.generateLink);
  yield takeEvery(posts.content.get.trigger, saga.getMedia);
  yield takeEvery(posts.content.removeItems.trigger, saga.removeItems);
  yield takeEvery(posts.content.addPhotos.trigger, saga.addPhotos);
  yield takeEvery(posts.content.update.trigger, saga.updatePhotoSet);
  // yield takeEvery(posts.create.success, navigateAway);
}
