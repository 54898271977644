import { createSelector } from 'reselect';

const Selectors = {
  results: state => state.search.results,
  loading: state => state.search.search.loading,
  noResults: state => state.search.search.noResults,
  recents: state => state.search.recents
};

export default Selectors;
