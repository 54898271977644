import { useEffect, useState } from 'react'
import { Helmet } from "react-helmet";
import { orderBy } from 'lodash'
import AccessBlock from 'components/common/AccessBlock'
import { Block, Text, Avatar, Button, Icon, Spinner } from 'ui'
import { event } from 'utils/ga'
import { getProfile } from 'modules/api'
import { Route, Switch, useParams, Redirect, useHistory } from "react-router-dom";
import Checkout from './checkout'
import ConfirmationPage from './confirmation'
import { useDispatch, useSelector } from 'react-redux';
import Selectors from 'selectors';
import Actions from 'actions';


function ProfileIndex({ url, iosUrl }) {
  const [onAppPopupVisible, setPopUp] = useState('true')
  const loggedIn = useSelector(Selectors.auth.loggedIn)
  const selfCheck = useSelector(Selectors.profile.isMe)
  const user = useSelector(Selectors.profile.user)
  const loading = useSelector(Selectors.profile.userLoading)
  const { username } = useParams()
  //const [user, setUser] = useState('')
  const dispatch = useDispatch()
  const { push } = useHistory() 

  useEffect(() => {
    setPopUp(window.localStorage.getItem('popupVisible'))
  }, [])

  useEffect(() => {
    dispatch(Actions.profile.loadProfile.trigger({
      username
    }))
  }, [username, dispatch])

  if (loading) {
    return (
      <Block
        flex={1}
        alignItems="center"
        display="flex"
        justifyContent="center"
        minHeight="calc(100vh)"
        p={5}
      > 
        <Spinner/>
      </Block>
    )
  }

  const {
    photo,
    bio,
    name,
    userId,
    blocks
  } = user

  const orderedBlocks = orderBy(blocks, 'createdAt', 'desc')

  const goToApp = (small) => {
    const now = new Date().valueOf()
    setTimeout(() => {
      if (new Date().valueOf() - now > 100) return
      window.location = `https://9cwbj-alternate.app.link?$username=${username}&$ios_deepview=false`
    }, 50)
    window.location = `narrate://profile/${username}`
    event({
      category: 'Profile',
      action: 'download_click',
      label: small === 'small' ? 'top_button' : 'center_button'
    })
  }

  const hidePopup = () => {
    window.localStorage.setItem('popupVisible', false)
    setPopUp(false)
  }

  const isMe = loggedIn && selfCheck(userId)

  return (
    <>
      <Helmet>
        <title>{`Connect with ${name || ''} (@${username}) on Narrate`}</title>
        <meta
          property="og:title"
          content={`Connect with ${name || ''} (@${username}) on Narrate`}
        />
        <meta property="og:type" content="profile" />
        <meta property="og:url" content={`${url}${username}`} />
        <meta property="og:image" content={photo} />
        <meta
          property="description"
          content={`Connect with ${name || ''} (@${username}) on Narrate`}
        />
        <meta
          property="og:description"
          content={`Connect with ${name || ''} (@${username}) on Narrate`}
        />
      </Helmet>
      <Block
        pb={{
          mobile: 10,
          desktop:0
        }}
      >
        <Block
          flex={1}
          col
          bg="white"
          alignItems={{
            mobile: 'flex-start',
            desktop: 'center'
          }}
          justifyContent="center"
        >
          <Block
            row
            flex={1}
            px={3}
            py={{
              mobile: 3,
              desktop: 10
            }}
            bg="white"
          >
            <Avatar
              size={140}
              mr={3}
              photo={photo}
              display={{
                mobile: 'none',
                desktop: 'inline-flex'
              }}
            />
            <Avatar
              size={100}
              mr={3}
              photo={photo}
              display={{
                mobile: 'inline-flex',
                desktop: 'none'
              }}
            />
            <Block flex={1} col justifyContent="center">
              <Block row>
              <Text
                variant="h6"
                mb={1}
                style={{
                  fontWeight:800
                }}
              >
                {`@${username.toLowerCase()}`}
              </Text>
              </Block>
              <Block row>
                <Text
                  variant="subhead1"
                  fontSize={{
                    mobile: 16
                  }}
                  mb={1}
                >
                  {name}
                </Text>
              </Block>
              <Block row>
                <Text variant="subhead4" maxWidth={300} mb={4} fontSize={{
                  mobile: 14
                }}>
                  {bio}
                </Text>
              </Block>
              <Button disabled={isMe} iconLeft="plane" iconColor="navy" text="Message" variant="outline" onClick={() => {
                if(loggedIn) {
                  push(`/conversations/${userId}/`)
                } else {
                  dispatch(Actions.profile.toggleRegister(true))
                  dispatch(Actions.auth.showModal({
                    visible: true, 
                    userId
                  }))
                }
              }} />
            </Block>
          </Block>
        </Block>
        <Block as="article" maxWidth="1200px">
          <Block
            as="section"
            display="grid"
            gridTemplateColumns={{
              mobile: '1fr',
              table: '1fr 1fr 1fr',
              desktop: orderedBlocks?.length > 0 ? '1fr 1fr 1fr' : '1fr'
            }}
            gridGap={3}
            gridAutoRows="minmax(150px, auto)"
          >
            {orderedBlocks?.length > 0 && !loading ? (
              orderedBlocks.map((block) => (
                <AccessBlock key={block.itemId} user={user} item={block} />
              ))
            ) : (
              <Block
                display="flex"
                flex={1}
                col
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                px={10}
              >
                <Block
                  width={50}
                  height={50}
                  borderWidth="2px"
                  borderStyle="solid"
                  borderColor="navy"
                  borderRadius="100%"
                  display="flex"
                  col
                  justifyContent="center"
                  alignItems="center"
                  textAlign="center"
                  mb={3}
                >
                  <Icon
                    name="caring-heart"
                    size={24}
                    color="navy"
                    style={{
                      position: 'relative',
                      left: '1px'
                    }}
                  />
                </Block>
                <Text variant="h6" color="navy">
                  No Access or Experiences Available Yet
                </Text>
              </Block>
            )}
          </Block>
        </Block>
        {/* (onAppPopupVisible === 'true' || onAppPopupVisible === null) */ false && (
          <Block
            position="fixed"
            bottom={0}
            left={0}
            right={0}
            bg="navy"
            px={4}
            py={4}
            justifyContent="center"
            alignItems="center"
            flex={1}
            display="flex"
            textAlign="center"
            gradient
            col
          >
            <Block bg="white" p={2} borderRadius={120}>
              <Icon color="brand" size={26} name="logo" />
            </Block>
            <Block my={3}>
              <Text variant="subhead1" color="white" mb={1}>
                Narrate is better on the app.
              </Text>
              <Text variant="subhead2" color="white">
                Direct Messaging. Become a creator. Start earning. Get Paid.
                Only on the app.
              </Text>
              <Button
                mt={4}
                mb={1}
                size="medium"
                variant="secondary"
                block
                onClick={() => goToApp()}
                text="Get the App"
              />
              <Text
                onClick={() => hidePopup()}
                color="white"
                variant="subhead2"
              >
                Not Now
              </Text>
            </Block>
          </Block>
        )}
      </Block>
    </>
  )
}

function Profile(){
  return (
    <Switch>
      <Route exact path="/:username" component={ProfileIndex} />
      <Route path={`/:username/:item/checkout`} component={Checkout} />
      <Route path={`/:username/:item/confirmation`} component={ConfirmationPage} />
      <Redirect exact from="/:username/:item" to="/:username/:item/checkout" />
    </Switch>
  )
}

export default Profile
