import { createActions } from 'redux-actions';
import { DefaultRoutine } from 'utils';

const Actions = createActions({
  PAYMENT: {
    PAYMENT_INTENT: DefaultRoutine,
    PAYMENT_CONFIRM: DefaultRoutine,
    COMPLETE_PAYMENT: DefaultRoutine,
    CLEAR_INTENT: undefined,
    PAYMENT_METHODS: DefaultRoutine,
    PAYMENT_CHOICE: undefined
  },
});

export default Actions;
