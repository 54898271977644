import React, { memo } from 'react'
import styled, {css} from 'styled-components'
import { COMMON, get } from './constants'

export const glyphMap = {
  'arrow-left': '\ue900',
  'arrow-right': '\ue901',
  'audio-off': '\ue902',
  'audio-on': '\ue903',
  'caring-heart': '\ue904',
  'chat-convo': '\ue905',
  'chevron-down': '\ue909',
  'chevron-left': '\ue906',
  'chevron-right': '\ue908',
  'chevron-up': '\ue907',
  close: '\ue936',
  'credit-card-hand': '\ue90b',
  'credit-card': '\ue90c',
  dollar: '\ue90d',
  'dots-horizontal': '\ue90e',
  'dots-vertical': '\ue90f',
  download: '\ue910',
  edit: '\ue911',
  hamburger: '\ue912',
  'hand-coins': '\ue913',
  heart: '\ue914',
  hot: '\ue915',
  inbox: '\ue916',
  link: '\ue917',
  lock: '\ue918',
  logo: '\ue919',
  message: '\ue91a',
  'piggy-bank': '\ue91c',
  reply: '\ue91e',
  share: '\ue91f',
  star: '\ue920',
  trash: '\ue921',
  typing: '\ue922',
  unlock: '\ue923',
  'chat-convo-fill': '\ue924',
  'camera-filled': '\ue925',
  camera: '\ue927',
  'user-fill': '\ue926',
  user: '\ue928',
  cog: '\ue929',
  plus: '\ue92a',
  search: '\ue92b',
  alert: '\ue92c',
  megaphone: '\ue92d',
  minus: '\ue92e',
  upload: '\ue92f',
  'flip-camera': '\ue934',
  block: '\ue931',
  'add-new': '\ue932',
  'plus-line': '\ue933',
  ex: '\ue936',
  'small-close': '\ue937',
  refresh: '\ue935',
  boost: '\ue938',
  image: '\ue939',
  check: '\ue93a',
  'view-all': '\ue93b',
  send: '\ue93c',
  plane: '\ue93d',
  settings: '\ue940',
  burger: '\ue912',
  bolt: '\ue950',
  'bolt-slash': '\ue951',
  flash: '\ue952',
  'keyboard-show': '\ue94e',
  'keyboard-hide': '\ue94f',
  code: '\ue947',
  scenery: '\ue944',
  eye: '\ue954',
  'times-circle': '\ue955',
  'play-circle': '\ue956',
  'pause-cirlce': '\ue957',
  'shield-check': '\ue958',
  play: '\ue959',
  pause: '\ue95a',
  'shield-plus': '\ue95b',
  'arrow-circle-up': '\ue95c',
  'cloud-upload': '\ue95d',
  'sign-in': '\ue95e',
  'sign-out': '\ue95f',
  'wallet': '\ue960'
}

const Icon = styled.span`
  font-family: ${get('fonts.icons')};
  font-size: ${(props) => props.size}px;
  line-height: ${(props) => props.size}px;
  user-select:none;
  ${props => props.onClick && css`
    cursor:pointer;
  `}
  ${COMMON}
`

Icon.defaultProps = {
  size: 14,
  color: 'navy',
}

export default memo((props) => {
  const { name } = props
  return (
    <Icon {...props} allowFontScaling={false}>
      {glyphMap[name]}
    </Icon>
  )
})
