import { Block, Text, Avatar } from 'ui'
import {truncate} from 'utils'

const MessageRow = ({user, onClick}) => {
  return (
    <li onClick={onClick} key={user?.userId}>
      <Avatar size={70} photo={user?.photo} iconColor="greyLight" />
      <Block flex={1} col>
        <Text as="h4" variant="subhead2" ml={3} mb={1} color="navy">{`@${user?.username}`}</Text>
        {user?.bio && <Text ml={3}>{truncate(user?.bio)}</Text>}
      </Block>
    </li>
  )
}

export default MessageRow