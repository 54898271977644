import * as styledSystem from 'styled-system'
import systemPropTypes from '@styled-system/prop-types'
import themeGet from '@styled-system/theme-get'
import theme from './theme'

const { get: getKey, compose } = styledSystem

export const get = (key) => themeGet(key, getKey(theme, key))

export const TYPOGRAPHY = styledSystem.typography
export const LAYOUT = styledSystem.layout
export const POSITION = styledSystem.position
export const FLEX = styledSystem.flexbox
export const GRID = styledSystem.grid
export const BORDER = compose(styledSystem.border, styledSystem.shadow)
export const COMMON = compose(
  styledSystem.space,
  styledSystem.color,
  styledSystem.display,
)

COMMON.propTypes = {
  ...systemPropTypes.space,
  ...systemPropTypes.color,
}

BORDER.propTypes = {
  ...systemPropTypes.border,
  ...systemPropTypes.shadow,
}

TYPOGRAPHY.propTypes = {
  ...systemPropTypes.typography,
}

TYPOGRAPHY.propTypes = systemPropTypes.typography
LAYOUT.propTypes = systemPropTypes.layout
POSITION.propTypes = systemPropTypes.position
FLEX.propTypes = systemPropTypes.flexbox
GRID.propTypes = systemPropTypes.grid
