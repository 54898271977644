import {useEffect, useState, useRef} from 'react'
import { Route, Switch, useParams, useRouteMatch, Redirect, Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from 'components/common/Footer'
import Header from 'components/common/Header'
import { Block, Text, Avatar, Button, Icon, Spinner, Modal, getStyle } from 'ui'
import { event } from 'utils/ga'
import { useSelector, useDispatch } from 'react-redux';
import styled, {css} from 'styled-components'
import Selectors from 'selectors';
import Actions from 'actions';
import ConvoList, {List} from 'components/conversation/MessageList'
import Conversation from './Conversation'
import MessageRow from 'components/conversation/MessageRow'
import { isMobile } from "react-device-detect";
import {truncate} from 'utils';


const MessageWrapper = styled.article`
  max-width:1200px;
  padding: 30px 15px;

  @media (min-width: 320px) and (max-width : 736px)
  {
    padding: 0;
    & > section > * {
      &:first-child {
        display:none;
      }
    }
  }


  @media (min-width: 736px) and (max-width: 1024px)
  {
    padding: 0;
    & > section > * {
      &:first-child {
        display:none;
      }
    }
  }
`

const SearchInputWrapper = styled.div`
  flex:1;
  background-color: ${getStyle('colors.white')};
  border:1px solid ${getStyle('colors.greyLight')};
  transition: all 0.4s;
  border-radius:24px;
  padding-right: 0;
  display:flex;
  align-items:center;
  padding-left:10px;

  &:focus-within {
    border-color: ${getStyle('colors.brand')};
  }

  input {
    font-family: ${getStyle('fonts.body')};
    font-weight:500;
    padding: 10px;
    color: ${getStyle('colors.navy')};
    outline:none;
    border:none;
    flex:1;
    background:none;
    &::placeholder {
      color: ${getStyle('colors.grey')};
    }
  }
`

const SearchHeader = styled(Block)`

  border-bottom:1px solid ${getStyle('colors.greyLighter')};
  display:grid;
  grid-template-columns:70px 1fr 70px;
  align-items:center;
  justify-content: center;
  position:relative;
  text-align:center;
  background:#fff;
  @media (min-width: 320px) and (max-width : 736px) {
    position:fixed;
    right:0;
    left:0;
    top:0;
    z-index:999;
    height:60px;
  }
`

const SearchContainer = styled(Block)`
  align-items:center;
  border-bottom:1px solid ${getStyle('colors.greyLighter')};

  @media (min-width: 320px) and (max-width : 736px) {
    padding-top:72px;
  }
`

const MessageListContainer = styled(Block)`
  grid-template-rows: 80px 1fr;
  @media (min-width: 320px) and (max-width : 736px) {
    grid-template-rows: auto;
  }
`

function MessageList({...props}) {
  const dispatch = useDispatch()
  const data = useSelector(Selectors.conversations.inbox)
  const loading = useSelector(Selectors.conversations.inboxLoading)
  const searchVisible = useSelector(Selectors.conversations.newMessageVisible)
  const searchResults = useSelector(Selectors.search.results)
  const noResults = useSelector(Selectors.search.noResults)
  const currentUserId = useSelector(Selectors.auth.userId)
  const loadingSearch = useSelector(Selectors.search.loading)
  const recentSearches = useSelector(Selectors.search.recents)
  const [query, setQuery] = useState('')
  const { push } = useHistory()
  const ref = useRef(null)
  const goToConvo = user => {
    const { userId } = user
    dispatch(Actions.conversations.newMessageToggle(false))
    dispatch(Actions.search.addToRecent(user))
    push(`/conversations/${userId}`)
  }

  useEffect(()=>{
    const searchInput = ref?.current
    if(searchVisible) {
      searchInput?.focus()
    }
  }, [searchVisible])

  return (
    <MessageListContainer
      display="grid"
      {...props}
    >
      {searchVisible ? (
        <>
          <SearchHeader>
            <Block>
              <Icon name="chevron-left" color="navy" size={28} onClick={()=> dispatch(Actions.conversations.newMessageToggle(false))} />
            </Block>
            <Text variant="h5" color="navy">new message</Text>
            <Block />
          </SearchHeader>
          <Block
            display="grid"
            gridTemplateRows={{
              mobile:"auto 1fr",
              desktop:"auto 1fr"
            }}
          >
            <Block>
              <SearchContainer row px={4} py={3}>
                <SearchInputWrapper>
                  <Icon name="search" size={20} color="grey" />
                  <input
                    type="text" 
                    placeholder="Search People"
                    value={query}
                    ref={ref}
                    onChange={e => {
                      setQuery(e.target.value)
                      dispatch(Actions.search.search.trigger({
                        query
                      }))
                    }} 
                  />
                </SearchInputWrapper>
                {query && query.length > 0 && <Text ml={2} onClick={() => {
                  setQuery('')
                  dispatch(Actions.search.reset())
                }}>cancel</Text>}
              </SearchContainer>
              {query.length === 0 && (
                <>
                  <Text variant="h6" ml={4} color="greyDark" mt={4}>Recent</Text>
                  {recentSearches?.length > 0 && searchResults?.length === 0 && (
                    <List recent>
                      {recentSearches.map(user => (
                        <MessageRow 
                          user={user}
                          about
                          onClick={() => goToConvo(user)}
                        />
                      ))}
                    </List>
                  )}
                </>
              )}
            </Block>
            {searchResults?.length > 0 && (
              <List search>
                {searchResults.map(user => (
                  <MessageRow 
                    user={user}
                    onClick={() => goToConvo(user)}
                  />
                ))}
              </List>
            )}
            {!loadingSearch && noResults && searchResults?.length === 0 && (
              <Block>
                <Text p={4} color="grey">No Results found.</Text>
              </Block>
            )}
          </Block>
        </>
      ) : (
      <>
        <Block
          borderBottom={1}
          display="grid"
          borderBottomColor="greyLighter"
          gridTemplateColumns="70px 1fr 70px"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          bg="white"
          zIndex={999}
          position={{
            mobile: 'fixed',
            desktop: 'static'
          }}
          height={{
            mobile: 60,
            desktop: 80
          }}
          width="100%"
        >
          <Block/>
          <Text variant="h5" color="navy">messages</Text>
          <Block>
            <Icon 
              name="plane"
              color="navy"
              size={25}
              onClick={() => dispatch(Actions.conversations.newMessageToggle(true))} />
          </Block>
        </Block>
        <ConvoList messages={data} loading={loading} />
      </>
      )}
    </MessageListContainer>
  )
}

function NotSelected(){
  const dispatch = useDispatch()
  const searchVisible = useSelector(Selectors.conversations.newMessageVisible)
  return (
    <>
      <Block
        flex={1}
        row
        alignItems="center"
        justifyContent="center"
        height="100%"
        display={{
          mobile:'none',
          desktop: 'flex'
        }}
      >
        <Block 
          flex={1} 
          col
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          {searchVisible ? (
            <Text variant="h6" color="grey">Search for a user and send them a message.</Text>
          ) : (
            <>
              <Text variant="h6" mb={1}>You don't have a message selected</Text>
              <Text variant="subhead2" color="greyDark">Choose one from your existing messages, or start a new one.</Text>
              <Block mt={4}>
                <Button text="New Message" size="medium" onClick={()=> {
                  dispatch(Actions.conversations.newMessageToggle(true))
                }} />
              </Block>
            </>
          )}
        </Block>
      </Block>
      <MessageList
        display={{
          mobile:'grid',
          desktop: 'none'
        }}
      />
    </>
  )
}

function Messages(){
  const { photo, username, name } = useSelector(Selectors.profile.myProfile)
  const data = useSelector(Selectors.conversations.inbox)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(Actions.conversations.loadList.trigger())
  }, [dispatch])

  // useEffect(() => {
  //   if(isMobile) {
  //     document.body.style.overflow = 'hidden'
  //   }
  //   return () => {
  //     document.body.style.overflow = null
  //   }
  // },[])

  return (
    <>
      <Helmet>
        <title>My Conversations - Narrate</title>
        <meta
          property="og:title"
          content="My Conversations - Narrate"
        />
        <meta
          property="description"
          content="My Conversations - Narrate"
        />
        <meta
          property="og:description"
          content="My Conversations - Narrate"
        />
      </Helmet>
      <MessageWrapper listVisible={false}>
        <Block
          as="section"
          display="grid"
          borderRadius={{
            mobile:0,
            desktop:6
          }}
          borderWidth={{
            mobile: 0,
            desktop:'1px'
          }}
          borderStyle={{
            desktop: 'solid'
          }}
          borderColor="greyLighter"
          bg="white"
          gridTemplateColumns={{
            mobile: '1fr',
            tablet: '200px 1fr',
            desktop: '400px 1fr'
          }}
          gridGap={0}
        >
          <MessageList />
          <Block 
            borderLeftWidth={{
              mobile: 0,
              desktop: 1
            }}
            borderLeftStyle={{
              mobile:'solid',
              desktop: 'solid'
            }}
            borderLeftColor="greyLighter" 
            minHeight={{
              mobile: '100vh',
              desktop: '80vh'
            }}
          >
            <Switch>
              <Route exact path="/conversations" component={NotSelected} />
              <Route
                exact
                path="/conversations/:userId" 
                component={Conversation}
              />
              <Route
                exact
                path="/conversations/:initialUserId/:participantUserId/:userId" 
                component={Conversation}
              />
            </Switch>
          </Block>
        </Block>
      </MessageWrapper>
    </>
  )
}

export default Messages