import styled, { css } from 'styled-components'

const OverlayBG = styled.div`
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background:rgba(0,0,0,0.2);
  z-index: 8888;
  transition: all 0.4s;
  opacity:0;
  pointer-events: none;
  ${props => props.visible && css`
    opacity:1;
    pointer-events: auto;
  `}
`

const Overlay = ({visible, onClick}) => {
  return (
    <OverlayBG visible={visible} onClick={onClick} />
  )
}

export default Overlay