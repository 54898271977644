export default {
  white: '#FFFFFF',
  grey: ['#F4F4F4', '#e7e7e7', '#D1D2DA', '#BABDC8', '#757A91'],
  black: '#000000',
  navy: ['#1A2249', '#606687'],
  brand: ['#3AE28C', '#3EE992', '#2BBC72', '#95DDB8'],
  danger: ['#EB425C', 'rgba(235, 66, 92, 0.06)'],
  overlay: 'rgba(0,0,0,0.75)',
  blue: '#4a90e2',
  none: 'transparent',
  translucent: 'rgba(255,255,255,0.95)',
};
