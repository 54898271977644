import _ from 'lodash';
import { handleActionsImmer } from 'utils';
import Actions from 'actions';

export const initialState = {
  results: [],
  search: {
    loading: false,
    noResults: false,
  },
  recents: []
};

const { search: { search, reset, addToRecent } } = Actions;

const Reducer = handleActionsImmer(
  {
    [search.trigger]: draft => {
      draft.search.loading = true;
    },
    [search.success]: (draft, { users }) => {
      if (users.length > 0) {
        draft.results = users;
      } else {
        draft.results = users;
        draft.search.noResults = true;
      }
      draft.search.loading = false;
    },
    [reset]: draft => {
      draft.results = [];
      draft.search.noResults = false;
      draft.search.loading = false;
    },
    [addToRecent]: (draft, data) => {
      draft.recents = [...draft.recents, data]
    }
  },
  initialState,
);

export default Reducer;
