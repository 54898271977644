import _ from 'lodash';
import { handleActionsImmer } from 'utils';
import Actions from 'actions';

const form = {
  values: null, // TODO don't think this is being used, but maybe intended to be a draft post?
  error: null,
  submitting: false,
  accessImage: null,
  accessImageMeta: null,
  loading: false,
};

export const initialState = {
  create: form,
  current: {
    error: null,
    loading: false,
    object: null,
  },
  list: {
    error: null,
    index: {},
    loading: false,
    page: 1,
    pages: 0,
    total: 0,
  },
  publicList: {
    error: null,
    index: {},
    loading: false,
    page: 1,
    pages: 0,
    total: 0,
  },
  update: form,
  uploading: false,
  pendingContent: [],
  selectedContent: [],
  deletedContent: [],
  newContent: [],
  saving: false,
  hasGetError: false,
  contentLink: '',
  contentLinkLoading: false,
  contentLoading: false,
  progress: 0,
  contentUploading: {
    count: 0,
    status: false,
  },
};

const {
  posts: {
    create,
    destroy,
    list,
    listByUser,
    show,
    updateImage,
    update,
    reset,
    uploadImage,
    content,
    saving,
  },
} = Actions;

const Reducer = handleActionsImmer(
  {
    [Actions.auth.logout.success]: () => initialState,
    [list.trigger]: draft => {
      draft.list.error = null;
    },
    [list.request]: draft => {
      draft.list.loading = true;
    },
    [list.success]: (draft, { Count: total, Items: results }) => {
      const page = 1; // TODO

      if (page === 1) {
        draft.list.index = results;
        draft.list.total = total;
      } else {
        draft.list.index = _.merge(draft.list.index, results);
      }
    },
    [list.failure]: (draft, { error }) => {
      draft.list.error = error;
      draft.list.loading = false;
    },
    [list.fulfill]: draft => {
      draft.list.loading = false;
    },

    [listByUser.trigger]: draft => {
      draft.publicList.error = null;
    },
    [listByUser.request]: draft => {
      draft.publicList.loading = true;
    },
    [listByUser.success]: (draft, { Count: total, Items: results }) => {
      const page = 1; // TODO

      if (page === 1) {
        draft.publicList.index = results;
        draft.publicList.total = total;
      } else {
        draft.publicList.index = _.merge(draft.publicList.index, results);
      }
      draft.publicList.loading = false;
    },
    [listByUser.failure]: (draft, { error }) => {
      draft.publicList.error = error;
      draft.publicList.loading = false;
    },
    [listByUser.fulfill]: draft => {
      draft.publicList.loading = false;
    },

    [show.trigger]: draft => {
      draft.current.error = null;
    },
    [show.request]: draft => {
      draft.current.loading = true;
    },
    [show.success]: (draft, data) => {
      draft.current.object = data;
      draft.current.loading = false;
    },
    [show.failure]: (draft, { error }) => {
      draft.current.error = error;
      draft.current.loading = false;
    },
    [show.fulfill]: draft => {
      draft.current.loading = false;
    },
    [create.trigger]: (draft, data) => {
      draft.create.accessImage = data.accessImage;
    },
    [create.request]: draft => {
      draft.create.loading = true;
    },
    [create.fulfill]: draft => {
      draft.create.loading = false;
    },
    [saving]: (draft, bool) => {
      draft.saving = bool;
    },
    [update.request]: draft => {
      draft.update.loading = true;
    },
    [update.fulfill]: draft => {
      draft.update.loading = false;
    },
    [updateImage.create]: (draft, data) => {
      draft.create.accessImage = data.accessImage;
      draft.create.accessImageMeta = data.accessImageMeta;
    },
    [updateImage.update]: (draft, data) => {
      draft.update.accessImage = data.accessImage;
      draft.update.accessImageMeta = data.accessImageMeta;
    },
    [destroy.trigger]: (draft, { id }) => {
      draft.list.index = draft.list.index.filter(post => post.id !== id);
    },
    [destroy.success]: draft => {
      draft.list.loading = false;
    },
    [uploadImage.trigger]: draft => {
      draft.uploading = true;
    },
    [uploadImage.fulfill]: draft => {
      draft.uploading = false;
    },
    [reset]: draft => {
      draft.current.loading = false;
      draft.list.loading = false;
      draft.create.loading = false;
      draft.update.loading = false;
      draft.publicList.loading = false;
    },
    [content.toggle]: (draft, { item }) => {
      draft.selectedContent = draft.selectedContent.includes(item)
        ? draft.selectedContent.filter(i => i.uri !== item.uri)
        : [item, ...draft.selectedContent];
    },
    [content.add]: (draft, { item }) => {
      item.uploading = false;
      draft.selectedContent = [item, ...draft.selectedContent];
      if (item.itemId) {
        draft.deletedContent = draft.deletedContent.filter(i => i.itemId !== item.itemId);
      } else {
        draft.deletedContent = draft.deletedContent.filter(i => i.assetId !== item.assetId);
      }
    },
    [content.remove]: (draft, { item }) => {
      if (item.itemId) {
        draft.newContent = draft.newContent.filter(i => i.itemId !== item.itemId);
        draft.deletedContent = [...draft.selectedContent.filter(i => i.itemId === item.itemId), ...draft.deletedContent];
        draft.selectedContent = draft.selectedContent.filter(i => i.itemId !== item.itemId);
      } else {
        draft.newContent = draft.newContent.filter(i => i.assetId !== item.assetId);
        draft.deletedContent = [...draft.selectedContent.filter(i => i.assetId === item.assetId), ...draft.deletedContent];
        draft.selectedContent = draft.selectedContent.filter(i => i.assetId !== item.assetId);
      }
    },
    [content.load]: (draft, data) => {
      draft.selectedContent = data;
    },
    [content.get.trigger]: draft => {
      draft.contentLoading = true;
    },
    [content.get.success]: (draft, { mediaSetItem }) => {
      draft.selectedContent = _.sortBy(mediaSetItem?.media, item => -_.get(item, 'createdAt', 0)) || [];
      draft.hasGetError = false;
      draft.contentLoading = false;
    },
    [content.get.fullfil]: draft => {
      draft.hasGetError = false;
    },
    [content.get.failure]: (draft, { error }) => {
      if (error.Error === 'Not found') {
        draft.hasGetError = true;
      }
      draft.contentLoading = false;
    },
    [content.uploadStatus]: (draft, { status, count }) => {
      draft.contentUploading = {
        count,
        status,
      };
    },
    [content.uploadProgress]: (draft, { progress }) => {
      draft.progress = progress;
    },
    [content.clear]: draft => {
      draft.selectedContent = [];
      draft.newContent = [];
      draft.deletedContent = [];
    },
    [content.upload.trigger]: (draft, { image, video }) => {
      draft.selectedContent = draft.selectedContent.map(item => {
        if (item.assetId === image?.assetId || item.assetId === video?.assetId) {
          return {
            ...item,
            pending: false,
            progress: 0,
            uploading: true,
          };
        }

        return item;
      });

      draft.saving = true;
    },
    [content.upload.success]: (draft, { original, uploadedImage, uploadedVideo, originalURI }) => {
      const uploaded = draft.selectedContent.filter(i => i.assetId !== original.assetId);
      const item = {
        ...original,
        ...(uploadedImage || uploadedVideo),
        uri: uploadedImage ? uploadedImage?.url : originalURI,
        isUploaded: true,
        uploading: false,
        pending: false,
        progress: 100,
      };

      draft.saving = false;

      draft.selectedContent = [item, ...uploaded];
      draft.newContent = [item, ...draft.newContent];
    },
    [content.upload.failure]: (draft, image) => {
      const currentContent = draft.selectedContent.filter(i => i.assetId !== image.assetId);
      image.failed = true;
      draft.selectedContent = [image, ...currentContent];
      draft.saving = false;
      draft.contentUploading = { status: false };
    }
  },
  initialState,
);

export default Reducer;
