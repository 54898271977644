import styled from 'styled-components'
import { get, COMMON } from './constants'

const Card = styled.div`
  background-color: ${get('colors.white')};
  border: 1px solid ${get('colors.greyLighter')};
  border-radius: ${get('radii.2')}px;
  flex-wrap: nowrap;
  overflow: hidden;
  margin-bottom: ${get('space.3')}px;
  ${(props) => props.style}
  transition:all 0.4s;
  cursor:pointer;
  ${COMMON}
  &:hover {
    box-shadow: 0 20px 20px rgba(0,0,0,0.08);
  }
`

const CardBody = styled.div`
  padding: 15px;
  ${(props) => props.style}
`

export {
  Card,
  CardBody,
}
