/* eslint-disable */

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import _ from 'lodash'
import produce from 'immer'
import { useRef } from 'react';

dayjs.extend(relativeTime)

export function inputCurrency(currency) {
  return currency.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}

export const formatAmount = (amount = 0) => {
  const amountString = (amount / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  })
  return amountString.replace('.00', '')
}

export const pluralize = (count, noun, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`;

export function timeAgo(time) {
  return dayjs(time).fromNow()
}

export function dateFormat(time, format) {
  return dayjs(time).format(format)
}

export function fontStack(fonts) {
  return fonts.map(font => (font.includes(' ') ? `"${font}"` : font)).join(', ')
}

export function truncate(str, num = 60) {
  if (str?.length <= num) {
    return str
  }
  return str?.slice(0, num) + '...'
}


export function fontFace(
  name,
  src,
  fontWeight = 'normal',
  fontStyle = 'normal'
) {
    return `
      @font-face{
        font-family: "${name}";
        src: url(${require(`../assets/fonts/${src}.woff2`).default}) format("woff2");
        font-style: ${fontStyle};
        font-weight: ${fontWeight};
      }`
}

const reducers = {
  request: path => draft => {
    const routineDraftState = _.get(draft, path);
    routineDraftState.loading = true;
    routineDraftState._persist = false;
  },
  success: path => draft => {
    const routineDraftState = _.get(draft, path);
    routineDraftState.failed = false;
    routineDraftState.loadedAt = Date.now();
    delete routineDraftState.error;
  },
  failure: path => (draft, { error }) => {
    const routineDraftState = _.get(draft, path);
    routineDraftState.failed = true;
    routineDraftState.error = error;
  },
  fulfill: path => draft => {
    const routineDraftState = _.get(draft, path);
    routineDraftState.loading = false;
  },
};

export function handleActionsImmer(actionsMap, defaultState, routineMap = {}) {
  // one can pass in actions that look like this ACTION_NAME||ACTION_NAME2
  // these should be decomposed one time to ease lookup in the future. Without
  // this logic, this doesn't work with combineActions
  const singleActionsMap = Object.keys(actionsMap).reduce((map, actionName) => {
    const actionNames = actionName.split('||');
    for (const parsedActionName of actionNames) {
      map[parsedActionName] = actionsMap[actionName];
    }
    return map;
  }, {});

  const routineActionsMap = {};
  _.each(routineMap, (routine, path) => {
    routineActionsMap[routine.request] = reducers.request(path);
    routineActionsMap[routine.success] = reducers.success(path);
    routineActionsMap[routine.failure] = reducers.failure(path);
    routineActionsMap[routine.fulfill] = reducers.fulfill(path);
  });

  return (state = defaultState, { type, payload }) => produce(state, draft => {
    const routineAction = routineActionsMap[type];
    if (routineAction) {
      routineAction(draft, payload);
    }

    const action = singleActionsMap[type];
    return action && action(draft, payload);
  });
}

export function payloadActions(...actionNames) {
  const actions = {};

  actionNames.forEach(actionName => {
    actions[actionName] = args => args;
  });

  return actions;
}

export const DefaultRoutine = payloadActions(
  'TRIGGER',
  'REQUEST',
  'SUCCESS',
  'FAILURE',
  'FULFILL',
  'CLEAR',
);


export const useComponentWillMount = func => {
  const willMount = useRef(true);

  if (willMount.current) {
    func();
  }

  willMount.current = false;
};

export const inapp = navigator.userAgent.match(/NarrateApp/i)
export const isMobile = navigator.userAgent.match(/iPhone|iPad|iPod|Android/i)