import React, {useRef, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Block, Text, Avatar, Icon, getStyle, colors } from 'ui'

const System = styled.aside`
  background-color: ${getStyle('colors.navy')};
  margin:60px 60px 45px;
  position:relative;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  padding:10px 40px;
  text-align:center;
  border-radius: ${props => Math.round(props.radius)}px;
  p > span > span {
    color: ${getStyle('colors.brand')};
  }
  & > div {
    position:absolute;
    top:-40px;
    & > * {
      position:relative;
    }
  }

  @media (min-width: 320px) and (max-width : 736px)
  {
    margin:45px 30px 23px;
    padding: 10px 20px;
  }

`

const SystemMessage = ({ currentMessage }) => {
  
  const { data: { users: {them, me}, users } } = currentMessage
  const ref = useRef()
  const [radius, setRadius] = useState(65)

  useEffect(() => {
    const height = ref.current.offsetHeight
    setRadius(height / 2)
  }, [])

  const wrapUsername = currentMessage?.text.replace(/@([\w_.]+)/g, "<span>@$1</span>")
  const text = wrapUsername.replace(/\s([\$]\d+([\.,]\d{2}?))|(\d+([\.,]\d{2}?)[\$])\s?/g, "<span> $1</span>")

  return (
    <System ref={ref} radius={radius}>
      {users && (
        <div>
          <Avatar 
            photo={them?.photo}
            size={them?.photo ? 50 : 54}
            iconColor="greyLight" 
            style={{
              left: 10
            }}
          />
          <Avatar 
            photo={me?.photo}
            size={50}
            iconColor="greyLight"
            style={{
              left: -10,
              zIndex: 2
            }}
          />
        </div>
      )}
      <Text
        variant="subhead2"
        center
        color="white"
        py={3}
        // parse={[
        //   {
        //     pattern: /(^|[^\w])@([\w_.]+)/g,
        //     style: styles.systemMessage.username,
        //     onPress: () => false,
        //   },
        //   {
        //     pattern: /\$\d+(,\d+)*(?:\.\d+)?/g,
        //     style: styles.systemMessage.username,
        //     onPress: () => false,
        //   },
        // ]}
      >
        <span dangerouslySetInnerHTML={{__html: text}}/>
      </Text>
    </System>
  )
}

export default SystemMessage