import { createSelector } from 'reselect';
import _ from 'lodash';

const Selectors = {
  profile: state => state.posts.list,
  publicProfile: state => state.posts.publicList,
  posts: state => state.posts,
  updateLoading: state => state.posts.update.loading,
  uploadingImage: state => state.posts.uploading,
  selectedContent: state => state.posts.selectedContent,
  newContent: state => state.posts.newContent,
  deletedContent: state => state.posts.deletedContent,
  pendingContent: state => state.posts.pendingContent,
  saving: state => state.posts.saving,
  contentLink: state => state.posts.contentLink,
  hasGetError: state => state.posts.hasGetError,
  contentLoading: state => state.posts.contentLoading,
  progress: state => state.posts.progress,
  contentUploading: state => state.posts.contentUploading
};

Selectors.create = createSelector(
  Selectors.posts,
  posts => posts.create,
);

Selectors.index = createSelector(
  Selectors.profile,
  list => _.reduce(list.index, (index, item) => Object.assign(index, { [item.id]: item }), {}),
);

Selectors.sortedProfile = createSelector(
  Selectors.profile,
  list => ({
    ...list,
    index: _.sortBy(list.index, post => -_.get(post, 'createdAt', 0)),
  }),
);

Selectors.sortedPublicProfile = createSelector(
  Selectors.publicProfile,
  list => ({
    ...list,
    index: _.sortBy(list.index, post => -_.get(post, 'createdAt', 0)),
  }),
);

export default Selectors;
