import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import PropTypes from 'prop-types'
import { fontFace } from 'utils'
import { TYPOGRAPHY, COMMON, get } from './constants'

const Container = styled.div`
  height:100%;
`

const GlobalStyle = createGlobalStyle`
  html { box-sizing: border-box; height:100%; }
  *, *:before, *:after { box-sizing: inherit; }
  body { 
    margin: 0;
    background-color: #FAFAFA;
    -webkit-font-smoothing: antialiased; 
    -moz-osx-font-smoothing: grayscale; 
    font-family: ${get('fonts.body')};
    height:100%;
  }
  #root {
    height:100%;
  }
  ${fontFace('Gilroy', 'Gilroy-Regular')}
  ${fontFace('Gilroy', 'Gilroy-Medium', 500)}
  ${fontFace('Gilroy', 'Gilroy-SemiBold', 600)}
  ${fontFace('Gilroy', 'Gilroy-Bold', 'bold')}
  ${fontFace('NarrateIcons', 'NarrateIcons')}
  table { border-collapse: collapse; }
  pre {white-space: pre-wrap;}
  a {text-decoration:none;}

  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select:focus,
  textarea {
    font-size: 16px;
  }
`

const Base = props => {
  const { children, ...rest } = props
  return (
    <Container {...rest}>
      <GlobalStyle />
      {children}
    </Container>
  )
}

Base.propTypes = { children: PropTypes.node }

const BaseStyles = styled(Base)`
  ${TYPOGRAPHY};
  ${COMMON};
`

BaseStyles.propTypes = {
  ...TYPOGRAPHY.propTypes,
  ...COMMON.propTypes,
  theme: PropTypes.object,
  children: PropTypes.node
}
export default BaseStyles
