import { createActions } from 'redux-actions';
import { DefaultRoutine } from 'utils';

const Actions = createActions({
  HOME: {
    FEED: {
      LOAD: DefaultRoutine,
    },
  },
});

export default Actions;
