import React from 'react'
import PropTypes from 'prop-types'
import systemPropTypes from '@styled-system/prop-types'
import styled, { css } from 'styled-components'
import classnames from 'classnames'
import { variant, width as variantWidth } from 'styled-system'
import { theme, Text, Icon, Block } from 'ui'
import { COMMON, get } from '../constants'
import InlineError from './error';
import { ErrorMessage, useField } from 'formik'

const sizeVariants = variant({
  prop: 'size',
  variants: {
    small: {
      minHeight: '28px',
      px: 2,
      py: 1,
      fontSize: 0,
      lineHeight: '20px'
    },
    normal: {
      p: 2,
      fontSize: 2
    },
    large: {
      px: 3,
      py: '12px',
      fontSize: 2
    }
  }
})

const Input = styled.input.attrs((props) => ({
  type: props.type || 'text'
}))`
  border: 0;
  font-size: ${get('fontSizes.3')}px;
  font-family: ${get('fonts.body')};
  font-weight: 500;
  background-color: transparent;
  color: inherit;
  width: 100%;
  ${(props) => {
    if (props.hasIcon) {
      return css`
        padding-left: 0;
      `
    }
    return css`
      padding-left: ${get('space.2')}px;
    `
  }}
  &:focus {
    outline: none;
  }
  ${(props) => props.as === 'textarea' && css`
    resize: vertical;
    min-height: 100px;
  `}

  ${sizeVariants}
`

export const Label = styled.div`
  display: inline-flex;
  flex-direction: column;
  width:100%;

  ${(props) => props.block
    && css`
      width: 100%;
      max-width:100%;
    `}

  ${(props) => props.inline
    && css`
      flex-direction: row;
      align-items: flex-start;
      display:grid;
      grid-template-columns: 140px 1fr;
      aside {
        text-align: right;
        margin-top:10px;
        white-space: nowrap;
      }

      > div:nth-child(3) {
        grid-column: 2;
      }

      @media (min-width: 320px) and (max-width : 736px) {
        display: inline-flex;
        flex-direction: column;
        width:100%;
      }

    `}
  ${COMMON}
`

export const Wrapper = styled.span`
  display: inline-flex;
  align-items: stretch;
  min-height: 34px;
  width:100%;
  font-size: ${get('fontSizes.3')}px;
  line-height: 20px;
  color: ${get('colors.navy')};
  align-items: center;
  vertical-align: center;
  border: ${get('borders.2')} ${get('colors.grey')};
  border-radius: ${get('radii.1')}px;
  outline: none;
  background: ${get('colors.white')};
  box-shadow: ${get('shadows.formControl')};
  transition: all 0.2s;
  #card-element {
    width:100%;
    margin: ${get('space.2')}px;
  }
  .icon {
    align-self: center;
    color: ${get('colors.gray.1')};
    margin: 0 0 0 ${get('space.3')};
    &-right {
      margin: 0 ${get('space.3')}px 0 0;
    }
    ${(props) => props.hasError
      && css`
        color: ${get('colors.danger')};
        `}
  }

  ${(props) => props.hasError
    && css`
      border-color: ${get('colors.danger')};
      `}

  ${(props) => !props.hasError
    && css`

    /* &:focus-within {
      border: ${get('borders.2')} ${get('colors.brand')};
      box-shadow: 0 0 0 5px rgba(${get('colors.brand')}, 0.1), ${get('shadows.formControl')};
    } */

      &:hover:not(:focus-within) {
        border-color: ${get('colors.gray.1')};
      }
    `}

    ${(props) => props.password
      && css`
        padding-right: ${get('space.2')};

        .icon {
          opacity: 0.7;
          cursor:pointer;
        }
      `
}}

  ${(props) => props.block
    && css`
      width: 100%;
    `}

  input, select {
    border: 0;
    font-family: ${get('fonts.body')};
    background-color: transparent;
    padding: ${get('space.2')}px;
    color: inherit;
    font-size: inherit;
    width: 100%;

    &::placeholder {
      color: #aab7c4;
    }

    ${(props) => {
    if (props.hasIcon) {
      return css`
          padding-left: 0;
        `
    }
    return css`
        padding-left: ${get('space.2')};
      `
  }}
    &:focus {
      outline: none;
    }

    ${sizeVariants}
  }

  @media (max-width: ${get('breakpoints.1')}) {
    font-size: ${get('fontSizes.1')};
  }
  ${COMMON}
  ${variantWidth}
`

const TextInput = ({
  id,
  label,
  icon,
  type = 'text',
  className,
  block,
  size,
  errors,
  name,
  context,
  values,
  width,
  password,
  placeholder,
  autocomplete,
  value,
  search,
  loading,
  handleClear,
  iconColor,
  textarea,
  onAccept,
  currency,
  children,
  error,
  touched,
  ...props
}) => {
  const wrapperClasses = classnames(className, 'TextInput-wrapper')
  const inputClasses = classnames(icon ? 'with-icon' : 'no-icon')
  const hasIcon = !!icon
  const [field, meta] = useField(name);
  const hasError = meta.touched && meta.error;

  return (
    <Label block={block} {...props}>
      {label && !props.inline && (
        <Text
          as="label"
          htmlFor={id || name}
          variant="subhead2"
          color={hasError ? 'danger' : 'navy'}
          pb={1}
        >
          {label}
        </Text>
      )}

      {label && props.inline && (
        <Block as="aside" pr={6} pb={{
          mobile:1,
          desktop:0
        }}>
          <Text
            as="label"
            htmlFor={id || name}
            variant="subhead2"
            color={hasError ? 'danger' : 'navy'}
          >
            {label}
          </Text>
        </Block>
      )}

      <Wrapper
        width={width}
        className={wrapperClasses}
        password={password}
        hasError={hasError}
      >
        {icon && <Icon size={24} className="icon" icon={icon} />}
        {children || (
          <Input
            as={textarea && 'textarea'}
            // eslint-disable-next-line
            type={type}
            className={inputClasses}
            hasIcon={hasIcon}
            size={size}
            error={hasError}
            placeholder={placeholder}
            autoComplete={autocomplete}
            id={id}
            {...field}
            {...props}
          />
        )}
      </Wrapper>
      {meta.touched && meta.error ? (
        <Block mb={2} mt={1}>
          <Text variant="subhead1" color="danger" role="alert">
            {meta.error}
          </Text>
        </Block>
      ) : null }
    </Label>
  )
}

TextInput.defaultProps = {
  theme,
  type: 'text',
  size: 'normal'
}

TextInput.propTypes = {
  block: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  theme: PropTypes.object,
  value: PropTypes.any,
  label: PropTypes.string,
  type: PropTypes.any,
  size: PropTypes.oneOf(['small', 'normal', 'large']),
  ...COMMON.propTypes,
  width: systemPropTypes.layout.width
}

export default TextInput
