import auth from 'modules/auth/selectors';
import profile from 'modules/profile/selectors';
import settings from 'modules/settings/selectors';
import posts from 'modules/posts/selectors';
import home from 'modules/home/selectors';
import conversations from 'modules/conversations/selectors';
import search from 'modules/search/selectors';
import payment from 'modules/payment/selectors';

export default {
  auth,
  profile,
  settings,
  posts,
  home,
  conversations,
  search,
  payment,
};
