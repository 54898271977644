import Narrate from '@narrate/app-sdk/';
import { createSelector } from 'reselect';
import _ from 'lodash';

const Selectors = {
  auth: state => state.auth,
  tokens: state => state.tokens,
  loggedIn: state => state.auth.loggedIn,
  modalVisible: state => state.auth.modalVisible,
  messagedUser: state => state.auth.messagedUser
};

Selectors.checkedAuth = createSelector(
  Selectors.auth,
  auth => auth.checkedAuth,
);

Selectors.login = createSelector(
  Selectors.auth,
  auth => auth.login,
);

Selectors.signup = createSelector(
  Selectors.auth,
  auth => auth.signup,
);

Selectors.loggedIn = createSelector(
  Selectors.auth,
  auth => auth.loggedIn,
);

Selectors.user = createSelector(
  Selectors.auth,
  auth => auth.user,
);

Selectors.decodedIdToken = createSelector(
  Selectors.tokens,
  () => Narrate.api.tokens.decodedIdToken(),
);

Selectors.email = createSelector(
  Selectors.decodedIdToken,
  idToken => _.get(idToken, 'email'),
);

Selectors.userId = createSelector(
  Selectors.decodedIdToken,
  idToken => _.get(idToken, 'sub'),
);

Selectors.forgotPassword = createSelector(
  Selectors.auth,
  auth => auth.forgotPassword,
);

Selectors.confirmPassword = createSelector(
  Selectors.auth,
  auth => auth.confirmPassword,
);

export default Selectors;
