import React from 'react'
import { useHistory } from "react-router-dom";
import styled, { css } from 'styled-components'
import { Block } from 'ui'

const Frame = styled(Block)`
  overflow:hidden;
  background: black;
  border-radius: 6px;
  transition: all 0.4s;

  &:hover {
    opacity:0.8;
  }

  > div {
    display: grid;
    place-items: center;
    overflow:hidden;
    position:relative;
    user-select:none;
    ${(props) => props.inapp && css`
      max-height:170px;
    `}

    @media (min-width: 1024px) {
      height:222px;
    }

    @media (min-width: 320px) and (max-width : 736px) {
      height: 200px;
    }

    ${(props) => props.isVideo && css`
      &:after {
        content:'';
        position: absolute;
        top:50%;
        left:50%;
        width: 40%;
        height:40%;
        border-radius: 100%;
        z-index:99;
        background: rgba(0,0,0,0.6) url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' enable-background='new 0 0 24 24' viewBox='0 0 24 24'%3E%3Cpath fill='%23FFFFFF' d='M18.5,9L8.9,3.5C8.4,3.2,7.8,3,7.2,3C5.3,3,3.8,4.5,3.8,6.4v11.2c0,1.9,1.5,3.4,3.4,3.4c0.6,0,1.2-0.2,1.7-0.5l9.7-5.6c0.5-0.3,0.9-0.7,1.2-1.2C20.7,12.1,20.2,10,18.5,9z'/%3E%3C/svg%3E") center center no-repeat;
        background-size: 40% 40%;
        transform:translate(-50%,-50%)
      }
    `}

  }

  > div > img {
    grid-area: 1 / 1 / 2 / 2;
  }

  > div:before {
    content: "";
    padding-bottom: 100%;
    display: block;
    grid-area: 1 / 1 / 2 / 2;
  }
`


const ContentItem = ({ item, ...props }) => {
  const router = useHistory()
  const { inapp, isMobile, mediaSetId, sk } = props
  const { thumbnailUrl, renditions, filename, itemId } = item
  const pathname = `/s/${mediaSetId}/${sk}/${itemId}`
  return (
    <Frame
      onClick={() => {
        router.push({
          pathname
        })
      }}
      isVideo={thumbnailUrl}
      inapp={inapp || isMobile}
    >
      <div>
        <Block
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
        />
        <img
          draggable={false}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            userSelect: 'none'
          }}
          src={renditions?.photo_long || thumbnailUrl}
          alt={filename}
        />
      </div>
    </Frame>
  )
}

export default ContentItem
