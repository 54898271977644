import { handleActionsImmer } from 'utils';
import Actions from 'actions';

export const initialState = {
  sendReport: {
    error: null,
    loading: false,
  },
  reportData: {
    visible: false
  }
};

const {
  settings: { reportIssue, reportModal },
} = Actions;


const Reducer = handleActionsImmer(
  {
    [reportIssue.trigger]: draft => {
      draft.sendReport.error = null;
      draft.sendReport.loading = true;
    },
    [reportIssue.success]: draft => {
      draft.sendReport.error = null;
      draft.sendReport.loading = false;
    },
    [reportIssue.failure]: draft => {
      draft.sendReport.error = null;
      draft.sendReport.loading = false;
    },
    [reportModal]: (draft, data) => {
      draft.reportData = data
    }
  },
  initialState,
);

export default Reducer;
