import { createActions } from 'redux-actions';
import { DefaultRoutine } from 'utils';

const Actions = createActions({
  SEARCH: {
    SEARCH: DefaultRoutine,
    UPDATE: undefined,
    RESET: undefined,
    ADD_TO_RECENT: undefined
  },
});

export default Actions;
