import { createActions } from 'redux-actions';
import { DefaultRoutine } from 'utils';

const Actions = createActions({
  AUTH: {
    LOAD: DefaultRoutine,
    LOADED: undefined,
    LOGIN: DefaultRoutine,
    LOGOUT: DefaultRoutine,
    REFRESH: DefaultRoutine,
    SIGNUP: DefaultRoutine,
    CLEAR_ERRORS: undefined,
    CHANGE_PASSWORD: DefaultRoutine,
    FORGOT_PASSWORD: DefaultRoutine,
    CONFIRM_PASSWORD: DefaultRoutine,
    SHOW_MODAL: undefined,
  },
});

export const authSuccessActions = [
  Actions.auth.loaded,
  Actions.auth.login.success,
  Actions.auth.signup.success,
  Actions.auth.changePassword.success,
];

export default Actions;
