import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet";
import Footer from 'components/common/Footer'
import Header from 'components/common/Header'
import { Block, Text, Avatar, Button, Spinner } from 'ui'
import { useParams } from "react-router-dom";
import { event } from 'utils/ga'
import { formatAmount } from 'utils'
import { loadData } from './checkout'

function Confirmation() {

  const [ accessBlock, setAccessBlock ] = useState({
    headline: null,
    amount: null,
    accessImage: null,
    description: null,
    paymentTerms: null
  })
  const [user, setUser] = useState('')
  const { item, username } = useParams()
  const [ userId, itemId ] = item.split('@')

  useEffect(() => {
    async function load() {
      const { profile, accessBlock } = await loadData({
        username,
        itemId,
        userId
      })

      setAccessBlock(accessBlock)
      setUser(profile)
    }
    load()

  }, [username, itemId, userId])

  if (!user) {
    return (
      <Block
        flex={1}
        alignItems="center"
        display="flex"
        justifyContent="center"
        minHeight="calc(100vh)"
        p={5}
      > 
        <Spinner/>
      </Block>
    )
  }

  const { photo, bio, name } = user
  const {
    headline,
    amount,
    paymentTerms
  } = accessBlock

  const isSub = () => paymentTerms === 'monthly'

  const goToApp = (small) => {
    const now = new Date().valueOf()
    setTimeout(() => {
      if (new Date().valueOf() - now > 100) return
      window.location = `https://9cwbj-alternate.app.link?$username=${username}&$ios_deepview=false`
    }, 50)
    window.location = `narrate://profile/${username}`
    event({
      category: 'Profile',
      action: 'download_click',
      label: small === 'small' ? 'top_button' : 'center_button'
    })
  }

  return (
    <>
      <Helmet>
        <title>{`${name || ''} (${username}) | Narrate`}</title>
        <meta
          property="og:title"
          content={`${name || ''} (${username}) | Narrate`}
        />
        <meta property="og:type" content="profile" />
        <meta property="og:url" content={`${window.location.href}`} />
        <meta property="og:image" content={photo} />
        <meta
          property="description"
          content={`Connect with ${name
            || ''} (${username}) on Narrate! ${bio && bio}`}
        />
        <meta
          property="og:description"
          content={`Connect with ${name
            || ''} (${username}) on Narrate! ${bio && bio}`}
        />
      </Helmet>
      <Block>
        <Block
          flex={1}
          col
          bg="white"
          alignItems={{
            mobile: 'flex-start',
            desktop: 'center'
          }}
          justifyContent="center"
        >
          <Block
            row
            flex={1}
            px={3}
            pt={3}
            pb={{
              mobile: 3,
              desktop: 10
            }}
            bg="white"
          >
            <Avatar
              size={140}
              mr={3}
              photo={photo}
              display={{
                mobile: 'none',
                desktop: 'inline-flex'
              }}
            />
            <Avatar
              size={120}
              mr={3}
              photo={photo}
              display={{
                mobile: 'inline-flex',
                desktop: 'none'
              }}
            />
            <Block flex={1} col justifyContent="center">
              <Block row>
                <Text
                  variant="subhead1"
                  fontSize={{
                    mobile: 20
                  }}
                  mb={1}
                >
                  {name}
                </Text>
              </Block>
              <Block row>
                <Text variant="bodySmall" maxWidth={300}>
                  {bio}
                </Text>
              </Block>
            </Block>
          </Block>
        </Block>
        <Block bg="white" pt={4}>
          <Block textAlign="center" col>
            <Text variant="h3" mb={5} px={3}>
              Congratulations!
            </Text>
            <Text variant="h5" mb={5} px={3}>
              You supported
              {' '}
              <strong>
                @
                {username}
              </strong>
              {' '}
              {isSub() ? 'by subscribing to "' : 'by purchasing "'}
              {headline.trim()}
              " for
              <strong>
                {' '}
                {formatAmount(amount)}
              </strong>
              {isSub() && ' per month'}
              !
            </Text>
            <Text color="greyDark" variant="h6" px={3}>
              {`You will receive an email shortly connecting you to @${username}`}
              .
            </Text>

            <Block
              col
              mt={10}
              py={12}
              bg={{
                mobile: 'navy',
                desktop: 'white'
              }}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <Text
                color={{
                  mobile: 'white',
                  desktop: 'black'
                }}
                variant="h5"
                mb={2}
                color={{
                  mobile: 'brand',
                  desktop: 'black'
                }}
              >
                iPhone Users
              </Text>
              <Text
                color={{
                  mobile: 'white',
                  desktop: 'black'
                }}
                variant="h6"
                mx={4}
              >
                Connect directly with
                {' '}
                <strong>
                  @
                  {username}
                </strong>
                {' '}
                on Narrate
              </Text>
              <Block textAlign="center" mt={5}>
                <Button
                  onClick={() => goToApp('small')}
                  size="large"
                  block
                  text="Download Narrate"
                />
              </Block>
            </Block>
          </Block>
        </Block>
      </Block>
    </>
  )
}

// export async function getServerSideProps({ params }) {
//   const data = await getProfile({
//     username: params.username,
//     pageSize: 20
//   })

//   const [userId, itemId] = params.block.split('@')

//   const accessBlock = await getAccessBlock({
//     itemId,
//     userId
//   })

//   return {
//     props: {
//       user: data,
//       accessBlock: accessBlock.Item,
//       url: `https://${process.env.VERCEL_URL}/`,
//       iosUrl:
//         'https://apps.apple.com/us/app/narrate-connect-with-creators/id1491857226'
//     }
//   }
// }

export default Confirmation
