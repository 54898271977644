import { combineActions } from 'redux-actions';
import { handleActionsImmer } from 'utils';
import Actions from 'actions';

export const initialState = {
  login: {
    loading: false,
    error: null,
  },
  signup: {
    loading: false,
    error: null,
  },
  confirmPassword: {
    loading: false,
    error: null,
  },
  changePassword: {
    loading: false,
    error: null,
  },
  forgotPassword: {
    loading: false,
    error: null,
  },
  exp: null,
  user: null,
  loggedIn: false,
  checkedAuth: false,
  modalVisible: false,
  messagedUser: null
};

const {
  auth: { load, login, logout, refresh, signup, changePassword, clearErrors, forgotPassword, confirmPassword, showModal },
} = Actions;

const Reducer = handleActionsImmer(
  {
    'persist/REHYDRATE': draft => {
      draft.checkedAuth = false;
    },
    [logout.trigger]: () => initialState,

    [combineActions(load.success, login.success, refresh.success, signup.success)]: draft => {
      draft.loggedIn = true;
    },
    [load.fulfill]: draft => {
      draft.checkedAuth = true;
    },
    [login.request]: draft => {
      draft.login.error = null;
      draft.login.loading = true;
    },
    [login.failure]: (draft, { error }) => {
      draft.login.error = error;
    },
    [login.fulfill]: draft => {
      draft.login.loading = false;
    },
    [signup.trigger]: draft => {
      draft.signup.loading = true;
      draft.signup.error = null;
    },
    [signup.failure]: (draft, { error }) => {
      draft.signup.error = error;
    },
    [signup.fulfill]: draft => {
      draft.signup.loading = false;
    },
    [changePassword.trigger]: draft => {
      draft.changePassword.loading = true;
      draft.changePassword.error = null;
    },
    [changePassword.failure]: (draft, { error }) => {
      draft.changePassword.error = error;
    },
    [changePassword.fulfill]: draft => {
      draft.changePassword.loading = false;
    },
    [forgotPassword.trigger]: draft => {
      draft.forgotPassword.loading = true;
      draft.forgotPassword.error = null;
    },
    [forgotPassword.request]: draft => {
      draft.forgotPassword.loading = true;
    },
    [forgotPassword.failure]: (draft, { error }) => {
      draft.forgotPassword.error = error;
    },
    [forgotPassword.fulfill]: draft => {
      draft.forgotPassword.loading = false;
    },
    [confirmPassword.trigger]: draft => {
      draft.confirmPassword.loading = true;
      draft.confirmPassword.error = null;
    },
    [confirmPassword.request]: draft => {
      draft.confirmPassword.loading = true;
    },
    [confirmPassword.failure]: (draft, { error }) => {
      draft.confirmPassword.error = error;
    },
    [confirmPassword.fulfill]: draft => {
      draft.confirmPassword.loading = false;
    },
    [showModal]: (draft, {visible, userId}) => {
      draft.messagedUser = userId
      draft.modalVisible = visible
    },
    [clearErrors]: draft => {
      draft.login.error = null;
      draft.signup.error = null;
      draft.changePassword.error = null;
      draft.forgotPassword.error = null;
      draft.confirmPassword.error = null;
    },
  },
  initialState,
);

export default Reducer;
