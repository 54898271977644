import React from 'react'
import Header from 'components/common/Header'
import Footer from 'components/common/Footer'
import Logo from 'components/common/Logo'
import {Block} from 'ui'

function Home() {
  const iosUrl = 'https://apps.apple.com/us/app/narrate-connect-with-creators/id1491857226'

  return (
    <Block pt={8}>
      <main className="content">
        <div className="wrapper">
          <section className="connect">
            <hgroup>
              <Block mb={10} textAlign={{
                mobile:'center',
                tablet: 'left',
                desktop: 'left'
              }}>
                <Logo />
              </Block>
              <h1>
                Connect with
                <br />
                Creators
              </h1>
              <h3>
                Get real access to creators, artists,
                <br />
                influencers and people you admire.
              </h3>
              <div className="get">
                <h4>Get the App</h4>
                <div className="stores">
                  <a href={iosUrl}>
                    <img src="images/appstore.svg" draggable="false" alt="" />
                  </a>
                  <img src="images/google-later.png" draggable="false" alt="" />
                </div>
              </div>
            </hgroup>
            <div className="devices">
              <img src="images/iphone.png" draggable="false" alt="" />
            </div>
          </section>
        </div>
      </main>
      </Block>
  );
}

export default Home;