import { createActions } from 'redux-actions';
import { DefaultRoutine } from 'utils';

const Actions = createActions({
  PROFILE: {
    MY_PROFILE: {
      LOAD: DefaultRoutine,
      UPDATE: DefaultRoutine,
      UPDATE_IMAGE: undefined,
      CLEAR_IMAGE: undefined,
      UPLOAD_PHOTO: DefaultRoutine,
    },
    LOAD_USER: DefaultRoutine,
    LOAD_PROFILE: DefaultRoutine,
    LOAD_EARNINGS: DefaultRoutine,
    LOAD_EARNINGS_SUMMARY: DefaultRoutine,
    GET_PROFILE_LINK: DefaultRoutine,
    TOGGLE_SIDEBAR: undefined,
    TOGGLE_REGISTER: undefined
  }
});

export default Actions;
