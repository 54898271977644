import React, { useState, useEffect } from 'react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import { Block, Text, Button, TextInput, colors, Spinner } from 'ui'
import { Wrapper } from 'ui/TextInput'
import { Formik, Form } from 'formik'
import PaymentSelect from 'components/common/PaymentSelect'
import { useSelector, useDispatch } from 'react-redux';
import Narrate from '@narrate/app-sdk';
import Selectors from 'selectors'
import Actions from 'actions'
import * as Yup from 'yup';


const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '16px',
      fontFamily: 'Gilroy',
      fontWeight: 100,
      color: colors.navy[0],
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: colors.danger[0],
      iconColor: colors.danger[0],
    }
  }
}

export default function CheckoutForm({
  itemId,
  userId,
  username,
  amount,
  isSub,
  onSuccess
}) {
  const [error, setError] = useState(null)
  const [cardError, setCardError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [preIntent, setPreIntent] = useState(null)
  const stripe = useStripe()
  const elements = useElements()
  const { cognitoEmail: loggedInEmail, userId: loggedInUserId } = useSelector(Selectors.profile.myProfile)
  const dispatch = useDispatch()
  const intentLoading = useSelector(Selectors.payment.loading)
  const previousIntent = useSelector(Selectors.payment.previousIntent)
  const {paymentInfo, client_secret, txId} = useSelector(Selectors.payment.paymentIntent)
  const errors = useSelector(Selectors.payment.errors)

  // Reset the demo.
  const handleReset = () => {
    setError(null)
    elements?.getElement(CardElement)?.clear()
  }


  useEffect(()=> {
    if(loggedInEmail) {
      dispatch(Actions.payment.paymentIntent.trigger({
        userId,
        itemId
      }))
    }
  }, [dispatch, itemId, userId, loggedInEmail])


  const submitWebOrder = async ({
    name,
    city,
    state,
    address,
    address2,
    country
  }) => {
    const cardElement = elements.getElement(CardElement)
    setIsLoading(true)

    const { returnData } = await Narrate.api.accessBlock.purchase({ userId, id: itemId }, {
      action: 'update-payment',
      client_secret: previousIntent?.client_secret,
      txId: previousIntent?.txId,
      paymentMethodId: previousIntent?.paymentInfo?.id
    })


    const result = await stripe.confirmCardPayment(returnData?.client_secret || client_secret, {
      payment_method: {
        type: 'card',
        card: cardElement,
        billing_details: {
          name,
          email: loggedInEmail,
          ...(isSub && {
            address: {
              city,
              state,
              line1: address,
              line2: address2,
              country
            }
          })
        }
      },
      setup_future_usage: 'off_session'
    })

    if (result.error) {
      // Inform the user if there was an error.
      setCardError(result.error.message)
      setIsLoading(false)
    } else if (result.paymentIntent.status === 'succeeded') {
      setError(null)
      setCardError(null)
      handleReset()
      dispatch(Actions.payment.paymentConfirm.trigger({
        txId: returnData?.txId || txId,
        onSuccess: () => {
          if (onSuccess) {
            onSuccess()
          }
        }
      }))
    }

  }
  

  // Handle form submission.
  const submitOrder = async () => {
    setIsLoading(true)

    const result = await stripe.confirmCardPayment(client_secret, {
      payment_method: paymentInfo?.id,
      setup_future_usage: 'off_session'
    })

    if (result?.error) {
      // Inform the user if there was an error.
      console.log(result.error)
      setCardError(result.error.message)
      setIsLoading(false)
    } else if (result.paymentIntent.status === 'succeeded') {
      setError(null)
      setCardError(null)
      handleReset()
      
      dispatch(Actions.payment.paymentConfirm.trigger({
        txId,
        onSuccess: () => {
          if (onSuccess) {
            onSuccess()
          }
        }
      }))
    }
  }

  const PaymentValidation = isSub ?
    Yup.object().shape({
      name: Yup.string().required('Your name is required.'),
      address: Yup.string().required('Your address is required.'),
      city: Yup.string().required('City is required.'),
      state: Yup.string().required('State is required.'),
      country: Yup.string().required('Country is required.')
    }) 
      :
    Yup.object().shape({
      name: Yup.string().required('Your name is required.')
    })

  
  const ErrorContainer = ({name, touched, errors}) => {
    if(touched && touched[name] && errors[name]) {
      return (
        <Block mb={2} mt={1}>
          <Text variant="subhead1" color="danger" role="alert">
            {errors[name]}
          </Text>
        </Block>
      )
    }
    return null;
  }

  if(loggedInUserId === userId) {
    return (
      <Block col justifyContent="center" alignCenter="center" textAlign="center">
        <Text variant="subhead1" color="grey">You can't buy your own access block</Text>
      </Block>
    )
  }

  if(intentLoading) {
    return (
      <Block col justifyContent="center" alignCenter="center" textAlign="center">
        <Spinner size={40} />
        <Text color="grey">loading...</Text>
      </Block>
    )
  }


  if(errors) {
    return (
      <Block col justifyContent="center" alignCenter="center" textAlign="center">
        <Text variant="subhead1" color="greyDark">There was an issue with the payment intent.<br/>Please refresh and try again.</Text>
        <Block mt={4} px={10}>
          <Button onClick={()=> window.location.reload()} size="medium" text="Reload"/>
        </Block>
      </Block>
    )
  }

    

  return (
    <Block flex={1} position="relative">
      {isLoading && (
        <Block
          bg="white"
          zIndex={999}
          flex={1}
          justifyContent="center"
          alignItems="center"
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          display="flex"
          borderRadius={2}
        >
          <Spinner size={40} />
        </Block>
      )}
      <Formik
        initialValues={{
          name: '',
          address: '',
          address2:'',
          city:'',
          state: '',
          country: 'US'
        }}
        validationSchema={paymentInfo ? null : PaymentValidation}
        onSubmit={(values) => {
          if(paymentInfo) {
            submitOrder(values)
          }else {
            submitWebOrder(values)
          }
        }}
      >
        {(props) => {
          const { errors, handleBlur, touched, values, handleChange, handleSubmit, isSubmitting } = props
          return (
            <Form>
              <Text
                display={{
                  mobile: 'none',
                  desktop: 'block'
                }}
                variant="h4"
                mb={8}
                color="navy"
                textAlign="center"
              >
                Payment Information
              </Text>
              {paymentInfo && !isSub ? (
                <>
                  <PaymentSelect lastPayment={paymentInfo} error={cardError} />
                  {cardError && (
                    <Block mt={3} mb={2} textAlign="center">
                      <Text variant="subhead1" color="danger" role="alert">
                        {`${cardError}`}
                      </Text>
                    </Block>
                  )}
                </>
              ):(
                <>
                  <Block mt={3}>
                    <Block row justifyContent="space-between">
                      <Text
                        as="label"
                        htmlFor="card-element"
                        variant="subhead2"
                        color={cardError ? "danger" : "navy"}
                        mb={1}
                      >
                        Card Information
                      </Text>
                      <Block>
                        <img src="/images/cards.png" width="120" />
                      </Block>
                    </Block>
                    <Wrapper bg="white" as="div" flex={1} hasError={cardError}>
                      <CardElement id="card-element" options={CARD_ELEMENT_OPTIONS} />
                    </Wrapper>
                    {cardError && (
                      <Block mb={2} mt={1}>
                        <Text variant="subhead1" color="danger" role="alert">
                          {cardError}
                        </Text>
                      </Block>
                    )}
                  </Block>
                  <Block mt={3} mb={2} col>
                    <TextInput
                      label="Name on Card"
                      id="name"
                      name="name"
                      placeholder="Name on Card"
                      value={values.name}
                    />
                  </Block>
      
                  {isSub && (
                    <>
                      <Block mt={3} mb={0} col>
                        <TextInput
                          label="Billing Address"
                          id="address"
                          name="address"
                          placeholder="Address"
                          value={values.address}
                        />
                        <TextInput
                          label=""
                          id="address2"
                          name="address2"
                          placeholder="Apartment, Suite, Unit"
                          value={values.address2}
                          mt={2}
                        />
                      </Block>
                      <Block mt={3} mb={2} col>
                        <TextInput
                          label="City"
                          id="city"
                          name="city"
                          placeholder="City"
                          value={values.city}
                        />
                      </Block>
        
                      <Block mt={3} mb={2} flex={1} row>
                        <Block pr={2}>
                          <Block row justifyContent="space-between">
                            <Text
                              as="label"
                              htmlFor="country"
                              variant="subhead2"
                              color={touched && touched.country && errors.country ? "danger" :"navy"}
                              mb={1}
                            >
                              Country
                            </Text>
                          </Block>
                          <Wrapper bg="white" as="div" flex={1} hasError={touched && touched.country && errors.country}>
                            <CountryDropdown
                              value={values.country}
                              id="country"
                              name="country"
                              valueType="short"
                              style={{
                                border: 'none',
                                width: '100%',
                                flex: 1,
                                marginRight: 10
                              }}
                              priorityOptions={['US', 'CA']}
                              onChange={(_, e) => handleChange(e)}
                              onBlur={handleBlur}
                            />
                          </Wrapper>
                          <ErrorContainer 
                            name="country"
                            touched={touched}
                            errors={errors} 
                          />
                        </Block>
                        <Block>
                          <Block row justifyContent="space-between">
                            <Text
                              as="label"
                              htmlFor="state"
                              variant="subhead2"
                              color={touched && touched.state && errors.state ? "danger" :"navy"}
                              mb={1}
                            >
                              State
                            </Text>
                          </Block>
                          <Wrapper bg="white" as="div" flex={1} hasError={touched && touched.state && errors.state}>
                            <RegionDropdown
                              country={values.country}
                              value={values.state}
                              countryValueType="short"
                              defaultOptionLabel="Select State"
                              id="state"
                              name="state"
                              style={{
                                border: 'none',
                                width: '100%',
                                flex: 1,
                                marginRight: 10
                              }}
                              onChange={(_, e) => handleChange(e)}
                              onBlur={handleBlur}
                            />
                          </Wrapper>
                          <ErrorContainer 
                            name="state"
                            touched={touched}
                            errors={errors} 
                          />
                        </Block>
                      </Block>
                    </>
                  )}
                </>
              )}
  
            {error && (
              <Block textAlign="center" my={3}>
                <Text variant="subhead1" color="danger" role="alert">
                  {error}
                </Text>
              </Block>
            )}
  
            <Button
              mt={3}
              size="large"
              block
              type="submit"
              variant="alt"
              id="submit-payment"
              text={isSub ? `Subscribe ${amount}/mo` : `Buy ${amount}`}
            />
            <Block textAlign="center" flex={1} display="flex" col id="secure">
              <Text
                mt={2}
                mb={4}
                textAlign="center"
                color="black"
                variant={{
                  mobile: 'subhead3',
                  desktop: 'subhead2'
                }}
                alignItems="center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  style={{
                    width: 16,
                    position: 'relative',
                    top: 2,
                    marginRight: 5
                  }}
                >
                  <path fill="#000000" d="M17,9V7c0-2.8-2.2-5-5-5S7,4.2,7,7v2c-1.7,0-3,1.3-3,3v7c0,1.7,1.3,3,3,3h10c1.7,0,3-1.3,3-3v-7C20,10.3,18.7,9,17,9z M9,7c0-1.7,1.3-3,3-3s3,1.3,3,3v2H9V7z" />
                </svg>
                Guaranteed
                {' '}
                <strong>safe</strong>
                {' '}
                and
                {' '}
                <strong>secure</strong>
                {' '}
                checkout
              </Text>
            </Block>
          </Form>
          )}
        }
      </Formik>
    </Block>
  )
}
