import Narrate from '@narrate/app-sdk';
import _ from 'lodash';
import { call, select, takeEvery, put } from 'redux-saga/effects';
import { routineSaga, sendTo } from 'utils/sagas';
import Actions from 'actions';
import Selectors from 'selectors';
import { push } from 'react-router-redux';


const { auth } = Actions;

const Auth = {
  load: () => {},
  login: () => {},
  logout: () => {},
  signup: () => {},
  changePassword: () => {},
  forgotPassword: () => {},
  confirmPassword: () => {},
};

class AuthSaga {
  load = routineSaga({
    routine: auth.load,
    *request() {
      yield Auth.load();
    },
  });

  login = routineSaga({
    routine: auth.login,
    *request({ payload: { email, password, callback } }) {
      const deviceId = navigator.userAgent
      const cleanEmail = email.trim();
      const { tokens } = yield call(Narrate.api.auth.login, cleanEmail, password, { deviceId });
      yield Narrate.api.tokens.updateTokens(tokens);
      if(callback) {
        yield call(() => callback())
      }
      return tokens;
    },
  });

  forgotPassword = routineSaga({
    routine: auth.forgotPassword,
    *request({ payload: { email, onSuccess } }) {
      const result = yield call(Narrate.api.auth.forgotPassword, email);
      if (onSuccess) {
        yield call(onSuccess);
      }
      return result;
    },
  });

  confirmPassword = routineSaga({
    routine: auth.confirmPassword,
    *request({ payload: { email, code, newPass, onSuccess } }) {
      const result = yield call(Narrate.api.auth.confirmPassword, email, code, newPass);
      if (onSuccess) {
        yield call(onSuccess);
      }
      return result;
    },
  });

  logout = routineSaga({
    routine: auth.logout,
    *request({payload: { callback }}) {
      yield call(() => Narrate.api.tokens.clear());
      if(callback) {
        yield call(() => callback())
      }
      //yield put(Actions.conversations.clear());
    },
  });

  signup = routineSaga({
    routine: auth.signup,
    *request({ payload: { email, password, username, callback } }) {
      const deviceId = navigator.userAgent;
      const cleanEmail = email.trim();
      const { tokens } = yield call(Narrate.api.auth.register, cleanEmail, password, { username, deviceId });
      yield Narrate.api.tokens.updateTokens(tokens);
      if(callback) {
        yield call(callback)
      }
      return tokens;
    },
  });

  changePassword = routineSaga({
    routine: auth.changePassword,
    *request({ payload: { email, previousPassword, newPassword, onSuccess } }) {
      console.log('***** email:', email);
      // const deviceId = yield call(DeviceInfo.getUniqueId);
      const result = yield Narrate.api.auth.changePassword(email,
        previousPassword,
        newPassword);
      // Narrate.api.tokens.updateTokens(tokens);
      if (onSuccess) {
        onSuccess();
      }
      return result;
    },
  });
}

const saga = new AuthSaga();

function* errorToSignout(data, status, errors, error) {
  if (status === 400) {
    yield put(Actions.auth.logout.trigger());
  }

  if (status === 401
    && (_.includes(errors, error.type) || _.includes(errors, data.message.toLowerCase()))
  ) {
    yield put(Actions.auth.logout.trigger());
  }
}

function* handleActionFailure({ payload }) {
  const response = _.get(payload, 'error.response', {});
  const { data, status } = response;
  const error = _.get(data, 'error', {});

  const errors = ['invalid_token', 'unauthorized'];
  yield call(errorToSignout, data, status, errors, error);
}

// eslint-disable-next-line no-unused-vars
function loginRequired(sagas) {
  return function*(...args) {
    const loggedIn = yield select(Selectors.auth.loggedIn);
    if (loggedIn) {
      yield call(sagas, ...args);
    }
  };
}

export default function* combined() {
  // yield takeEvery('persist/REHYDRATE', sendTo(auth.load.trigger));

  yield takeEvery(auth.load.failure, sendTo(auth.logout.trigger));
  yield takeEvery(auth.load.trigger, saga.load);
  yield takeEvery(auth.login.trigger, saga.login);
  yield takeEvery(auth.logout.trigger, saga.logout);
  yield takeEvery(auth.signup.trigger, saga.signup);
  yield takeEvery(action => /^.*FAILURE$/.test(action.type), handleActionFailure);
  yield takeEvery(auth.changePassword.trigger, saga.changePassword);
  yield takeEvery(auth.forgotPassword.trigger, saga.forgotPassword);
  yield takeEvery(auth.confirmPassword.trigger, saga.confirmPassword);
}
