import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { variant, compose, fontSize } from 'styled-system'
import systemPropTypes from '@styled-system/prop-types'
import Icon from './Icon'
import Text from './Text'
import { COMMON, get } from './constants'
import { Typography, Palette } from './theme'
import Spinner from './Spinner'

const isMobile = false

export const styles = {
  button: {
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    borderRadius: 15,
    height: 30,
    flexDirection: 'row',
    paddingHorizontal: 10,
    backgroundColor: Palette.brand,
  },
  label: {
    ...(isMobile ? Typography.body : Typography.bodySmall),
    ...Typography.button,
    color: Palette.white,
    marginBottom: 0,
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
  },
}

const sizeVariants = variant({
  prop: 'size',
  variants: {
    normal: {
      borderRadius: 20,
      px: 3,
      py: 1,
    },
    medium: {
      borderRadius: '30px',
      px: 5,
      py: 2,
    },
    large: {
      borderRadius: '30px',
      px: 10,
      py: 3
    },
  },
})

const styleVariants = variant({
  variants: {
    outline: {
      fontSize: 0,
      borderWidth: 1,
      borderColor: 'greyLight',
      backgroundColor: 'transparent',
      borderStyle: 'solid'
    },
    primary: {
      fontSize: 0,
      backgroundColor: 'brand',
      borderWidth: 0,
    },
    secondary: {
      backgroundColor: 'white',
      borderWidth: 0,
    },
    alt: {
      fontSize: 0,
      backgroundColor: 'navy',
      borderWidth: 0,
      '&:hover': {
        backgroundColor: 'navyLighter'
      }
    },
  },
})

const Button = styled.button`
  flex-direction: row;
  align-items: center;
  display:flex;
  justify-content: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-decoration: none;
  outline:none;
  border-image: none;
  user-select:none;
  flex:1;
  transition: all 0.4s;
  cursor:pointer;
  ${compose(fontSize, COMMON)}
  ${(props) => props.disabled
    && css`opacity: 0.9;`
}
  ${(props) => props.variant === 'outline' && props.disabled && css`opacity: 0.4;`}
  ${styleVariants}
  ${sizeVariants}
`

const ButtonWrapper = styled.div`
  display:flex;
  align-self: ${(props) => (props.block ? 'stretch' : 'flex-start')}
`

const ButtonIcon = styled(Icon)`
  ${COMMON}
`

const ButtonText = styled(Text)`
  ${(props) => props.size === 'large' && css`
    font-size: ${get('fontSizes.4')}px;
  `}

  ${(props) => props.size === 'medium' && css`
    font-size: ${get('fontSizes.2')}px;
  `}

  margin:0;
`

const Btn = ({
  style,
  text,
  block,
  opacity,
  children,
  iconLeft,
  iconRight,
  iconColor,
  iconSize = 18,
  progress,
  outline,
  large,
  disabled,
  ...props
}) => {
  const _iconColor = iconColor || '#ffffff'
  const { size, variant: vari } = props

  let textColor
  switch (vari) {
  case 'alt':
    textColor = 'brand'
    break
  case 'outline':
    textColor = 'navy'
    break;
  case 'secondary':
    textColor = 'navy'
    break
  default:
    textColor = 'white'
    break
  }

  return (
    <ButtonWrapper
      block={block}
      style={style}
    >
      <Button
        disabled={disabled || progress}
        activeOpacity={opacity || 0.8}
        {...props}
      >
        {text ? (
          <>
            {iconLeft
              && (
                <ButtonIcon
                  name={iconLeft}
                  color={_iconColor}
                  mr={2}
                  size={iconSize}
                />
              )}
            <ButtonText
              variant="button"
              size={size}
              color={textColor}
            >
              {text}
            </ButtonText>
            {iconRight
              && (
                <ButtonIcon
                  name={iconRight}
                  color={_iconColor}
                  ml={2}
                  size={iconSize}
                />
              )}
          </>
        ) : children}
        {progress && (
          <Spinner
            style={{
              marginLeft: 5
            }}
            light
            width={24}
          />
        )}
      </Button>
    </ButtonWrapper>
  )
}

Button.defaultProps = {
  variant: 'primary',
  size: 'normal',
}

Button.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  variant: PropTypes.oneOf(['primary', 'outline', 'alt', 'secondary']),
  size: PropTypes.oneOf(['small', 'normal', 'medium', 'large']),
  ...COMMON.propTypes,
  ...systemPropTypes.layout,
}


export default Btn
