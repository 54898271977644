import { useEffect, useRef } from 'react'
import { Block } from 'ui'
import Hls from 'hls.js'
import styled, { css } from 'styled-components'

const Video = styled.video`
  max-width: 100%;
  width:100%;
  height:100%;
  cursor: pointer;
  user-select: none;
  object-fit: contain;
  outline:none;
`

export default function VideoPlayer({ src, poster, inapp }) {
  const videoRef = useRef(null)

  useEffect(() => {
    const video = videoRef.current

    if (!video) return

    video.controls = true
    video.playsinline = true
    let hls

    if (video.canPlayType('application/vnd.apple.mpegurl')) {
        // This will run in safari, where HLS is supported natively
        video.src = src
    } else if (Hls.isSupported()) {
        // This will run in all other modern browsers
        hls = new Hls({ enableWorker: false })
        hls.loadSource(src)
        hls.attachMedia(video)
    } else {
      console.error(
        'This is an old browser that does not support MSE https://developer.mozilla.org/en-US/docs/Web/API/Media_Source_Extensions_API'
      )
    }

    return () => {
      if (hls) {
        hls.destroy()
      }
    }
  }, [src, videoRef, poster])


  return (
    <Block
      position="fixed"
      top={0}
      left={0}
      right={0}
      bottom={0}
      zIndex={8888}
    >
      <Block
        style={{
          height: '100%',
        }}
      >
        <Video 
          ref={videoRef} 
          poster={poster} 
          controls={!inapp} 
          autoPlay={inapp}
          src={src}
          type="application/x-mpegURL"
          playsInline
        />
      </Block>
    </Block>
  )
}
