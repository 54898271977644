const handleError = (err) => {
	console.warn(err);
	return new Response(JSON.stringify({
		message: 'There was an error with your request'
	}));
};

const config = {
  headers: {
    Origin: window.location.origin
  }
}

export async function getProfile({ username, pageSize }) {
  const res = await fetch(`${process.env.API_URL}/weborder/profile-view/${username}?fetchSize=${pageSize}`, config)

  const json = await res.json()

  if (json.errors) {
    throw new Error(json)
  }

  if (json.accessBlocks.items) {
    json.accessBlocks.items = json.accessBlocks.items.map(
      ({ autoReply, ...block }) => block
    )
  }

  return json
}

export async function getAccessBlock({ itemId, userId }) {
  const res = await fetch(`${process.env.API_URL}/users/${userId}/access/${itemId}`, config)

  const json = await res.json()

  if (json.errors) {
    throw new Error(json)
  }
  if (json.Item) {
    delete json.Item.autoReply
  }

  return json
}

export async function getPhotoSet({ mediaSetId, sk }) {
  const res = await fetch(`${process.env.API_URL}/s/${mediaSetId}?sk=${sk}&fetchSize=100`, config)
  const json = await res.json()
  return json
}


export async function refreshLink({userEmail, mediaSetId}) {
  const res = await fetch(`${process.env.API_URL}/mediaset/${mediaSetId}/refreshlink`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...config.headers
    },
    body: JSON.stringify({
      userEmail
    })
  }).catch(handleError)

  return {
    status: 'success',
    message: 'email sent',
  }
}

export async function paymentConfirm({ username, customerId, stripeResponse, txId }) {

  const res = await fetch(`${process.env.API_URL}/weborder/${customerId}/confirm-payment/${txId}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...config.headers
    },
    body: JSON.stringify({
      username,
      stripeResponse
    })
  })

  return res.json()
}

export async function paymentIntent({
  itemId,
  userId,
  username,
  customerName,
  customerEmail
}) {

  const res = await fetch(`${process.env.API_URL}/weborder/${userId}/create-payment-intent`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...config.headers
    },
    body: JSON.stringify({
      itemId,
      userId,
      username,
      customerName,
      customerEmail
    })
  })

  return res.json()
}