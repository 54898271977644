import { takeEvery, call } from 'redux-saga/effects';

import Narrate from '@narrate/app-sdk';
import { routineSaga, sendTo } from 'utils/sagas';
import Actions from 'actions';

const loadFeed = routineSaga({
  routine: Actions.home.feed.load,
  *request({ payload: { onSuccess } }) {
    const home = yield Narrate.api.homeFeed.list();

    if (onSuccess) {
      yield call(onSuccess);
    }

    return home;
  },
});

export default function* combined() {
  yield takeEvery('persist/REHYDRATE', sendTo(Actions.home.feed.load.trigger));
  yield takeEvery(Actions.home.feed.load.trigger, loadFeed);
  yield takeEvery(
    [
      Actions.profile.myProfile.update.success, // update list when user profile is updated
    ],
    sendTo(Actions.home.feed.load.trigger),
  );
}
