import ReactGA from 'react-ga';


export const event = ({ action, category, label, value, }) => {
  ReactGA.event({
    action,
    category,
    label,
    value,
  })
}